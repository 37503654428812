import UseCase from "../../../../core/app/usecase";
import { DocumentTypeResourceType } from "../../../../mock/models/document-type";
import FileEntity from "../../../entities/file";
import { AiTaskSectionCode } from "../../../entities/aiTaskSectionCode";
import DocumentRepository from "../../../repositories/documentRepository";


type CreateAiTaskParams = {
  companyId: string;
  files: FileEntity;
  sectionCode: AiTaskSectionCode,
  resourceId?: string;
  resourceType?: DocumentTypeResourceType;
  siteId?: string;
  isSupplier?: boolean;
  requirementSource?: string
}

/**
 * Returns the list of all the existing Document.
 */
class CreateAiTask implements UseCase<CreateAiTaskParams, string> {
  constructor(private repository: DocumentRepository) { }

  async Call({companyId, files, sectionCode, resourceId, resourceType, siteId, isSupplier, requirementSource}: CreateAiTaskParams): Promise<string> {
    return await this.repository.createAiTask(companyId, files, sectionCode, resourceId, resourceType, siteId, isSupplier, requirementSource);
  }
}

export default CreateAiTask;