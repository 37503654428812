import { Flex, Text, useToast } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiAlertTriangle, FiInfo, FiPlus } from "react-icons/fi";
import Document from "../../../../domain/entities/document";
import DocumentType from "../../../../domain/entities/documentType";
import DocumentTypeWithPublic from "../../../../domain/entities/documentTypeWithPublic";
import { COLORS } from "../../../assets/theme/colors";
import { useDocumentsFilesDownloader, UseDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import { DocumentsListViewModelProps } from "../../../hooks/Document/useDocumentsViewModel";
import ContentLayout from "../../../layout/ContentLayout";
import { DeleteActionAlert } from "../../../screens/Common/DeleteActionAlert";
import ActionBar from "../../Common/ActionBar";
import ActionBarItem from "../../Common/ActionBarItem";
import { InfoBanner } from "../../Common/alerts/InfoBanner";
import AddDocumentTypesModal from "../common/AddDocumentTypesModal";
import DocumentCommunicationModal from "../common/DocumentCommunicationModal";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import RenderIf from "../../Permissions/RenderIf";
import { Permission } from "../../Permissions/Permissions";
import DocumentsTable from "./DocumentsTable";
import { ResourceDocumentsPermissions } from "../common/SiteResourceDocuments";
import { AiTaskSectionCode } from "../../../../domain/entities/aiTaskSectionCode";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { AiTaskStatus } from "../../../../domain/entities/aiTask";
import ManageTaskWithAiModal from "./ManageTaskWithAiModal";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";
import { DownloadFileScope } from "../../../../domain/repositories/documentsDownloader";
import { MdCloudDownload } from "react-icons/md";

export type Column = {
  field: string;
  filterType: "text" | "tags" | "date-range" | "select";
  label: string;
};

export interface GeneralDocumentsViewProps {
  title: string;
  subtitle: string;
  downloader: UseDownloader;
  props: DocumentsListViewModelProps;
  showExpireDate?: boolean;
  showPublic?: boolean;
  hasEvaluation?: boolean;
  fromSite?: boolean;
  siteGeneralDocument?: boolean;
  permissions: {
    documents: ResourceDocumentsPermissions;
    addDocument: Permission;
    manageDocumentWithAi: Permission;
  };
  aiTaskSectionCode?: AiTaskSectionCode;
  aiFilesPropagable?: boolean;
  siteId?: string;
}

const GeneralDocumentsView = ({
  title,
  downloader,
  showExpireDate = true,
  showPublic,
  fromSite,
  props: {
    documentsProps: {
      createDocument,
      createPropagableDocuments,
      deleteDocument,
      deleteDocumentIsLoading,
      createDocumentIsLoading,
      createPropagableDocumentsIsLoading,
      ...docsProps
    },
    documentTypesProps,
    documentProps,
    communicationProps,
    documentAIProps,
  },
  permissions,
  siteGeneralDocument,
  aiTaskSectionCode,
  aiFilesPropagable,
  siteId
}: GeneralDocumentsViewProps) => {
  const { t } = useTranslation();

  const [showInfoBanner, setShowInfoBanner] = useState(true);
  const [selectedDocument, setSelectedDocument] = useState<Document>();
  const [showUpsertDocument, setShowUpsertDocument] = useState<boolean>(false);
  const [showAskConfirmDelete, setShowAskConfirmDelete] =
    useState<boolean>(false);
  const [showCommunicationModal, setShowCommunicationModal] =
    useState<boolean>(false);
  const [showManageTaskWithAI, setShowManageTaskWithAI] =
    useState<boolean>(false);

  const cancelConfirmDelete = () => {
    setShowAskConfirmDelete(false);
    setSelectedDocument(undefined);
  };

  const confirmDelete = async () => {
    await deleteDocument(selectedDocument.id);
    cancelConfirmDelete();
  };

  const refetchCommentsAndNotes = () => {
    communicationProps.refetchResourceDocumentComments(),
      communicationProps.refetchResourceDocumentNotes();
  };

  const showCreateDocumentFunction = () => {
    const newDocument: Document = {
      id: "",
      type: undefined,
      expiresAt: new Date(),
      files: [],
    };
    setSelectedDocument(newDocument);
    setShowUpsertDocument(true);
  };

  const publicSaveChanges = async (documentTypes: DocumentTypeWithPublic[]) => {
    documentTypes.forEach((type) => {
      const newDocument = { ...selectedDocument, id: "", type };

      setSelectedDocument(newDocument);
    });
    await createPropagableDocuments(documentTypes);
    setShowUpsertDocument(false);
  };

  const saveChanges = async (documentTypes: DocumentType[]) => {
    documentTypes.forEach((type) => {
      const newDocument = { ...selectedDocument, id: "", type };

      setSelectedDocument(newDocument);
    });
    await createDocument(documentTypes.map((doc) => doc.id));
    setShowUpsertDocument(false);
  };

  const availableDocumentTypes = useMemo(
    () =>
      documentTypesProps.documentTypes.filter((documentType) => {
        return !docsProps.documents.find(
          (document) => document.type?.id === documentType.id,
        );
      }),
    [documentTypesProps.documentTypes, docsProps.documents],
  );
  const columns: Column[] = [
    { field: "name", filterType: "text" as const, label: t("name") },
  ];

  if (showExpireDate) {
    columns.splice(1, 0, {
      field: "expiresAt",
      filterType: "date-range",
      label: t("expireDate", { ns: "documents" }),
    });
  }

  if (showPublic) {
    columns.splice(2, 0, {
      field: "isPublic",
      filterType: "select",
      label: t("isPublic", { ns: "requirements" }),
    });
  }
  if (!fromSite) {
    columns.splice(3, 0, {
      field: "tags",
      filterType: "tags" as const,
      label: t("tags"),
    });
  }
  const documentDownloader = useDocumentsFilesDownloader(
    siteId,
    null,
    fromSite ? 'site' as DownloadFileScope : 'company' as DownloadFileScope
  );
  const toast = useToast()

  return (
    <ContentLayout
      action={
        <ActionBar>
          <RenderIf permissions={permissions.addDocument ?? []}>
            <ActionBarItem
              icon={FiPlus}
              description={t("addDocument", { ns: "documents" })}
              onClick={() => showCreateDocumentFunction()}
            />
          </RenderIf>
          <RenderIf permissions={permissions.documents.downloadAll ?? []}>
            <ActionBarItem
              icon={MdCloudDownload}
              description={t("downloadAllFiles", { ns: "documents" })}
              bgColor={COLORS.sikuroBlue}
              color={COLORS.white}
              onClick={() => documentDownloader.initiateDocumentsZipDownload(
                (ok: boolean) => {
                  toast({
                    duration: 5000,
                    isClosable: true,
                    colorScheme: ok ? "green" : "red",
                    icon: ok ? <FiInfo /> : <FiAlertTriangle />,
                    description: ok
                      ? t("downloadAllFilesOk", {
                        ns: "documents"
                      })
                      : t("downloadAllFilesError", {
                        ns: "documents"
                      })
                  });
                }
              )}
            />
          </RenderIf>
          {communicationProps && (
            <RenderIf permissions={permissions.documents.chat ?? []}>
              <ActionBarItem
                bgColor={COLORS.yellow}
                color="black"
                icon={HiOutlineChatAlt2}
                description={t("chat", { ns: "communication" })}
                onClick={() => {
                  refetchCommentsAndNotes(), setShowCommunicationModal(true);
                }}
              />
            </RenderIf>
          )}
          <RenderIf
            check={PermissionCheck.All}
            permissions={[
              permissions.documents.addFile,
              permissions.manageDocumentWithAi,
            ]}
          >
            <ActionBarItem
              icon={FaWandMagicSparkles}
              description={t("manageDocumentWithAi", { ns: "documents" })}
              onClick={() => {
                documentAIProps.aiTasksRefetch();
                setShowManageTaskWithAI(true);
              }}
              isAnimated={documentAIProps.aiTasks.some(
                (task) => task.status === AiTaskStatus.RUNNING
              )}
            />
          </RenderIf>
        </ActionBar>
      }
    >
      <Flex
        flex={1}
        h="100%"
        w="100%"
        padding={10}
        textAlign="center"
        flexDirection="column"
        alignItems="start"
        justifyContent="start"
      >
        <Text
          fontSize="xl"
          textColor={COLORS.sikuroBlue}
          fontWeight="bold"
          mb={4}
        >
          {title}
        </Text>
        <Flex gap={4} width={'full'} alignItems={'center'}>
          {showInfoBanner && (
            <RenderIf permissions={permissions.documents.addFile ?? []}>
              <InfoBanner
                text={t("dragAndDropInfo", { ns: "documents" })}
                onClose={() => setShowInfoBanner(false)}
              />
            </RenderIf>
          )}
        </Flex>

        <Flex
          flexDirection={"column"}
          alignItems={"start"}
          width="100%"
          position="relative"
          overflow={"auto"}
        >
          <DocumentsTable
            {...docsProps}
            {...documentProps}
            fromResource={false}
            downloader={downloader}
            columns={columns}
            showExpiresAt={showExpireDate}
            tags={documentTypesProps.allTags}
            deleteDocument={deleteDocument}
            deleteDocumentIsLoading={deleteDocumentIsLoading}
            addFileToResourceDocument={(documentId, file, siteIds, selectAll) => {
              return documentProps.addFileToDocument(
                documentId,
                file,
                siteIds,
                selectAll
              );
            }
            }
            documentSites={documentProps.documentSites}
            hasSitesNextPage={documentProps.hasSitesNextPage}
            fetchSitesNextPage={documentProps.fetchSitesNextPage}
            fromSite={fromSite}
            communicationProps={communicationProps}
            siteGeneralDocument={siteGeneralDocument}
            permissions={permissions.documents}
            siteCount={documentProps.totalSiteCount}
          />
        </Flex>
      </Flex>

      <DeleteActionAlert
        isLoading={deleteDocumentIsLoading}
        onConfirm={confirmDelete}
        onCancel={cancelConfirmDelete}
        mainTitle={t("warning", { ns: "common" })}
        title={t("confirmDeleteDocumentType", { ns: "documents" })}
        leftButtonText={t("confirm", { ns: "common" })}
        rightButtonText={t("cancel", { ns: "common" })}
        isOpen={showAskConfirmDelete}
      />

      {showUpsertDocument && (
        <AddDocumentTypesModal
          {...documentTypesProps}
          isAddingDocumentsApi={
            createDocumentIsLoading || createPropagableDocumentsIsLoading
          }
          documentTypes={availableDocumentTypes}
          onClose={() => setShowUpsertDocument(false)}
          onConfirm={showPublic ? publicSaveChanges : saveChanges}
          isFetching={documentTypesProps.documentTypesIsFetching}
          isPropagable={showPublic}
          showAvailableDocuments={siteGeneralDocument}
        />
      )}

      {showCommunicationModal && communicationProps && (
        <DocumentCommunicationModal
          onClose={() => setShowCommunicationModal(false)}
          hook={communicationProps}
          permissions={permissions.documents}
        />
      )}
      {showManageTaskWithAI && (
        <ManageTaskWithAiModal
          title={t("uploadWithAi", { ns: "documents" })}
          onClose={() => {
            docsProps.refetchDocuments();
            setShowManageTaskWithAI(false);
          }}
          isPropagable={aiFilesPropagable}
          aiTaskSectionCode={aiTaskSectionCode}
          aiTasks={documentAIProps.aiTasks}
          createAiTask={documentAIProps.createAiTask}
          aiTaskDetail={documentAIProps.aiTaskDetail}
          aiTaskDetailFetching={documentAIProps.aiTaskDetailFetching}
          setSelectedAiTaskId={documentAIProps.setAiTaskId}
          aiTasksHasNextPage={documentAIProps.aiTasksHasNextPage}
          aiTasksFetchNextPage={documentAIProps.aiTasksFetchNextPage}
          aiTasksFetching={documentAIProps.aiTasksFetching}
          onDeleteTask={documentAIProps.deleteAiTask}
          onConfirmTask={documentAIProps.confirmAiTask}
          onConfirmTaskIsLoading={documentAIProps.confirmAitaskIsLoading}
          documentTypesForAi={documentAIProps.documentTypesForAi}
          setEnableDocumentTypesForAiQuery={
            documentAIProps.setEnableDocumentTypesForAiQuery
          }
          sitesWhereToPropagate={documentAIProps.sitesForAiTask}
        />
      )}
    </ContentLayout>
  );
};

export default GeneralDocumentsView;
