import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useMediaQuery,
  useToast
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiMessageX } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";
import Document, { SiteResourceDocument } from "../../../../domain/entities/document";
import FileEntity from "../../../../domain/entities/file";
import { RequirementSubject } from "../../../../domain/entities/requirementSubject.enum";
import { ResourceDocumentEvaluationState } from "../../../../domain/entities/resourceDocumentEvaluationState.enum";
import Site from "../../../../domain/entities/site";
import { downloadFileFromURL } from "../../../../infrastructure/utilities/fileDownloader";
import { formatDateBasedOnLanguage, getDateFormat, processUploadedFiles } from "../../../../utils";
import { COLORS } from "../../../assets/theme/colors";
import { DocumentCommunicationHook } from "../../../hooks/Document/useDocumentCommunicationVIewModel";
import { UseDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import { DeleteActionAlert } from "../../../screens/Common/DeleteActionAlert";
import ImportButton from "../../Common/ImportButton";
import { DocumentDetailHeader } from "./DocumentDetailHeader";
import { RequirementDetailHeader } from "./RequirementDetailHeader";
import SelectSitesView from "./SelectSitesView";
import { Alert } from "../../../screens/Common/Alert";
import { GetSitesFilter } from "../../../../domain/repositories/siteRepository";
import { UpdateFilter } from "../../../hooks/Site/useSiteResourceDetailViewModel";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import LoadingView from "../../../screens/Common/LoadingView";
import RenderIf, { useHasPermissions } from "../../Permissions/RenderIf";
import { PermissionCheck, useAuth } from "../../../providers/Auth0JWTProvider";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import DOMPurify from "dompurify";
import CustomInputDate from "./CustomDateInput/CustomDateInput";
import { format, parse, startOfDay } from "date-fns";
import FullScreenButton from "../../Common/FullScreenButton";
import DocumentExpirationDate from "./DocumentExpirationDate";
import { HiPencil } from "react-icons/hi";
import { ResourceDocumentsPermissions } from "./SiteResourceDocuments";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  setFilterSites?: (value: any) => void;
  isReadOnly: boolean;
  files: FileEntity[];
  document: Document | SiteResourceDocument;
  renameModels: string[];
  deleteFile: (fileId: string, siteIds?: string[], selectAll?: boolean) => void;
  addFile: (files: FileEntity, siteIds: string[], selectAll: boolean) => void;
  editFile?: (
    id?: string,
    name?: string,
    expiresAt?: Date,
    siteIds?: string[],
    selectAll?: boolean
  ) => void;
  editDocument?: (
    expiresAt?: Date,
    isPublic?: boolean,
    noExpiration?: boolean,
    siteIds?: string[],
    file?: FileEntity,
    selectAll?: boolean
  ) => void;
  evaluateDocument?: (
    documentId: string,
    evaluationState?: ResourceDocumentEvaluationState,
    expirationDate?: string,
    noEvaluationExpiration?: boolean,
    siteIds?: string[],
    selectAll?: boolean,
    showCommunications?: boolean
  ) => void;
  communicationProps?: DocumentCommunicationHook;
  sites?: Site[];
  setDocumentSites?: (sites: Site[]) => void;
  onPrev: () => void;
  onNext: () => void;
  type: "document" | "requirements";
  downloader?: UseDownloader;
  showDownloadAll?: boolean;
  canSetExpirationDate?: boolean;
  siteGeneralDocument?: boolean;
  fileIsLoading?: boolean;
  requirementSubject?: RequirementSubject;
  isWorkingSite?: boolean;
  canAddFile?: boolean;
  hasSites?: (action: string, documentTypeId?: string) => Promise<boolean>;
  hasSitesNextPage?: boolean;
  fetchSitesNextPage?: () => void;
  siteCount?: number;
  isFetchingSites?: boolean;
  filterSites?: GetSitesFilter;
  updateFilterSites?: UpdateFilter;
  setSortSites?: (sort: SortMeta) => void;
  sortSites?: SortMeta;
  documentsFetching?: boolean;
  deleteIsLoading: boolean;
  addFileIsLoading: boolean;
  docDetailPermissions: ResourceDocumentsPermissions;
  resourceId?: string;
  resourceCategory?: DocumentTypeCategory;
  errorMsg: string | null;
  setErrorMsg: (value: string) => void;
  selectAllSites?: boolean;
  setSelectAllSites?: (value: boolean) => void;
  showCommunications?: boolean;
}

export const DocumentDetailModal = ({
                                      isOpen,
                                      onClose,
                                      isReadOnly,
                                      files,
                                      document,
                                      renameModels,
                                      communicationProps,
                                      downloader,
                                      siteGeneralDocument,
                                      documentsFetching,
                                      addFileIsLoading,
                                      deleteIsLoading,
                                      errorMsg,
                                      setErrorMsg,
                                      showDownloadAll,
                                      requirementSubject,
                                      fileIsLoading,
                                      isWorkingSite,
                                      isFetchingSites,
                                      hasSites,
                                      filterSites,
                                      updateFilterSites,
                                      setSortSites,
                                      sortSites,
                                      deleteFile,
                                      addFile,
                                      editDocument,
                                      evaluateDocument,
                                      sites,
                                      onPrev,
                                      onNext,
                                      type,
                                      editFile,
                                      docDetailPermissions,
                                      resourceId,
                                      resourceCategory,
                                      hasSitesNextPage,
                                      fetchSitesNextPage,
                                      siteCount,
                                      canAddFile=true,
                                      showCommunications,
                                      setFilterSites
                                    }: Props) => {
  const { t } = useTranslation("documents");
  const toast = useToast();
  const [isTablet] = useMediaQuery("max-width: 1300px");
  const [selectedSites, setSelectedSites] = useState<string[]>([]);
  const [updatedFile, setUpdatedFile] = useState<{
    name: string;
    date: string;
  }>();
  const [selectedEvaluationState, setSelectedEvaluationState] =
    useState<ResourceDocumentEvaluationState>(
      type === "requirements"
        ? document?.evaluationResult
        : ResourceDocumentEvaluationState.UNDEFINED
    );
  const [activeFile, setActiveFile] = useState<FileEntity>();
  const [fileToAdd, setFileToAdd] = useState<FileEntity>();
  const [askConfirmDelete, setAskConfirmDelete] = useState<boolean>(false);
  const [invalidFilename, setInvalidFilename] = useState<boolean>(false);
  const [popoverNameFileId, setPopoverNameFileId] = useState(null);
  const [popoverDateFileId, setPopoverDateFileId] = useState(null);
  const [canEditFile, setCanEditFile] = useState<boolean>(false);
  const [isLoadingDeleteFile, setIsLoadingDeleteFile] = useState(false);
  const [evaluationDate, setEvaluationDate] = useState<Date>(
    (document as Document)?.evaluationExpiresAt
  );
  const [noEvaluationExpiration, setNoEvaluationExpiration] = useState<boolean>(
    document?.noEvaluationExpiration
  );
  const [documentEvaluationValidity, setDocumentEvaluationValidity] =
    useState<string>((document as SiteResourceDocument)?.suggestExpiration);
  const [action, setAction] = useState<
    "add" | "delete" | "evaluate" | "edit" | "editFile" | string | null
  >();
  const [invalidDate, setInvalidDate] = useState<string>();

  const [showErrorDate, setShowErrorDate] = useState(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showSites, setShowSites] = useState<boolean>(false);
  const [showUpdateEvaluation, setShowUpdateEvaluation] =
    useState<boolean>(false);
  const [showErrorEvalution, setShowErrorEvalution] = useState(false);

  const [dateFormat, setDateFormat] = useState(getDateFormat());
  const [selectAllSites, setSelectAllSites] = useState(false);
  const canEdit = useHasPermissions(
    docDetailPermissions.edit,
    PermissionCheck.All
  );
  
  const fileNotReadable = DOMPurify.sanitize(
    t("fileNotReadable", { ns: "documents" })
  );
  const { token } = useAuth();

  const downloadFile = (fileId: string, renameModel: string) => {
    let documentId: string;
    if (type === "document") {
      documentId = document.id;
      downloader?.getFileURL(
        documentId,
        fileId,
        renameModel,
        (url) => {
          downloadFileFromURL(url, token.access_token);
        },
        "company"
      );
    } else {
      documentId = (document as SiteResourceDocument).documentId;
      downloader?.getFileURL(
        documentId,
        fileId,
        renameModel,
        (url) => {
          downloadFileFromURL(url, token.access_token);
        },
        "site"
      );
    }
  };

  const handleDeleteFile = async (siteIds?: string[], selectAll?: boolean) => {
    await deleteFile(activeFile?.id, siteIds ? siteIds : [], selectAll);
    setShowEdit(false);
    setActiveFile(null);
  };

  const handleAddFile = async (
    file?: FileEntity,
    siteIds?: string[],
    selectAll?: boolean
  ) => {
    setShowEdit(false);
    if (document.id) {
      await addFile(file, siteIds ? siteIds : [], selectAll);
    } else {
      await editDocument(
        (document as Document).expiresAt,
        (document as Document).isPublic,
        (document as Document).noExpiration,
        selectedSites,
        file,
        selectAll
      );
    }
    setFileToAdd(null);
    setShowEdit(false);
    if (hasSites && !hasSites("add", type === "document" && (document as Document).type.id )) {
      setAction(null);
    }
  };


  const handleDragIn = () => {
    if (canEdit ) {
      if (!toast.isActive("uploadToast")) {
        toast({ id: "uploadToast", description: t("dropDocument") });
      }
    } else {
      toast({
        id: "uploadToast",
        description: t("noPermission", { ns: "documents" }),
        status: "error"
      });
    }
  };
  const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    canAddFile && setAction("add");

    if (hasSites && !hasSites("add", type === "document" && (document as Document).type.id )) {
      setAction(null);
    }

    if (canEdit) {
      if (e?.dataTransfer?.files[0]) {
        handleFileDrop(e.dataTransfer.files);
      }
    }
  };

  const handleFileDrop = async (files: FileList | null) => {
    if (!files) {
      return;
    }
    const fileList = processUploadedFiles(files);
    setFileToAdd(fileList);
    if (
      Object.hasOwn(document, "isPublic") &&
      !(document as Document).isPublic
    ) {
      toast.closeAll();
      return;
    }
    const hasSiteItems = hasSites && (await hasSites("add", type === "document" && (document as Document).type.id ));
    if (hasSiteItems) {
      setShowEdit(true);
      setShowSites(true);
      setAction("add");
    } else {
      await handleAddFile(fileList);
    }
    toast.closeAll();
  };

  const handleEditDocument = async (
    validity?: Date,
    isPublic?: boolean,
    noExpiration?: boolean
  ) => {
    editDocument(validity, isPublic, noExpiration);
  };

  const handleEvaluation = async (
    documentId: string,
    evaluationState: ResourceDocumentEvaluationState
  ) => {
    const hasSiteItems = hasSites && (await hasSites("evaluate", type === "document" && (document as Document).type.id ));
    const isDocumentType = type === "document";
    const isRequirementType = type === "requirements";
    const isAvailableState =
      evaluationState === ResourceDocumentEvaluationState.AVAILABLE;

    if (isAvailableState) {
      setShowEdit(true);
      setAction("evaluate");
      if (isDocumentType) {
        if (hasSiteItems) {
          setShowSites(true);
        } else {
          setShowUpdateEvaluation(true);
          setShowSites(false);
        }
      } else if (isRequirementType) {
        setShowUpdateEvaluation(true);
      }
    } else if (!isAvailableState && isDocumentType && hasSiteItems) {
      setShowEdit(true);
      setShowSites(true);
    } else {
      evaluateDocument(documentId, evaluationState);
      setShowUpdateEvaluation(false);
    }
  };

  useEffect(() => {
    if ((document as Document).expiresAt) {
      setEvaluationDate(new Date((document as Document).expiresAt));
    }
    if (type === "requirements" && document) {
      const formattedDate = format(
        new Date((document as SiteResourceDocument).suggestExpiration),
        dateFormat
      );
      setDocumentEvaluationValidity(formattedDate);
    }
  }, [document, type]);

  const handleSave = async (siteIds?: string[], selectAll?: boolean) => {
    setSelectedSites(siteIds);
    switch (action) {
      case "delete":
        return await handleDeleteFile(siteIds, selectAll);
      case "add":
        return await handleAddFile(fileToAdd, siteIds, selectAll);
      case "edit":
        await editDocument(
          (document as Document)?.expiresAt,
          (document as Document)?.isPublic,
          (document as Document).noExpiration,
          siteIds,
          fileToAdd,
          selectAll
        );
        setShowEdit(false);
        return;

      case "evaluate":
        await evaluateDocument(
          document.id,
          selectedEvaluationState || document.evaluationResult,
          selectedEvaluationState ===
          ResourceDocumentEvaluationState.AVAILABLE &&
          showUpdateEvaluation &&
          evaluationDate
            ? format(evaluationDate, dateFormat)
            : undefined,
          noEvaluationExpiration,
          siteIds,
          selectAll
        );
        setShowEdit(false);
        setShowUpdateEvaluation(false);
        return;

      case "editFile":
        await editFile(
          activeFile?.id,
          updatedFile?.name,
          updatedFile.date
            ? parse(updatedFile.date, dateFormat, new Date())
            : null,
          siteIds,
          selectAll
        );
        setShowEdit(false);
        setCanEditFile(false);
        setPopoverNameFileId(null);
        setPopoverDateFileId(null);
        return;

      default:
        break;
    }
  };

  const addFileToDoc = async (file: FileEntity, action: string) => {
    setAction(action);

    if (!(document as Document).isPublic) {
      await handleAddFile(file);
      return;
    }

    const hasSiteItems = hasSites && (await hasSites(action, type === "document" && (document as Document).type.id ));
    if (hasSiteItems) {
      setShowEdit(true);
      setShowSites(true);
    } else {
      await handleAddFile(file);
    }
  };

  const deleteFileFromDoc = async (file: FileEntity) => {
    setAction("delete");
    if (!(document as Document).isPublic) {
      setAskConfirmDelete(true);
      return;
    }
    if (siteGeneralDocument) {
      setAskConfirmDelete(true);
    } else {
      const hasSiteItems = hasSites && (await hasSites("delete", type === "document" && (document as Document).type.id ));
      if (hasSiteItems) {
        setShowEdit(true);
        setShowSites(true);
      } else {
        setAskConfirmDelete(true);
      }
    }
  };

  const editFileName = async () => {
    setCanEditFile(false);

    if (!(document as Document).isPublic) {
      editFile(
        activeFile?.id,
        updatedFile?.name,
        updatedFile?.date
          ? parse(updatedFile?.date, dateFormat, new Date())
          : null
      );
      setCanEditFile(false);
      return;
    }

    const hasSiteItems = hasSites && (await hasSites(action, type === "document" && (document as Document).type.id ));
    if (hasSiteItems) {
      setShowEdit(true);
      setShowSites(true);
      setAction("editFile");
    } else {
      editFile(
        activeFile?.id,
        updatedFile?.name,
        updatedFile?.date
          ? parse(updatedFile?.date, dateFormat, new Date())
          : null
      );
      setCanEditFile(false);
    }
  };
  const editFileDate = async () => {
    if (!(document as Document).isPublic) {
      editFile(
        activeFile?.id,
        updatedFile?.name,
        updatedFile?.date
          ? parse(updatedFile?.date, dateFormat, new Date())
          : null
      );
      setCanEditFile(false);
      setPopoverNameFileId(null);
      setPopoverDateFileId(null);
      return;
    }

    const hasSiteItems = hasSites && (await hasSites(action, type === "document" && (document as Document).type.id ));
    if (hasSiteItems) {
      setShowEdit(true);
      setShowSites(true);
      setAction("editFile");
    } else {
      editFile(
        activeFile?.id,
        updatedFile?.name,
        updatedFile?.date
          ? parse(updatedFile?.date, dateFormat, new Date())
          : null
      ),
        setCanEditFile(false),
        setPopoverNameFileId(null),
        setPopoverDateFileId(null);
    }
  };

  const didRenameDocument = (name: string) => {
    // This regex allows strings that may start or end with a space, hyphen, or underscore,
    // while accepting valid combinations of alphanumeric characters, spaces, hyphens, and underscores.
    const validNameRX = /^(?:[\w\s-]*[\w\s])?[\w\s-]*$/i;
    const nameIsValid = validNameRX.test(name);
    if (nameIsValid) {
      setUpdatedFile((current) => ({ ...current, name }));
    }

    setInvalidFilename(!nameIsValid);
  };

  function isDocument(doc: Document | SiteResourceDocument): doc is Document {
    return (doc as Document).type !== undefined;
  }

  useEffect(() => {
    if ((document as Document).expiresAt) {
      setEvaluationDate(new Date((document as Document).expiresAt));
    }
    if (type === "requirements" && document) {
      const formattedDate = format(
        new Date((document as SiteResourceDocument).suggestExpiration),
        dateFormat
      );
      setDocumentEvaluationValidity(formattedDate);
    }
  }, [document, type]);

  useEffect(() => {
    if (files) {
      setActiveFile(files[0]);
    }
  }, [files]);

  useEffect(() => {
    if (document) {
      setAction(null);
    }
  }, [document]);

  useEffect(() => {
    if (activeFile) {
      const formatted =
        activeFile?.expiresAt &&
        formatDateBasedOnLanguage(activeFile?.expiresAt);
      setUpdatedFile((prev) => ({
        ...prev,
        name: activeFile?.name,
        date: formatted || null
      }));
    }
  }, [activeFile, files]);

  useEffect(() => {
    setAction(null);
  }, [errorMsg]);

  function openFilePreviewWindow(file: FileEntity) {
    if (file?.uri) {
      window.open(file?.uri);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setActiveFile(null);
      }}
      size={"6xl"}
    >
      <ModalOverlay />
      <ModalContent h={"85vh"} w={"full"}>
        <ModalCloseButton />
        <Grid
          templateRows="repeat(12, 1fr)"
          templateColumns="repeat(3, 1fr)"
          borderRadius={"md"}
          h={"full"}
        >
          {type === "document" ? (
            <DocumentDetailHeader
              document={document as Document}
              renameModels={renameModels}
              isReadOnly={isReadOnly}
              downloader={downloader}
              editDocument={(validity, isPublic, noExpiration) =>
                handleEditDocument(validity, isPublic, noExpiration)
              }
              evaluateDocument={(documentId, evaluationState) =>
                handleEvaluation(documentId, evaluationState)
              }
              action={action}
              setAction={setAction}
              selectedEvaluationState={selectedEvaluationState}
              setSelectedEvaluationState={setSelectedEvaluationState}
              setActiveFile={(file) => {
                setActiveFile(file);
              }}
              showOverlay={showUpdateEvaluation}
              communicationProps={communicationProps}
              setShowEdit={setShowEdit}
              setShowUpdateEvaluationDate={setShowUpdateEvaluation}
              onPrev={onPrev}
              onNext={onNext}
              showDownloadAll={showDownloadAll}
              siteGeneralDocument={siteGeneralDocument}
              hasSites={hasSites}
              documentsFetching={documentsFetching}
              disabledFunctions={fileToAdd && action !== null}
              setFileToAdd={setFileToAdd}
              setShowSites={setShowSites}
              permissions={docDetailPermissions}
              setShowErrorEvalution={setShowErrorEvalution}
            />
          ) : (
            <RequirementDetailHeader
              resourceId={resourceId}
              resourceCategory={resourceCategory}
              document={document as SiteResourceDocument}
              requirementSubject={requirementSubject}
              renameModels={renameModels}
              downloader={downloader}
              evaluateDocument={(documentId, evaluationState) =>
                handleEvaluation(documentId, evaluationState)
              }
              selectedEvaluationState={selectedEvaluationState}
              setSelectedEvaluationState={setSelectedEvaluationState}
              setShowEdit={setShowEdit}
              setShowUpdateEvaluationDate={setShowUpdateEvaluation}
              setActiveFile={(file) => {
                setActiveFile(file);
              }}
              showOverlay={showUpdateEvaluation}
              communicationProps={communicationProps}
              isWorkingSite={isWorkingSite}
              updateEvaluationDate={String(evaluationDate)}
              sites={sites}
              onPrev={onPrev}
              onNext={onNext}
              hasSites={hasSites}
              documentsFetching={documentsFetching}
              permissions={docDetailPermissions}
              action={action}
              setAction={setAction}
              canComment={showCommunications}
            />
          )}
          <GridItem
            colSpan={1}
            rowSpan={11}
            w={"sm"}
            bg="white"
            position={"relative"}
            h={"full"}
            borderRadius={"0 0 0 5px"}
            onDragEnter={() => handleDragIn()}
            onDrop={(e) => handleDrop(e)}
            onDragOver={(e) => e.preventDefault()}
          >
            <Box
              h={docDetailPermissions.edit ? "90%" : "100%"}
              overflow={"auto"}
              sx={{
                "&::-webkit-scrollbar": {
                  display: "none"
                },
                scrollbarWidth: "none",
                msOverflowStyle: "none"
              }}
              borderRight={"1px solid #efefef"}
            >
              {fileIsLoading ? (
                <Stack>
                  <Skeleton height="140px" />
                  <Skeleton height="140px" />
                  <Skeleton height="140px" />
                  <Skeleton height="140px" />
                </Stack>
              ) : files?.length > 0 ? (
                files.map((file: FileEntity) => (
                  <Flex
                    key={file.id}
                    p={4}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    color={activeFile?.id === file.id ? "white" : "unset"}
                    backgroundColor={
                      activeFile?.id === file.id ? COLORS.sikuroBlue : "unset"
                    }
                    _hover={{ background: COLORS.sikuroBlue, color: "white" }}
                    onClick={() => {
                      setActiveFile(file);
                    }}
                    cursor={"pointer"}
                  >
                    <Flex flexDirection={"column"} gap={1}>
                      <Text>
                        {file.name}.{file.ext}
                        <RenderIf permissions={docDetailPermissions.edit}>
                          {activeFile && !siteGeneralDocument && (
                            <Popover
                              isOpen={popoverNameFileId === file.id}
                              closeOnBlur={false}
                              onClose={() => {
                                setPopoverNameFileId(null);
                              }}
                            >
                              <PopoverTrigger>
                                {docDetailPermissions.edit && (
                                  <Button
                                    background={"transparent"}
                                    _hover={{ background: "transparent" }}
                                    onClick={() => {
                                      setPopoverNameFileId(file.id);
                                    }}
                                  >
                                    <HiPencil color="white" />
                                  </Button>
                                )}
                              </PopoverTrigger>
                              <PopoverContent>
                                <PopoverArrow />
                                <PopoverCloseButton color={"black"} />
                                <PopoverHeader color={"black"}>
                                  {t("updateFileName")}
                                </PopoverHeader>
                                <PopoverBody padding={4}>
                                  {activeFile && !siteGeneralDocument && (
                                    <Flex>
                                      <InputGroup
                                        size="md"
                                        display={"flex"}
                                        alignItems={"center"}
                                        mt={1}
                                      >
                                        <Input
                                          type="text"
                                          size={"sm"}
                                          color={"black"}
                                          onChange={(e) =>
                                            didRenameDocument(e.target.value)
                                          }
                                          onKeyDown={(e) => {
                                            if (e.key !== "Enter") {
                                              return;
                                            }
                                            editFileName();
                                          }}
                                          value={updatedFile?.name}
                                        />
                                        <InputRightAddon
                                          cursor={
                                            updatedFile?.name && "pointer"
                                          }
                                          background={COLORS.sikuroBlue}
                                          opacity={updatedFile?.name ? 1 : 0.5}
                                          h={8}
                                          onClick={(e) =>
                                            updatedFile?.name
                                              ? editFileName()
                                              : e.preventDefault()
                                          }
                                        >
                                          <Flex
                                            alignItems={"center"}
                                            w={"full"}
                                            h={"full"}
                                          >
                                            <FaCheck color="white" />
                                          </Flex>
                                        </InputRightAddon>
                                      </InputGroup>
                                    </Flex>
                                  )}
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          )}
                        </RenderIf>
                      </Text>
                      <Text>
                        {t("expireDate")}:{" "}
                        {file.expiresAt
                          ? formatDateBasedOnLanguage(file.expiresAt)
                          : "-"}
                        <RenderIf permissions={docDetailPermissions.edit}>
                          {activeFile && !siteGeneralDocument && (
                            <Popover
                              isOpen={popoverDateFileId === file.id}
                              closeOnBlur={false}
                              onClose={() => {
                                setPopoverDateFileId(null);
                                setUpdatedFile((prev) => ({
                                  ...prev,
                                  date: null
                                }));
                              }}
                            >
                              <PopoverTrigger>
                                {docDetailPermissions.edit && (
                                  <Button
                                    background={"transparent"}
                                    _hover={{ background: "transparent" }}
                                    onClick={() =>
                                      setPopoverDateFileId(file.id)
                                    }
                                  >
                                    <HiPencil color="white" />
                                  </Button>
                                )}
                              </PopoverTrigger>
                              <PopoverContent>
                                <PopoverArrow />
                                <PopoverCloseButton color={"black"} />
                                <PopoverHeader color={"black"}>
                                  {t("updateFileExpiresDate")}
                                </PopoverHeader>
                                <PopoverBody padding={4}>
                                  <InputGroup
                                    size="md"
                                    display={"flex"}
                                    alignItems={"start"}
                                    mt={1}
                                  >
                                    <CustomInputDate
                                      value={updatedFile?.date}
                                      onChange={(val) =>
                                        setUpdatedFile((prev) => ({
                                          ...prev,
                                          date: val
                                        }))
                                      }
                                      minDate={startOfDay(new Date())}
                                      maxDate={new Date("9999-12-31")}
                                      hasError={setShowErrorDate}
                                      setValue={setInvalidDate}
                                    />
                                    <InputRightAddon
                                      cursor={"pointer"}
                                      background={COLORS.sikuroBlue}
                                      onClick={() => {
                                        return editFileDate();
                                      }}
                                    >
                                      <FaCheck color="white" />
                                    </InputRightAddon>
                                  </InputGroup>
                                  {showErrorDate && (
                                    <Text
                                      color={"#E53E3E"}
                                      fontSize="0.875rem"
                                      py="2"
                                      lineHeight={"normal"}
                                    >
                                      {t("invalidDateError", {
                                        ns: "common",
                                        date: invalidDate
                                      })}
                                    </Text>
                                  )}
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          )}
                        </RenderIf>
                      </Text>
                    </Flex>
                    <Flex alignItems={"center"}>
                      <RenderIf permissions={docDetailPermissions.downloadAll}>
                        <Menu placement="auto">
                          <Tooltip
                            label={t("downloadFile", { ns: "documents" })}
                          >
                            <MenuButton>
                              <IoMdDownload fontSize="24px" />
                            </MenuButton>
                          </Tooltip>
                          <MenuList color={"black"}>
                            <MenuGroup
                              title={t("downloadFile", { ns: "documents" })}
                            >
                              {renameModels?.map((name) => (
                                <MenuItem
                                  key={name}
                                  minH="48px"
                                  onClick={() => downloadFile(file.id, name)}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                            </MenuGroup>
                          </MenuList>
                        </Menu>
                      </RenderIf>
                      <RenderIf permissions={docDetailPermissions.delete}>
                        {/*NM:i'm using the same property as for addFile because has the same rules (companyId === siteResource.companyId)*/}
                        {canAddFile && <DeleteIcon
                          fontSize="1.2rem"
                          fill={activeFile?.id === file.id ? "white" : "unset"}
                          onClick={() => deleteFileFromDoc(file)}
                        />}
                      </RenderIf>
                    </Flex>
                  </Flex>
                ))
              ) : (
                <Flex
                  justifyContent={"center"}
                  alignItems={"center"}
                  h={"full"}
                >
                  <Text>{t("noAvailableFiles")}</Text>
                </Flex>
              )}
            </Box>
            <RenderIf permissions={docDetailPermissions.addFile}>
              {canAddFile && <Flex
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                h={"10%"}
                borderRight={"1px solid #efefef"}
              >
                <ImportButton
                  isDisabled={action !== null || fileIsLoading}
                  bgColor={COLORS.sikuroBlue}
                  textColor="white"
                  description={t("add")}
                  type="btn"
                  uploadFile={(file) => {
                    if (file) {
                      addFileToDoc(file, "add");
                      setFileToAdd(file);
                    }
                  }}
                />
              </Flex>}
            </RenderIf>
          </GridItem>
          <GridItem
            colSpan={2}
            rowSpan={11}
            borderRadius={"5px"}
            zIndex={
              action === "evaluate" ||
                action === "add" ||
                action === "editFile" ||
                action === "delete"
                ? 999
                : 0
            }
            bg={
              action === "evaluate" ||
                action === "add" ||
                action === "editFile" ||
                action === "delete"
                ? COLORS.white
                : ""
            }
          >
            {showEdit ? (
              <Box
                p={4}
                h={"full"}
                overflow={"auto"}
                zIndex={
                  action === "evaluate" ||
                    action === "add" ||
                    action === "editFile" ||
                    action === "delete"
                    ? 999
                    : 0
                }
                sx={{
                  "&::-webkit-scrollbar": {
                    display: "none"
                  },
                  scrollbarWidth: "none",
                  msOverflowStyle: "none"
                }}
              >
                {showUpdateEvaluation && (
                  <Box>
                    {type === "requirements" && (
                      <>
                        <Text marginTop={2} fontWeight={"bold"}>
                          {t("manageEvaluationExpiration", {
                            ns: "siteResources"
                          })}
                        </Text>

                        <Flex
                          alignItems={"center"}
                          gap={2}
                          mt={1}
                          fontSize={"18px"}
                          width={"100%"}
                        >
                          <DocumentExpirationDate
                            setAction={setAction}
                            expiresAt={new Date(documentEvaluationValidity)}
                            documentDuration={
                              isDocument(document)
                                ? document?.type?.duration
                                : document?.documentTypeDuration
                            }
                            isDisabled={noEvaluationExpiration}
                            updateExpirationDate={(data) =>
                              setEvaluationDate(data)
                            }
                            noExpiration={noEvaluationExpiration}
                            setNoExpiration={setNoEvaluationExpiration}
                            showNoExpiration={true}
                            showButtons={false}
                          />
                        </Flex>
                        <Box>
                          {showErrorDate && (
                            <Text
                              color={"#E53E3E"}
                              fontSize="0.875rem"
                              py="2"
                              lineHeight={"normal"}
                            >
                              {t("invalidDateError", {
                                ns: "common",
                                date: invalidDate
                              })}
                            </Text>
                          )}
                        </Box>
                        <Flex gap={4} py={4} justifyContent={"flex-end"}>
                          <Button
                            colorScheme="red"
                            onClick={() => {
                              setAction(null);
                              setShowErrorDate(false);
                              setShowUpdateEvaluation(false);
                              setShowEdit(false);
                              setSelectedEvaluationState(
                                document?.evaluationResult
                              );
                            }}
                          >
                            {t("cancel", { ns: "common" })}
                          </Button>
                          <Button
                            colorScheme="blue"
                            isDisabled={
                              showErrorDate ||
                              (!noEvaluationExpiration && !evaluationDate)
                            }
                            onClick={() => {
                              evaluateDocument(
                                document.id,
                                selectedEvaluationState,
                                evaluationDate
                                  ? format(new Date(evaluationDate), dateFormat)
                                  : undefined,
                                noEvaluationExpiration
                              );
                              setShowEdit(false);
                              setShowErrorDate(false);
                              setShowUpdateEvaluation(false);
                              setAction(null);
                            }}
                          >
                            {t("save", { ns: "common" })}
                          </Button>
                        </Flex>
                      </>
                    )}
                    {type === "document" && (
                      <>
                        {sites.length > 0 && (
                          <>
                            <Text marginTop={3} fontWeight={"bold"}>
                              {t("manageEvaluationExpiration", {
                                ns: "siteResources"
                              })}
                            </Text>
                            <Flex
                              alignItems={"center"}
                              gap={2}
                              mt={2}
                              fontSize={"18px"}
                              width={"100%"}
                            >
                              <DocumentExpirationDate
                                setAction={setAction}
                                expiresAt={evaluationDate}
                                documentDuration={
                                  isDocument(document)
                                    ? document?.type?.duration
                                    : document?.documentTypeDuration
                                }
                                isDisabled={noEvaluationExpiration}
                                updateExpirationDate={(data) => {
                                  setEvaluationDate(data);
                                }}
                                noExpiration={noEvaluationExpiration}
                                setNoExpiration={setNoEvaluationExpiration}
                                showNoExpiration={true}
                                showButtons={false}
                              />
                            </Flex>
                          </>
                        )}
                        {showErrorDate && (
                          <Box>
                            <Text
                              color={"#E53E3E"}
                              fontSize="0.875rem"
                              py="2"
                              lineHeight={"normal"}
                            >
                              {t("invalidDateError", {
                                ns: "common",
                                date: invalidDate
                              })}
                            </Text>
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                )}
                {showSites && (
                  <>
                    {(isFetchingSites && sites.length === 0) ||
                      addFileIsLoading ? (
                      <LoadingView />
                    ) : (
                      <SelectSitesView
                        title={t("selectSites")}
                        reminderText={t("noSiteSelected", { ns: "common" })}
                        alertText={t("siteSelectionAlert", { ns: "common" })}
                        onClose={() => {
                          setSelectedEvaluationState(
                            type === "requirements"
                              ? document?.evaluationResult
                              : ResourceDocumentEvaluationState.UNDEFINED
                          );
                          setShowEdit(false);
                          setAction(null);
                          setShowUpdateEvaluation(false);
                          setActiveFile(null);
                          updateFilterSites(null, null);
                          setEvaluationDate(null);
                          setNoEvaluationExpiration(false);
                          setFilterSites && setFilterSites(null);
                        }}
                        onSaveAction={(siteIds, selectAll) => {
                          handleSave((selectAll ? [] : siteIds), selectAll);
                          setAction(null);
                          setShowErrorDate(false);
                          setFilterSites && setFilterSites(null);
                        }}
                        action={action}
                        isDisabled={
                          showErrorDate ||
                          (action === "evaluate" && selectedEvaluationState === ResourceDocumentEvaluationState.AVAILABLE &&
                            !noEvaluationExpiration &&
                            !evaluationDate)
                        }
                        siteList={sites}
                        includeFooterButton={true}
                        operationIsBusy={addFileIsLoading}
                        filterResourceSites={filterSites}
                        updateFilterResourceSites={updateFilterSites}
                        setSortResourceSites={setSortSites}
                        sortResourceSites={sortSites}
                        isTablet={isTablet}
                        hasNextPage={hasSitesNextPage}
                        fetchNextPage={fetchSitesNextPage}
                        siteCount={siteCount}
                        selectAllSites={selectAllSites}
                        setSelectAllSites={setSelectAllSites}
                        showSelectAll={true}
                      />
                    )}
                  </>
                )}
              </Box>
            ) : (
              <Flex
                p={4}
                h={"full"}
                overflow={"auto"}
                sx={{
                  "&::-webkit-scrollbar": {
                    display: "none"
                  },
                  scrollbarWidth: "none",
                  msOverflowStyle: "none"
                }}
                flexDirection={"column"}
                gap={4}
              >
                {!activeFile && !showErrorEvalution && (
                  <Flex
                    width="100%"
                    height="100%"
                    justifyContent="center"
                    alignContent="center"
                    style={{
                      border: "1px solid #E2E8F2",
                      borderRadius: "4px",
                      height: "100%"
                    }}
                  >
                    {fileIsLoading ||
                      action === "add" ||
                      showUpdateEvaluation ? (
                      <LoadingView />
                    ) : (
                      <Text alignSelf={"center"}>
                        {t("fileNotSelected", { ns: "documents" })}
                      </Text>
                    )}
                  </Flex>
                )}

                {showErrorEvalution && (
                  <Box gap={4} py={4}>
                    <Text color="red" textAlign={"center"} fontWeight={"bold"}>
                      {t("warning", { ns: "common" })}
                    </Text>
                    <Text color="red">{t("noSitesToAssociateEvaluation")}</Text>
                    <Flex gap={4} py={4} justifyContent={"flex-end"}>
                      <Button
                        colorScheme="yellow"
                        onClick={() => {
                          setAction(null);
                          setShowErrorEvalution(false);
                          setShowUpdateEvaluation(false);
                          setShowErrorDate(false);
                          setShowEdit(false);
                        }}
                      >
                        {t("cancel", { ns: "common" })}
                      </Button>
                    </Flex>
                  </Box>
                )}

                {activeFile &&
                  activeFile.ext != "xlsx" &&
                  activeFile.ext != "xls" &&
                  activeFile.ext != "zip" &&
                  activeFile.ext != "doc" &&
                  activeFile.ext != "docx" &&
                  (fileIsLoading || action === "add" || isLoadingDeleteFile ? (
                    <LoadingView />
                  ) : (
                    <Flex width={"100%"} height={"100%"}>
                      <iframe
                        src={activeFile?.uri}
                        title={`PDF Preview ${activeFile?.name}`}
                        width="100%"
                        style={{
                          border: "1px solid #E2E8F2",
                          borderRadius: "4px",
                          height: "100%"
                        }}
                      />
                      <FullScreenButton
                        onClick={() => {
                          openFilePreviewWindow(activeFile);
                        }}
                      />
                    </Flex>
                  ))}
                {activeFile &&
                  (activeFile.ext === "xlsx" ||
                    activeFile.ext === "xls" ||
                    activeFile.ext === "zip" ||
                    activeFile.ext === "doc" ||
                    activeFile.ext === "docx") &&
                  (fileIsLoading || action === "add" ? (
                    <LoadingView />
                  ) : (
                    <Flex
                      title={`PDF Preview ${activeFile?.name}`}
                      backgroundColor={"gray.300"}
                      width="100%"
                      height="100%"
                      style={{
                        border: "1px solid black",
                        borderRadius: "4px",
                        height: "100%"
                      }}
                    >
                      <Flex
                        justifySelf="center"
                        alignSelf="center"
                        width="100%"
                        paddingLeft={5}
                        paddingRight={5}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                      >
                        <Icon
                          fontSize="40px"
                          color={"gray.500"}
                          as={BiMessageX}
                        />
                        <Text
                          mt={4}
                          textAlign={"center"}
                          textColor={"gray.600"}
                          dangerouslySetInnerHTML={{ __html: fileNotReadable }}
                        />
                      </Flex>
                    </Flex>
                  ))}
              </Flex>
            )}
          </GridItem>
        </Grid>
        {askConfirmDelete && (
          <DeleteActionAlert
            onConfirm={async () => {
              await handleDeleteFile();
              setAskConfirmDelete(false);
              setAction(null);
            }}
            onCancel={() => {
              setAskConfirmDelete(false);
              setAction(null);
            }}
            isLoading={deleteIsLoading}
            mainTitle={t("warning", { ns: "common" })}
            title={t("confirmDeleteFile")}
            leftButtonText={t("confirm", { ns: "common" })}
            rightButtonText={t("cancel", { ns: "common" })}
            isOpen={!!askConfirmDelete}
          />
        )}
        {errorMsg && (
          <Alert
            title={t("warning", { ns: "common" })}
            message={t(errorMsg, { ns: "errors" })}
            variant="info"
            onClose={() => {
              setAction(null);
              setErrorMsg(null);
            }}
          />
        )}
        {invalidFilename && (
          <Alert
            onClose={() => {
              setInvalidFilename(false);
              setAction(null);
            }}
            title={t("warning", { ns: "common" })}
            message={t("invalidFileName")}
            variant="question"
          />
        )}
      </ModalContent>
    </Modal>
  );
};
