import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
	family: 'Roboto',
	fonts: [{ src: '/fonts/Roboto-Regular.ttf' }, { src: '/fonts/Roboto-Bold.ttf', fontWeight: 700 }],
});

export const styles = StyleSheet.create({
	badgeContainer: {
		border: '1px solid black',
		padding: '3mm',
		width: '85.7mm',
		height: '53.9mm',
		fontSize: 8,
		fontFamily: 'Roboto',
	},
  siteName: {
    fontSize: 10,
    fontWeight: 900,
    width: '100%',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  companyNameContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: "row",
    marginVertical: '3mm'
  },
	mainContainer: {
		flexDirection: 'row',
    height: '100%'
	},
	photoContainer: {
		width: '25mm',
		height: '25mm',
		marginRight: '3mm',
	},
	qrCodeContainer: {
		width: '40mm',
		height: '40mm',
		margin: '0 auto',
	},
	photo: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	badgeDetailsContainer: {
		flexDirection: 'column',
    width: "51.7mm",
    height: "100%",
	},
  badgeDetailsRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    paddingBottom: "1mm",
	},
	footerContainer: {
		width: '100%',
    height: '6mm',
		textAlign: 'right',
	},
  companyName: {
    fontSize: 10,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  emptyContainer: {
    width: '85.7mm',
    height: '53.9mm',
  },
  text: {
    flex: 1,
  }
});
