import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { SubmitErrorHandler, useForm } from "react-hook-form";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { SystemStaffRole } from "../../../domain/entities/staff";
import {
  BusinessSize,
  Credentials,
  LicenseType,
  SignUpCompany,
  SignUpUser,
} from "../../../domain/interactors/auth/args";
import { useAuth } from "../../providers/Auth0JWTProvider";
import OnboardingViewModel from "../../viewmodels/onboarding/OnboardingViewModel";
import {useTranslation} from "react-i18next";

export const SYSTEM_STAFF_ROLES: SystemStaffRole[] = [
  "employer",
  "rls",
  "rspp",
  "doctor",
  "contact",
];

const staffInitialValues = {
  name: "",
  surname: "",
  email: "",
  phone: "",
};

export type FormType = SignUpCompany & SignUpUser;
const useOnboardingViewModel = (
  onValidationError: SubmitErrorHandler<FormType> = undefined,
) => {
  const vm = new OnboardingViewModel();
  const [searchParams] = useSearchParams({ email: "" });
  const location = useLocation();
  const { setLoginData } = useAuth();
  const navigate = useNavigate();
  const token = searchParams.get("token"); // invite token
  const company = searchParams.get("company");
  const email = searchParams.get("email") ?? "";
  const methods = useForm<FormType>({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      email,
      password: "",
      confirmPassword: "",
      licenseType: LicenseType.Smart,
      privacyPolicy: false,
      newsletter: false,
      businessName: "",
      vatCountry: "IT",
      vat: "",
      businessSize: BusinessSize.Micro,
      businessType: null,
      firstName: "",
      lastName: "",
      pec: "",
      ccnl: "",
      ...SYSTEM_STAFF_ROLES.reduce(
        (acc, role) => ({ ...acc, [role]: staffInitialValues }),
        {},
      ),
    },
  });

  const { t } = useTranslation('onboarding')

  const [activationError, setActivationError] = useState<string>();
  const [error, setError] = useState<string>();
  const [successCreateAccount, setSuccessCreateAccount] =
    useState<boolean>(false);
  const [successRequestPasswordReset, setSuccessRequestPasswordReset] =
    useState<boolean>(false);
  const userAlreadyRegistered = useQuery(
    ["user-already-registered", token, company],
    async () => {
      try {
        return (
          token && company && (await vm.userAlreadyRegistered(token, company))
        );
      } catch (error) {
        setActivationError(`${error.message}`);
        return { already_registered: false };
      }
    },
  );

  const createPersonalAccountMutation = useMutation(
    async (user: SignUpUser) => {
      return vm.createPersonalAccount({ ...user, token });
    },
    {
      onSuccess: () => setSuccessCreateAccount(true),
      onError: (error: Error) => {
        if (error.message === "error.email-conflict") {
          methods.setError("email", { message: t(`apiErrors.${error.message}`) });
        }
        setError(error.message)
      },
    },
  );

  const createBusinessAccountMutation = useMutation(
    async (company: SignUpCompany) => {
      return vm.createBusinessAccount({ ...company, token });
    },
    {
      onSuccess: () => setSuccessCreateAccount(true),
      onError: (error: Error) => {
        const message = t(`apiErrors.${error.message}`)
        if (error.message === "error.email-conflict") {
          methods.setError("email", { message });
          onValidationError?.({ email: message } as any);
        }
        if (error.message === "error.vat-code-conflict") {
          methods.setError("vat", { message });
          onValidationError?.({ vat: message } as any);
        }
        setError(error.message)
      },
    },
  );

  const loginMutation = useMutation(
    async ({ email, password }: Credentials) => {
      const loginData = await vm.login(email, password);
      localStorage.setItem("tenantLogo", loginData.company?.logo);
      localStorage.setItem("tenantName", loginData.company?.name);
      localStorage.setItem("justLoggedIn", "true");

      setLoginData({
        ...loginData,
        generalPermissions: loginData.permissions,
        sitePermissions: [],
      });
    },
    {
      onSuccess: () => {
        if (token) {
          navigate(`/activate-user?token=${token}&company=${company}`);
        } else {
          navigate(location?.state?.requestedPath ?? "/overview");
        }
      },
      onError: (error: Error) => setError(error.message),
    },
  );

  const requestPasswordResetMutation = useMutation(
    async (email: string) => {
      await vm.requestPasswordReset(email);
    },
    {
      onSuccess: () => setSuccessRequestPasswordReset(true),
      onError: (error: Error) => setError(error.message),
    },
  );

  const createBusinessAccount = methods.handleSubmit((data) => {
    const { firstName, lastName, ...company } = data;
    createBusinessAccountMutation.mutate(company);
  }, onValidationError);

  const createPersonalAccount = methods.handleSubmit((data) => {
    const {
      vat,
      vatCountry,
      businessName,
      businessSize,
      contact,
      doctor,
      rls,
      rspp,
      employer,
      ...user
    } = data;
    createPersonalAccountMutation.mutate(user);
  }, onValidationError);

  const isUserEmailAvailable = async (email: string) =>
    !(await vm.userEmailAvailable(email));

  const getBusinessTypes = useQuery<string[], Error>(
    ["get-business-types"],
    async () => {
      const types = await vm.getBusinessTypes();
      methods.setValue("businessType", types[0]);
      return types;
    },
  );

  return {
    methods,
    error,
    setError,
    activationError,
    setActivationError,
    successCreateAccount,
    createPersonalAccount,
    createBusinessAccount,
    login: loginMutation.mutate,
    requestPasswordReset: requestPasswordResetMutation.mutate,
    successRequestPasswordReset,
    setSuccessRequestPasswordReset,
    isLoading:
      createBusinessAccountMutation.isLoading ||
      createPersonalAccountMutation.isLoading ||
      loginMutation.isLoading,
    isUserEmailAvailable,
    businessTypes: getBusinessTypes.data,
    userAlreadyRegistered: userAlreadyRegistered?.data,
    userAlreadyRegisteredLoading: userAlreadyRegistered?.isLoading,
  };
};

export default useOnboardingViewModel;
