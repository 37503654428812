import {
	makeAddSpecializationToRequirement,
	makeAddTemplateToSiteRequirement,
	makeAddVariantToRequirement,
	makeCreateRequirementSpecialization,
	makeCreateRequirementVariant,
	makeDeleteTemplateFromSiteRequirement,
	makeGetRequirementsByVariant,
	makeGetRequirementsGroupByVariant,
	makeRemoveSpecializationFromRequirement,
	makeRemoveVariantFromRequirement,
	makeUpdateRequirement,
	makeUpdateRequirementsByVariantOrder,
	makeUpdateRequirementsGroupByVariantOrder,
	makeUpdateRequirementsOrder,
	makeUpdateSpecializationFromRequirement,
	makeUpdateVariantFromRequirement,
} from '../../../core/app/factory/requirements/useCases';
import DocumentType from '../../../domain/entities/documentType';
import { DocumentTypeResourceType } from '../../../mock/models/document-type';
import Requirement from '../../../domain/entities/requirement';
import Variant from '../../../domain/entities/variant';
import { makeGetSiteVariants, makeGetVariants } from '../../../core/app/factory/variants/usecases';
import Specialization from '../../../domain/entities/specialization';
import { makeGetSpecializations } from '../../../core/app/factory/specializations/usecases';
import { RequirementSubject } from '../../../domain/entities/requirementSubject.enum';
import { AddDocumentTypeViewModel } from '../../hooks/Document/useAddDocumentType';
import Tag from '../../../domain/entities/tag';
import { SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import { makeGetTags } from '../../../core/app/factory/tags/usecases';
import { DocumentTypeAdd, GetSitesFilter } from '../../../domain/repositories/siteRepository';
import { makeGetPropagableSites } from '../../../core/app/factory/requirementsGroup/useCases';
import Site from '../../../domain/entities/site';
import FileEntity from '../../../domain/entities/file';
import { DocumentTypeCategory } from '../../../domain/entities/documentTypeCategory.enum';

abstract class RequirementsViewModel implements AddDocumentTypeViewModel {
	async updateRequirement(companyId: string, requirement: Requirement, requirementGroupId?: string, siteIds?: string[], selectAllSites?: boolean, filters?: GetSitesFilter): Promise<void> {
		return await makeUpdateRequirement().Call({ companyId, requirement, requirementGroupId, siteIds, selectAllSites, filters });
	}

	async getRequirementsByVariant(
		companyId: string,
		siteId: string,
		variantId: string,
		requirementSubject: RequirementSubject
	): Promise<Requirement[]> {
		return await makeGetRequirementsByVariant().Call({ companyId, siteId, variantId, requirementSubject });
	}

	async getRequirementsGroupByVariant(
		companyId: string,
		variantId: string,
		requirementGroupId: string,
		requirementSubject: RequirementSubject
	): Promise<Requirement[]> {
		return await makeGetRequirementsGroupByVariant().Call({ companyId, variantId, requirementGroupId, requirementSubject });
	}

	async updateRequirementsOrder(companyId: string, requirements: Requirement[], requirementGroupId?: string): Promise<void> {
		return await makeUpdateRequirementsOrder().Call({ companyId, requirements, requirementGroupId });
	}

	async updateRequirementsByVariantOrder(companyId: string, siteId: string, variantId: string, requirements: Requirement[]): Promise<void> {
		return await makeUpdateRequirementsByVariantOrder().Call({ companyId, siteId, variantId, requirements });
	}

	async updateRequirementsGroupByVariantOrder(
		companyId: string,
		variantId: string,
		requirementGroupId: string,
		requirements: Requirement[]
	): Promise<void> {
		return await makeUpdateRequirementsGroupByVariantOrder().Call({ companyId, variantId, requirementGroupId, requirements });
	}

	async listSpecializations(companyId: string, type: DocumentTypeResourceType, search?: string): Promise<Array<Specialization>> {
		if (type === DocumentTypeResourceType.COMPANY || type === DocumentTypeResourceType.SITE) {
			return [];
		}
		return await makeGetSpecializations().Call({ companyId, type, search });
	}

	async getPropagableSites(
		companyId: string,
		requirementGroupId: string,
		filter?: GetSitesFilter,
		sort?: SortMeta,
		pageParam?: number,
	): Promise<{results: Site[], count: number}> {
		return await makeGetPropagableSites().Call({ companyId, requirementGroupId, filter, sort, pageParam });
	}

	async listVariants(companyId: string, type: DocumentTypeResourceType, search?: string): Promise<Array<Variant>> {
		return await makeGetVariants().Call({ companyId, type, search });
	}

	async listSiteVariants(companyId: string, siteId: string, type: DocumentTypeResourceType, search?: string): Promise<Array<Variant>> {
		return await makeGetSiteVariants().Call({ companyId, siteId, type, search });
	}

	async createRequirementVariant(companyId: string, variant: Variant, requirementType: RequirementSubject): Promise<Variant> {
		return await makeCreateRequirementVariant().Call({ companyId, variant, requirementType });
	}

	async createRequirementSpecialization(
		companyId: string,
		specialization: Specialization,
		requirementType: RequirementSubject
	): Promise<Specialization> {
		return await makeCreateRequirementSpecialization().Call({ companyId, specialization, requirementType });
	}

	async addVariantToRequirement(
		companyId: string,
		requirementId: string,
		variant: Tag,
		reqGroupId: string,
		resourceType: DocumentTypeResourceType
	): Promise<void> {
		return await makeAddVariantToRequirement().Call({ companyId, requirementId, variant, requirementGroupId: reqGroupId, resourceType });
	}

	async removeVariantFromRequirement(companyId: string, requirementId: string, variantId: string, requirementGroupId: string): Promise<void> {
		return await makeRemoveVariantFromRequirement().Call({ companyId, requirementId, variantId, requirementGroupId });
	}
	async updateVariantFromRequirement(companyId: string, variant: Tag): Promise<void> {
		return await makeUpdateVariantFromRequirement().Call({ companyId, variant });
	}
	async updateSpecializationFromRequirement(companyId: string, variant: Tag): Promise<void> {
		return await makeUpdateSpecializationFromRequirement().Call({ companyId, variant });
	}
	async addSpecializationToRequirement(
		companyId: string,
		requirementId: string,
		specialization: Tag,
		reqGroupId: string,
		resourceType: DocumentTypeResourceType
	): Promise<void> {
		return await makeAddSpecializationToRequirement().Call({
			companyId,
			requirementId,
			specialization,
			requirementGroupId: reqGroupId,
			resourceType,
		});
	}

	async removeSpecializationFromRequirement(
		companyId: string,
		requirementId: string,
		specializationId: string,
		requirementGroupId: string
	): Promise<void> {
		return await makeRemoveSpecializationFromRequirement().Call({ companyId, requirementId, specializationId, requirementGroupId });
	}

	async getTags(companyId: string, search?: string): Promise<Tag[]> {
		return await makeGetTags().Call({ companyId, search });
	}
	async addTemplateToSiteRequirement(companyId: string, siteId: string, requirementId: string, file: FileEntity): Promise<void> {
		return await makeAddTemplateToSiteRequirement().Call({ companyId, siteId, requirementId, file });
	}
	async deleteTemplateFromSiteRequirement(companyId: string, siteId: string, requirementId: string, templateId: string): Promise<void> {
		return await makeDeleteTemplateFromSiteRequirement().Call({ companyId, siteId, requirementId, templateId });
	}

	abstract getDocumentTypes(companyId: string, search?: string, tags?: string[], sort?: SortMeta): Promise<DocumentType[]>;

	abstract getDocumentTypesForAi(companyId: string, aiTaskId: string, resourceType: DocumentTypeCategory): Promise<DocumentType[]>

	abstract getGroupRequirementsByResource(
		companyId: string,
		requirementGroupId: string,
		requirementSubject: RequirementSubject,
		supplierRequirementSource?: RequirementSubject
	): Promise<Requirement[]>;

	abstract addRequirementToGroup(
		companyId: string,
		requirementGroupId: string,
		documents: DocumentTypeAdd[],
		requirementSubject: RequirementSubject,
		siteIds?: string[],
		selectAllSites?: boolean,
		filters?: GetSitesFilter
	): Promise<Requirement>;

	abstract addRequirementToSite(
		companyId: string,
		siteId: string,
		documents: DocumentTypeAdd[],
		requirementSubject: RequirementSubject,
	): Promise<Requirement>;

	abstract removeRequirementFromGroup(companyId: string, requirementGroupId: string, requirementId: string, siteIds?: string[], selectAllSites?: boolean, filters?: GetSitesFilter): Promise<void>;

	abstract removeRequirementFromSite(companyId: string, siteId: string, requirementId: string): Promise<void>;
}

export { RequirementsViewModel };
