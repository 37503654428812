import Staff from "../../../../domain/entities/staff";
import { Box, IconButton, Tooltip } from "@chakra-ui/react";
import { CopyIcon, EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import { IconProps } from '@chakra-ui/react';
import { FC, useState } from "react";

interface ContactFieldProps {
  staff?: Staff;
}

const ContactField = ({ staff }: ContactFieldProps) => (
  <Box fontWeight={600} mt={1}>
    {staff ? (
      <>
        {staff.name} {staff.surname}
        {staff.email && staff.email !== "-" && (
          <ContactIcon contact={staff.email} Icon={EmailIcon} />
        )}
        {staff.phone && staff.phone !== "-" && (
          <ContactIcon contact={staff.phone} Icon={PhoneIcon} />
        )}
      </>
    ) : (
      "-"
    )}
  </Box>
);

const ContactIcon = ({
  contact,
  Icon,
}: {
  contact: string;
  Icon: FC<IconProps>;
}) => {
  const [focus, setFocus] = useState(false);
  const IconComponent = focus ? CopyIcon : Icon;
  return (
    <Tooltip label={contact} hasArrow>
      <IconButton
        onMouseEnter={() => setFocus(true)}
        onMouseLeave={() => setFocus(false)}
        onClick={() => {
          navigator.clipboard.writeText(contact);
          setFocus(false);
        }}
        aria-label={contact}
        icon={<IconComponent fontSize="14px" />}
        variant="unstyled"
        sx={{ minWidth: 0, height: "auto", padding: "0 10px" }}
      />
    </Tooltip>
  );
};

export default ContactField;
