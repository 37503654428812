import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  useSteps,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDateForInputHTML } from "../../../utils";
import useSitesListViewModel from "../../hooks/Site/useSitesListViewModel";
import { useWorkingSitesViewModel } from "../../hooks/WorkingSite/useWorkingSites";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { Permission } from "../Permissions/Permissions";
import SelectSitesView from "../Views/common/SelectSitesView";
import {
  ExportItem,
  exportItems,
  ExportSection,
  exportSections,
} from "./ExportDialogData";
import InputAnimatedTimePicker from "./InputAnimatedTimePicker";
import Steps from "./stepper/Steps";
import InputField from "./table/InputField";

interface ExportDialogProps {
  onClose: () => void;
  onExport: (
    topic: string,
    id?: string,
    ids?: string[],
    startDate?: Date,
    endDate?: Date,
    permissionSites?: { [key: string]: string }
  ) => void;
}

const ExportDialog = ({ onClose, onExport }: ExportDialogProps) => {
  const { t } = useTranslation("common");
  const { sites: workingSites } = useWorkingSitesViewModel();
  const { sites } = useSitesListViewModel(true);
  const [selectedSection, setSelectedSection] = useState<string>();
  const [selectedExportItem, setSelectedExportItem] = useState<ExportItem>();
  const { hasPermissions } = useAuth();

  // export badges stamps states

  const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>([]);

  const [actionStartDate, setActionStartDate] = useState<Date>(undefined);
  const [actionStartTime, setActionStartTime] = useState<Date>(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [actionStartDateError, setActionStartDateError] = useState<
    string | null
  >(null);
  const [actionStartTimeError, setActionStartTimeError] = useState<
    string | null
  >(null);

  const [actionEndDate, setActionEndDate] = useState<Date>(undefined);
  const [actionEndTime, setActionEndTime] = useState<Date>(
    new Date(new Date().setHours(23, 59, 0, 0))
  );
  const [actionEndDateError, setActionEndDateError] = useState<string | null>(
    null
  );
  const [actionEndTimeError, setActionEndTimeError] = useState<string | null>(
    null
  );

  const [permissionSites, setPermissionsSites] = useState<{
    [key: string]: string;
  }>({});

  const [selectedFilters, setSelectedFilters] = useState<{
    siteId: string;
    siteIds: string[];
    dateRange: { startDate: Date; endDate: Date };
  }>({
    siteId: "",
    siteIds: [],
    dateRange: { startDate: new Date(), endDate: new Date() },
  });

  const steps = [
    {
      title: t("selectSection"),
    },
    {
      title: t("selectFile"),
    },
    {
      title: t("selectParameters"),
    },
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const onSelectStep = (index: number) => {
    setActiveStep(index);
  };

  const handleExport = () => {
    onExport(
      selectedExportItem.topic,
      selectedFilters.siteId,
      selectedSiteIds,
      new Date(
        actionStartDate?.getFullYear(),
        actionStartDate?.getMonth(),
        actionStartDate?.getDate(),
        actionStartTime?.getHours(),
        actionStartTime?.getMinutes()
      ),
      new Date(
        actionEndDate?.getFullYear(),
        actionEndDate?.getMonth(),
        actionEndDate?.getDate(),
        actionEndTime?.getHours(),
        actionEndTime?.getMinutes()
      ),
      permissionSites
    );
  };

  const handleNext = () => {
    return activeStep === 2 ? handleExport() : onSelectStep(activeStep + 1);
  };

  const handleSectionChange = (value: string) => {
    setSelectedSection(value);
  };

  const handleSelectExportItem = (val: any) => {
    const exportItem = JSON.parse(val);
    setSelectedExportItem(exportItem);
  };

  const handleFilters = (e) => {
    const newSiteId = e.target.value;

    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      siteId: newSiteId,
    }));
  };

  const handleStartDateChange = (fieldName, value) => {
    setActionStartDateError(null);
    setActionStartDate(value ? new Date(value) : undefined);
  };

  const handleStartTimeChange = (fieldName, value) => {
    setActionStartTimeError(null);
    const [hours, minutes] = value.split(":");
    const today = new Date();
    setActionStartTime(
      new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        parseInt(hours),
        parseInt(minutes)
      )
    );
  };

  const handleEndDateChange = (fieldName, value) => {
    setActionEndDateError(null);
    setActionEndDate(new Date(value));
  };

  const handleEndTimeChange = (fieldName, value) => {
    setActionEndTimeError(null);
    const [hours, minutes] = value.split(":");
    const today = new Date();
    setActionEndTime(
      new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        parseInt(hours),
        parseInt(minutes)
      )
    );
  };

  const handleFilterType = (type: string) => {
    switch (type) {
      case "siteId":
        return (
          <Box my={4}>
            <Select onChange={handleFilters} value={selectedFilters.siteId}>
              <option value={""}>{t("selectSite")}</option>
              {sites &&
                Array.isArray(sites) &&
                sites.map((item) => (
                  <option key={item?.id} value={item?.id}>
                    {item?.name}
                  </option>
                ))}
            </Select>
          </Box>
        );

      case "workingSiteId":
        return (
          <Box my={4}>
            <Select onChange={handleFilters} value={selectedFilters.siteId}>
              <option value={""}>{t("selectSite", { ns: "common" })}</option>
              {workingSites &&
                Array.isArray(workingSites) &&
                workingSites.map((item) => (
                  <option key={item?.site.id} value={item?.site.id}>
                    {item?.site.name}
                  </option>
                ))}
            </Select>
          </Box>
        );

      case "badgesParameters":
      case "badgesDeniedParameters":
        return (
          <Box>
            <SelectSitesView
              autosize
              title={t(type === "badgesParameters" ? "selectSites" : "selectSitesForDenied", { ns: "common" })}
              reminderText={t("noSiteSelected", { ns: "common" })}
              alertText={t("siteSelectionAlert", { ns: "common" })}
              siteSelectedAction={setSelectedSiteIds}
              siteList={sites}
              includeFooterButton={false}
              showSelectAll={false}
              showPermissionsSelect={type === "badgesParameters" ? true : false}
              setPermissionsSites={setPermissionsSites}
              hideSubtitles={true}
            />

            <Text marginLeft={2} marginTop={2} fontSize={16}>
              {t(type === "badgesParameters" ? "selectStampsDate" : "selectStampsDeniedDate", { ns: "common" })}
            </Text>

            <Flex flexDirection={"row"} marginTop={5}>
              <Box width={"30%"}>
                <InputField
                  field={"actionStartDate"}
                  type={"date"}
                  label={t("stampingStartDate")}
                  value={formatDateForInputHTML(actionStartDate) ?? ""}
                  onChange={handleStartDateChange}
                />
                <Text marginLeft={2} marginTop={2} fontSize={14} color={"red"}>
                  {actionStartDateError}
                </Text>
              </Box>

              <Box width={"30%"} marginLeft={10}>
                <InputAnimatedTimePicker
                  onChange={handleStartTimeChange}
                  id={"actionStartTime"}
                  label={t("stampingStartTime")}
                  defaultValue={
                    `${actionStartTime
                      ?.getHours()
                      ?.toString()
                      ?.padStart(2, "0")}:${actionStartTime
                      ?.getMinutes()
                      ?.toString()
                      ?.padStart(2, "0")}` ?? ""
                  }
                />
                <Text marginLeft={2} marginTop={2} fontSize={14} color={"red"}>
                  {actionStartTimeError}
                </Text>
              </Box>
            </Flex>

            <Flex flexDirection={"row"}>
              <Box width={"30%"}>
                <InputField
                  field={"actionEndDate"}
                  type={"date"}
                  label={t("stampingEndDate")}
                  value={formatDateForInputHTML(actionEndDate) ?? ""}
                  onChange={handleEndDateChange}
                />
                <Text marginLeft={2} marginTop={2} fontSize={14} color={"red"}>
                  {actionEndDateError}
                </Text>
              </Box>

              <Box width={"30%"} marginLeft={10}>
                <InputAnimatedTimePicker
                  onChange={handleEndTimeChange}
                  id={"actionEndTime"}
                  label={t("stampingEndTime")}
                  defaultValue={
                    `${actionEndTime
                      ?.getHours()
                      ?.toString()
                      ?.padStart(2, "0")}:${actionEndTime
                      ?.getMinutes()
                      ?.toString()
                      ?.padStart(2, "0")}` ?? ""
                  }
                />
                <Text marginLeft={2} marginTop={2} fontSize={14} color={"red"}>
                  {actionEndTimeError}
                </Text>
              </Box>
            </Flex>
          </Box>
        );

      default:
        <p>{t("noParametersRequired", { ns: "common" })}</p>;
    }
  };

  const renderSection = (section: ExportSection) => {
    const hasPermissionForSection = (section: ExportSection) => {
      switch (section.title) {
        case "mySites":
          return hasPermissions([Permission.Sites_Manage]);
        case "myWorkingSites":
          return hasPermissions([Permission.Worksite_Show]);
        default:
          return true;
      }
    };

    if (!hasPermissionForSection(section)) {
      return null;
    }

    return (
      <Flex flexDirection={"column"}>
        <Radio key={section.title} value={section.title}>
          <Text marginLeft={2}>{t("exportSections." + section.title)}</Text>
        </Radio>
        <Text
          marginLeft={8}
          fontStyle={"italic"}
          fontSize={"0,85rem"}
          color={"gray.500"}
        >
          {t(section.description, { ns: "export" })}
        </Text>
      </Flex>
    );
  };

  return (
    <Modal isOpen={true} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent
        style={{ background: "white", padding: "2rem" }}
        overflowY={"scroll"}
      >
        <Box padding={4} border="1px" borderColor="gray.200" borderRadius={6}>
          <Steps selected={activeStep} steps={steps} onSelect={onSelectStep} />
        </Box>
        <Text fontSize="xl" fontWeight={500}>
          {t(`titleStep${activeStep + 1}`)}
        </Text>
        <Text fontSize="sm" mb={5}>
          {t(`descriptionStep${activeStep + 1}`)}
        </Text>
        <Box py={4}>
          {activeStep === 0 && (
            <RadioGroup px={4} onChange={handleSectionChange}>
              <Stack spacing={4} direction="column">
                {exportSections.map((section: ExportSection) => {
                  return renderSection(section);
                })}
              </Stack>
            </RadioGroup>
          )}
          {activeStep === 1 && (
            <>
              <RadioGroup
                px={4}
                onChange={(value) => handleSelectExportItem(value)}
              >
                <Stack spacing={4} direction="column">
                  {exportItems[selectedSection]?.map((x) => (
                    <Flex flexDirection={"column"} key={x.title}>
                      <Radio value={JSON.stringify(x)}>
                        <Text marginLeft={2}>
                          {t(x.title, { ns: "export" })}
                        </Text>
                      </Radio>
                      <Text
                        marginLeft={8}
                        fontStyle={"italic"}
                        fontSize={"0,85rem"}
                        color={"gray.500"}
                      >
                        {t(x.description, { ns: "export" })}
                      </Text>
                    </Flex>
                  ))}
                </Stack>
              </RadioGroup>
            </>
          )}
          {activeStep === 2 && (
            <>
              {!selectedExportItem?.hasParams ? (
                <Text>{t("noParametersRequired", { ns: "common" })}</Text>
              ) : (
                selectedExportItem.params?.map((p) => (
                  <>{handleFilterType(p.type)}</>
                ))
              )}
            </>
          )}
        </Box>
        <Flex justifyContent="flex-end" gap={2} mt={5}>
          <Button
            colorScheme="blue"
            onClick={handleNext}
            isDisabled={
              (activeStep === 0 && !selectedSection) ||
              (activeStep === 1 && !selectedExportItem) ||
              (activeStep === 2 &&
                (!selectedExportItem ||
                  (selectedExportItem?.hasParams &&
                    selectedExportItem?.topic !== "badges-records" &&
                    selectedExportItem?.topic !== "badges-records-denied" &&
                    !selectedFilters.siteId) ||
                  (selectedExportItem?.hasParams &&
                    (selectedExportItem?.topic === "badges-records" || selectedExportItem?.topic === "badges-records-denied") &&
                    (selectedSiteIds?.length === 0 ||
                      !actionStartDate ||
                      !actionEndDate)) ||
                  (selectedExportItem?.topic !== "badges-records-denied" && selectedSiteIds?.length !==
                    Object.keys(permissionSites).length)))
            }
          >
            {activeStep === 2 ? t("export") : t("confirm")}
          </Button>
          <Button colorScheme="red" onClick={onClose}>
            {t("cancel")}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default ExportDialog;
