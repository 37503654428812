import {
  PaginatedResults,
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import Audit, { AuditType } from "../../../domain/entities/audit";
import {
  makeCreateAudit,
  makeCreateAuditNote,
  makeDeleteAudit,
  makeGetAuditNotes,
  makeGetAudits,
  makeGetSelectableSuppliers,
} from "../../../core/app/factory/audit/usecases";
import { GetAuditFilter } from "../../../domain/repositories/auditRepository";
import DocumentComment from "../../../domain/entities/documentComment";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { SiteResourceDocument } from "../../../domain/entities/document";
import {
  makeGetSiteSupplierChemicals,
  makeGetSiteSupplierDocuments,
  makeGetSiteSupplierMachines,
  makeGetSiteSupplierResourcesDocuments,
  makeGetSiteSupplierTools,
  makeGetSiteSupplierVehicles,
  makeGetSiteSupplierWorkers,
} from "../../../core/app/factory/sites/usecases";
import Supplier from "../../../domain/entities/supplier";
import DocumentType from "../../../domain/entities/documentType";
import { GetSiteResourcesFilters } from "../../hooks/Site/useSiteResourcesViewModel";
import { SiteWorker } from "../../../domain/entities/siteWorker";
import { SiteMachine } from "../../../domain/entities/siteMachine";
import { SiteVehicle } from "../../../domain/entities/siteVehicle";
import { SiteTool } from "../../../domain/entities/siteTool";
import { SiteChemical } from "../../../domain/entities/siteChemical";
import { GetSiteResourcesAuditFilters } from "../../hooks/Site/useSiteAuditsViewModel";

class AuditsViewModel {
  private getAuditsUseCase = makeGetAudits();
  private createAuditUseCase = makeCreateAudit();
  private deleteAuditUseCase = makeDeleteAudit();
  private getAuditNotesUseCase = makeGetAuditNotes();
  private createAuditNoteUseCase = makeCreateAuditNote();
  private getSelectableSuppliers = makeGetSelectableSuppliers();

  async getAudits(
    companyId: string,
    siteId: string,
    page: number,
    filter?: GetAuditFilter,
    sort?: SortMeta,
  ): Promise<PaginatedResults<Audit>> {
    return await this.getAuditsUseCase.Call({
      companyId,
      siteId,
      page,
      perPage: 25,
      filter,
      sort,
    });
  }

  async createAudit(
    companyId: string,
    siteId: string,
    audit: AuditType,
    supplierId: string,
    siteRequirementsToExclude?: string[],
    companyRequirementsToExclude?: string[],
    workersRequirementsToExclude?: string[],
    machinesRequirementsToExclude?: string[],
    vehiclesRequirementsToExclude?: string[],
    toolsRequirementsToExclude?: string[],
    chemicalsRequirementsToExclude?: string[],
    workerResourceIds?: string[],
    machineResourceIds?: string[],
    vehicleResourceIds?: string[],
    toolResourceIds?: string[],
    chemicalResourceIds?: string[],
  ): Promise<boolean> {
    return await this.createAuditUseCase.Call({
      companyId,
      siteId,
      audit,
      supplierId,
      siteRequirementsToExclude,
      companyRequirementsToExclude,
      workersRequirementsToExclude,
      machinesRequirementsToExclude,
      vehiclesRequirementsToExclude,
      toolsRequirementsToExclude,
      chemicalsRequirementsToExclude,
      workerResourceIds,
      machineResourceIds,
      vehicleResourceIds,
      toolResourceIds,
      chemicalResourceIds,
    });
  }

  async deleteAudit(
    companyId: string,
    siteId: string,
    auditId: string,
  ): Promise<void> {
    return await this.deleteAuditUseCase.Call({ companyId, siteId, auditId });
  }

  async getSiteSupplierDocuments(
    companyId: string,
    siteId: string,
    resourceType: DocumentTypeCategory,
    supplierId: string,
    pageParam?: number,
  ): Promise<PaginatedResults<SiteResourceDocument>> {
    return await makeGetSiteSupplierDocuments().Call({
      companyId,
      siteId,
      resourceType,
      supplierId,
      pageParam,
    });
  }

  async getSiteResourceDocuments(
    companyId: string,
    siteId: string,
    resourceType: DocumentTypeCategory,
    supplierId: string,
    pageParam?: number,
  ): Promise<PaginatedResults<DocumentType>> {
    return await makeGetSiteSupplierResourcesDocuments().Call({
      companyId,
      siteId,
      resourceType,
      supplierId,
      pageParam,
    });
  }

  async getSiteWorkers(
    companyId: string,
    siteId: string,
    pageParam: number,
    filterSiteResources: GetSiteResourcesAuditFilters,
    siteResourcesSort: SortMeta,
    supplierId: string,
  ): Promise<PaginatedResults<SiteWorker>> {
    return await makeGetSiteSupplierWorkers().Call({
      companyId,
      siteId,
      pageParam,
      filterSiteResources,
      siteResourcesSort,
      supplierId,
    });
  }

  async getSiteMachines(
    companyId: string,
    siteId: string,
    pageParam: number,
    filterSiteResources: GetSiteResourcesAuditFilters,
    siteResourcesSort: SortMeta,
    supplierId: string,
  ): Promise<PaginatedResults<SiteMachine>> {
    return await makeGetSiteSupplierMachines().Call({
      companyId,
      siteId,
      pageParam,
      filterSiteResources,
      siteResourcesSort,
      supplierId,
    });
  }

  async getSiteVehicles(
    companyId: string,
    siteId: string,
    pageParam: number,
    filterSiteResources: GetSiteResourcesAuditFilters,
    siteResourcesSort: SortMeta,
    supplierId: string,
  ): Promise<PaginatedResults<SiteVehicle>> {
    return await makeGetSiteSupplierVehicles().Call({
      companyId,
      siteId,
      pageParam,
      filterSiteResources,
      siteResourcesSort,
      supplierId,
    });
  }

  async getSiteTools(
    companyId: string,
    siteId: string,
    pageParam: number,
    filterSiteResources: GetSiteResourcesAuditFilters,
    siteResourcesSort: SortMeta,
    supplierId: string,
  ): Promise<PaginatedResults<SiteTool>> {
    return await makeGetSiteSupplierTools().Call({
      companyId,
      siteId,
      pageParam,
      filterSiteResources,
      siteResourcesSort,
      supplierId,
    });
  }

  async getSiteChemicals(
    companyId: string,
    siteId: string,
    pageParam: number,
    filterSiteResources: GetSiteResourcesAuditFilters,
    siteResourcesSort: SortMeta,
    supplierId: string,
  ): Promise<PaginatedResults<SiteChemical>> {
    return await makeGetSiteSupplierChemicals().Call({
      companyId,
      siteId,
      pageParam,
      filterSiteResources,
      siteResourcesSort,
      supplierId,
    });
  }

  async getAuditNote(
    companyId: string,
    siteId: string,
    requirementId: string,
    requirementSubject: DocumentTypeCategory,
    resourceId: string,
  ): Promise<DocumentComment> {
    return await this.getAuditNotesUseCase.Call({
      companyId,
      siteId,
      requirementId,
      requirementSubject,
      resourceId,
    });
  }

  async createAuditNote(
    companyId: string,
    siteId: string,
    requirementId: string,
    note: string,
    requirementSubject: DocumentTypeCategory,
    resourceId: string,
  ): Promise<void> {
    return await this.createAuditNoteUseCase.Call({
      companyId,
      siteId,
      requirementId,
      note,
      requirementSubject,
      resourceId,
    });
  }

  async updateAuditNote(
    companyId: string,
    siteId: string,
    requirementId: string,
    note: string,
    requirementSubject: DocumentTypeCategory,
    resourceId: string,
  ): Promise<void> {
    return await this.createAuditNoteUseCase.Call({
      companyId,
      siteId,
      requirementId,
      note,
      requirementSubject,
      resourceId,
    });
  }

  async listActiveSupplier(
    companyId: string,
    siteId: string,
    active?: boolean
  ): Promise<Supplier[]> {
    return await this.getSelectableSuppliers.Call({ companyId, siteId, active });
  }
}

export { AuditsViewModel };
