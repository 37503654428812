import React, { ChangeEvent, useState } from "react";
import CreateSiteView from "./CreateSiteView";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../assets/theme/colors";
import ContentLayout from "../../layout/ContentLayout";
import useSitesListViewModel from "../../hooks/Site/useSitesListViewModel";
import {
  Flex,
  Select,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import SiteListActionBar from "../../components/Views/Sites/SitesListActionBar";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import SkeletonTableRow from "../../components/Common/table/SkeletonTableRow";
import ColumnFilterComponent from "../../components/Common/table/ColumnFilterComponent";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import { SiteState } from "../../../domain/entities/site";
import SearchInput from "../Common/SearchInput";
import StateTag from "../../components/Common/StateTag";

const SiteListView = () => {
  // Local state.
  const [showCreate, setShowCreate] = useState<boolean>(false);

  // Data, localization support.
  const navigate = useNavigate();
  const { t } = useTranslation("sites");
  const {
    sites,
    isLoading,
    createSite,
    setSiteCreationActive,
    typologies,
    requirementsGroups,
    getSitesHasNextPage,
    getSitesFetchNextPage,
    createSiteIsLoading,
    filterSites,
    updateFilterSites,
    sort,
    setSort,
    siteVariants,
    setCreationRequirementGroup,
    setSearch,
  } = useSitesListViewModel();

  const colorHandler = (state: string) => {
    switch (state) {
      case "active":
        return COLORS.sikuroBlue;
      case "suspended":
        return "yellow.400";
      case "closed":
        return "red";
      default:
        return "";
    }
  };

  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const columns = [
    {
      field: "name",
      type: "text",
      label: t("name"),
      width: isMobile ? "150px" : "30%",
    },
    {
      field: "protocol",
      type: "text",
      label: t("protocol"),
      width: isMobile ? "150px" : "20%",
    },
    {
      field: "location",
      type: "text",
      label: t("location"),
      width: isMobile ? "300px" : "30%",
    },
    {
      field: "state",
      type: "text",
      label: t("state"),
      width: isMobile ? "100px" : "20%",
    },
  ] as { field: string; type: "text" | "tags"; label: string; width: string }[];

  const excludeSort = new Set(["location"]);
  const fSites = filterSites ?? {
    name: "",
    protocol: "",
    state: "active",
    location: "",
  };

  const updateFilterState = (e: ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.value) {
      case SiteState.ACTIVE:
        updateFilterSites("state", SiteState.ACTIVE);
        break;
      case SiteState.CLOSED:
        updateFilterSites("state", SiteState.CLOSED);
        break;
      case SiteState.SUSPENDED:
        updateFilterSites("state", SiteState.SUSPENDED);
        break;
      default:
        updateFilterSites("state", undefined);
        break;
    }
  };

  return (
    <>
      <ContentLayout
        action={
          <SiteListActionBar
            onAdd={() => {
              setSiteCreationActive(true);
              setShowCreate(true);
            }}
          />
        }
      >
        <Flex
          flex={1}
          h="100%"
          w="100%"
          bg="white"
          alignItems="center"
          justifyContent="start"
          gap={4}
          textAlign="center"
          flexDirection="column"
          paddingLeft={10}
          paddingRight={3}
          paddingTop={10}
          paddingBottom={10}
        >
          <Flex justifyContent={"end"} alignItems="center" w={"100%"}>
            <SearchInput onSearch={setSearch} />
          </Flex>
          <Flex
            flexDirection={"column"}
            alignItems={"start"}
            width="100%"
            position="relative"
            overflow={"hidden"}
          >
            <InfiniteTable
              tableId={"siteList"}
              autosize
              infiniteScroll={{
                dataLength: sites?.length,
                hasNextPage: getSitesHasNextPage,
                fetchNextPage: getSitesFetchNextPage,
              }}
              isLoading={isLoading}
              emptyText={t("noSiteFound", { ns: "sites" })}
              showEmptyText={sites?.length === 0}
            >
              <Thead>
                <Tr bg={COLORS.table.headerBg}>
                  {columns.map((column) => (
                    <Th width={column.width} key={column.field}>
                      <TableColumnHeader
                        text={column.label}
                        filter={{
                          isActive:
                            !!fSites[column.field] &&
                            (!Array.isArray(fSites[column.field]) ||
                              !!fSites[column.field][0]),
                          component: (
                            <>
                              {column.field !== "state" ? (
                                <ColumnFilterComponent
                                  type={column.type}
                                  value={fSites[column.field]}
                                  updateFilter={(value) =>
                                    updateFilterSites(
                                      column.field,
                                      value as string | string[],
                                    )
                                  }
                                />
                              ) : (
                                <Select
                                  value={fSites?.state}
                                  onChange={updateFilterState}
                                >
                                  <option value={undefined}>
                                    {t("status.site.allStatus", {
                                      ns: "common",
                                    })}
                                  </option>
                                  <option value={SiteState.ACTIVE}>
                                    {t("status.site.active", { ns: "common" })}
                                  </option>
                                  <option value={SiteState.CLOSED}>
                                    {t("status.site.closed", { ns: "common" })}
                                  </option>
                                  <option value={SiteState.SUSPENDED}>
                                    {t("status.site.suspended", {
                                      ns: "common",
                                    })}
                                  </option>
                                </Select>
                              )}
                            </>
                          ),
                        }}
                        sort={
                          excludeSort.has(column.field)
                            ? undefined
                            : {
                              handler: (direction) =>
                                setSort({ field: column.field, direction }),
                              direction:
                                sort?.field === column.field
                                  ? sort.direction
                                  : null,
                            }
                        }
                      />
                    </Th>
                  ))}
                </Tr>
              </Thead>
              {sites?.length > 0 && (
                <Tbody
                  className={sites?.length > 0 ? "real-row" : "skeleton-row"}
                >
                  {sites?.length > 0 ? (
                    sites.map((s) => (
                      <Tr
                        key={s?.id}
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(`/sites/${s?.id}/details`)}
                      >
                        <Td width={columns[0].width}>{s?.name}</Td>
                        <Td width={columns[1].width}>{s?.protocol}</Td>
                        <Td width={columns[2].width}>
                          {s?.address && <span>{s?.address}</span>}
                          {s?.city && <span>, {s?.city}</span>}
                          {s?.zipCode && <span>, {s?.zipCode}</span>}
                        </Td>
                        <Td width={columns[3].width}>
                          <StateTag value={s?.state} type="siteStatus" />
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <SkeletonTableRow
                      columnNumber={4}
                      rowNumber={3}
                      hasAction={false}
                    />
                  )}
                </Tbody>
              )}
            </InfiniteTable>
          </Flex>
        </Flex>
      </ContentLayout>

      {showCreate && (
        <CreateSiteView
          onCancel={() => {
            setSiteCreationActive(false);
            setShowCreate(false);
            setCreationRequirementGroup(undefined);
          }}
          onConfirm={async (
            site,
            requirementsGroup,
            typology,
            siteVariant,
          ) =>
            await createSite({
              site,
              requirementsGroup,
              typology,
              siteVariant,
            })
          }
          createSiteIsLoading={createSiteIsLoading}
          typologies={typologies}
          requirementsGroups={requirementsGroups}
          siteVariants={siteVariants}
          setCreationRequirementGroup={setCreationRequirementGroup}
        />
      )}
    </>
  );
};

export default SiteListView;
