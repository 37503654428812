import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { MdClose, MdList, MdModeEdit } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../assets/theme/colors";
import RequirementsTable from "./RequirementsTable";
import ContentLayout from "../../layout/ContentLayout";
import { DeleteActionAlert } from "./DeleteActionAlert";
import ActionBar from "../../components/Common/ActionBar";
import DeleteIcon from "../../components/Common/DeleteIcon";
import Requirement from "../../../domain/entities/requirement";
import DocumentType from "../../../domain/entities/documentType";
import ActionBarItem from "../../components/Common/ActionBarItem";
import { RequirementUpdateModal } from "./RequirementUpdateModal";
import { Permission } from "../../components/Permissions/Permissions";
import SelectSitesView from "../../components/Views/common/SelectSitesView";
import { DocumentTypeAdd } from "../../../domain/repositories/siteRepository";
import RenderIf from "../../components/Permissions/RenderIf";
import DocumentTypeWithPublic from "../../../domain/entities/documentTypeWithPublic";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import {
  Flex,
  Heading,
  Icon,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  DocumentTypeWithRequirement
} from "../../components/Views/common/AddDocumentTypesModal";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import CreateRequirementModal from "../../components/Views/common/CreateRequirementModal";
import { OrderDocumentsByVariantModal } from "../../components/Views/Sites/OrderDocumentsByVariantModal";
import { Alert } from "./Alert";

type RequirementsViewProps = {
  useViewModel: any;
  groupId?: string;
  siteId?: string;
  requirementSubject: RequirementSubject;
  namespace: string;
  showSiteSelection: boolean;
  hasTemplate?: boolean;
  permissions?: {
    add?: Permission | Permission[];
    edit: Permission | Permission[];
    delete: Permission | Permission[];
  };
  isPropagable?: boolean;
};

const RequirementsView = ({
  useViewModel,
  groupId,
  siteId,
  requirementSubject,
  namespace,
  showSiteSelection,
  permissions,
  hasTemplate,
  isPropagable
}: RequirementsViewProps) => {
  const { t } = useTranslation("requirements");
  const [selectAllSites, setSelectAllSites] = useState<boolean>(false);


  let titleLabel = "";
  let subtitleLabel = "";

  switch (requirementSubject) {
    case RequirementSubject.SUPPLIER:
      titleLabel = t(namespace + ".companies");
      subtitleLabel = t(namespace + ".manageCompanies");
      break;
    case RequirementSubject.WORKER:
      titleLabel = t(namespace + ".workers");
      subtitleLabel = t(namespace + ".manageWorkers");
      break;
    case RequirementSubject.VEHICLE:
      titleLabel = t(namespace + ".vehicles");
      subtitleLabel = t(namespace + ".manageVehicles");
      break;
    case RequirementSubject.MACHINE:
      titleLabel = t(namespace + ".machines");
      subtitleLabel = t(namespace + ".manageMachines");
      break;
    case RequirementSubject.TOOL:
      titleLabel = t(namespace + ".tools");
      subtitleLabel = t(namespace + ".manageTools");
      break;
    case RequirementSubject.CHEMICAL:
      titleLabel = t(namespace + ".chemicals");
      subtitleLabel = t(namespace + ".manageChemicals");
      break;
    default:
      break;
  }

  const {
    error,
    requirements,
    supplierRequirementSource,
    setSupplierRequirementSource,
    requirementsLoading,
    addRequirementToGroup,
    addRequirementToSite,
    removeRequirementFromGroup,
    removeRequirementFromGroupIsBusy,
    removeRequirementFromSite,
    updateRequirement,
    requirementsByVariantFetching,
    requirementsGroupByVariantFetching,
    requirementsByVariantIsBusy,
    requirementsGroupByVariantIsBusy,
    variants,
    requirementsByVariant,
    requirementsGroupByVariant,
    setVariantId,
    specializations,
    moveRequirement,
    moveRequirementsByVariant,
    moveRequirementsGroupByVariant,
    documentTypesProps,
    updateVariant,
    updateSpecialization,
    variantRenameError,
    specializationRenameError,
    isTagLoading,
    availableSites,
    availableSitesHasNextPage,
    availableSitesFetchNextPage,
    availableSitesCount,
    availableSitesIsFetching,
    updateAvailableSites,
    setEnablableGetPropagableSites,
    filterSites,
    setFilterSites,
    setSortSites,
    sortSites,
    createVariant,
    createVariantIsBusy,
    createSpecialization,
    createSpecializationIsBusy,
    addTemplateToSiteRequirement,
    deleteTemplateFromSiteRequirement,
    requirementWithVariantAlreadyExistsError,
    setRequirementWithVariantAlreadyExistsError,
    setReqByVariant,
    setReqGruoupByVariant,
    setHookDocumentCategory
  } = useViewModel(groupId ?? siteId, requirementSubject);

  const [selectedRequirement, setSelectedRequirement] = useState<Requirement>();
  const [showAddDocumentType, setShowAddDocumentType] =
    useState<boolean>(false);
  const [showAskConfirmDelete, setShowAskConfirmDelete] =
    useState<boolean>(false);
  const [showEditRequirement, setShowEditRequirement] =
    useState<boolean>(false);
  const [addDocumentIsLoading, setAddDocumentIsLoading] =
    useState<boolean>(false);
  const [showOrderRequirements, setShowOrderRequirements] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const showAddDocumentTypeModal = () => {
    setEnablableGetPropagableSites(true)
    setShowAddDocumentType(true);
  };

  const discardChangesFunction = () => {
    setShowAddDocumentType(false);
  };

  const openRequirementEdit = (requirement: Requirement) => {
    setSelectedRequirement(requirement);
    setEnablableGetPropagableSites(true)
    setShowEditRequirement(true);
  };

  const askConfirmDelete = (requirement: Requirement) => {
    setSelectedRequirement(requirement);
    setEnablableGetPropagableSites(true)
    setShowAskConfirmDelete(true);
  };

  const cancelConfirmDelete = () => {
    setShowAskConfirmDelete(false);
    setSelectedRequirement(undefined);
  };

  const showOrderRequirementsModal = () => {
    setShowOrderRequirements(!showOrderRequirements);
  };

  const confirmDelete = async (siteIds?: string[]) => {
    if (groupId) {
      await removeRequirementFromGroup(selectedRequirement, selectAllSites ? [] : siteIds, selectAllSites);
    }
    if (siteId) {
      await removeRequirementFromSite(selectedRequirement);
    }
    cancelConfirmDelete();
    setFilterSites(null);
  };

  const addDocumentTypesToGroup = async (
    documentTypes: (
      | DocumentType
      | DocumentTypeWithRequirement
      | DocumentTypeWithPublic
    )[],
    siteIds?: string[],
    selectAllSites?: boolean
  ) => {
    setAddDocumentIsLoading(true);
    if (groupId || siteId) {
      const documents: DocumentTypeAdd[] = documentTypes.map(
        (d: DocumentTypeWithRequirement) => {
          return {
            documentTypeId: d.id,
            isOptional: d.isOptional,
            graceDays: d.graceDays,
            variants: variants.filter((v) => d.variants.includes(v.id)),
            specializations: specializations.filter((s) =>
              d.specializations.includes(s.id)
            ),
            source: supplierRequirementSource
          };
        }
      );

      groupId
        ? await addRequirementToGroup(documents, selectAllSites ? [] : siteIds, selectAllSites)
        : await addRequirementToSite(documents);
      setAddDocumentIsLoading(false);
      setFilterSites(null);
    }
    setShowAddDocumentType(false);
  };

  const toggleRequirementOptionalFlag = (
    requirement: Requirement,
    newValue: boolean
  ) => {
    requirement.isOptional = newValue;
    return updateRequirement(requirement);
  };

  const goToCompanyRequirementsGroups = () => {
    navigate(`/company/requirements`);
  };

  const handleMoveRequirementsByVariant = (requirements: Requirement[]) => {
    if (groupId) {
      moveRequirementsGroupByVariant(requirements);
    } else {
      moveRequirementsByVariant(requirements);
    }
  };

  const sanitizedHtmlContent = DOMPurify.sanitize(
    t(subtitleLabel, { color: COLORS.sikuroBlue })
  );
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  /**
   * useEffect that will set the documentTypeProps document category everytime the user changes the subject
   * in this way the query to get the document types will be triggered with the new subject
   */
  useEffect(() => {
    documentTypesProps.setHookDocumentCategory(requirementSubject as unknown as DocumentTypeCategory);
  }, [requirementSubject]);

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            bgColor={COLORS.sikuroBlue}
            color="white"
            icon={MdClose}
            description={t("close", { ns: "common" })}
            onClick={goToCompanyRequirementsGroups}
          />
          <RenderIf permissions={permissions.add}>
            <ActionBarItem
              icon={FiPlus}
              description={t("addRequirementToGroup")}
              onClick={() => showAddDocumentTypeModal()}
            />
          </RenderIf>
          <ActionBarItem
            icon={MdList}
            description={t("orderRequirements")}
            onClick={() => showOrderRequirementsModal()}
          />
        </ActionBar>
      }
    >
      {showAskConfirmDelete && !showSiteSelection && (
        <DeleteActionAlert
          isLoading={removeRequirementFromGroupIsBusy}
          onConfirm={confirmDelete}
          onCancel={cancelConfirmDelete}
          mainTitle={t("warning", { ns: "common" })}
          title={
            namespace === "sites"
              ? t("propagateRequirementInfo", { ns: "documents" })
              : t(namespace + ".confirmDeleteRequirement")
          }
          leftButtonText={t("confirm", { ns: "common" })}
          rightButtonText={t("cancel", { ns: "common" })}
          isOpen={showAskConfirmDelete}
        />
      )}
      <Flex
        flex={1}
        h="100%"
        width={isMobile ? "1200px" : "100%"}
        padding={8}
        textAlign="center"
        flexDirection="column"
        alignItems="start"
        justifyContent="start"
      >
        {error && <Heading>{error.message}</Heading>}

        <Text textColor={COLORS.sikuroBlue} fontSize={20} fontWeight={"bold"}>
          {titleLabel}
        </Text>
        <Text
          dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}
          fontSize={16}
          color="black"
          fontWeight={"normal"}
        ></Text>
        <Flex
          flexDirection={"column"}
          alignItems={"start"}
          width="100%"
          position="relative"
          overflow={"hidden"}
          mt={4}
        >
          <RequirementsTable
            variants={variants}
            specializations={specializations}
            requirements={requirements}
            isLoading={requirementsLoading}
            onToggleOptional={toggleRequirementOptionalFlag}
            actions={[
              ...(hasTemplate
                ? [
                  {
                    label: t("uploadTemplate", { ns: "documents" }),
                    templateActions: {
                      add: addTemplateToSiteRequirement,
                      delete: deleteTemplateFromSiteRequirement,
                    },
                    permissions: permissions.edit,
                  },
                ]
                : []),
              {
                icon: <Icon as={MdModeEdit} fontSize="22px" fill="#767676" />,
                label: t("edit", { ns: "common" }),
                onClick: openRequirementEdit,
                permissions: permissions.edit,
              },
              {
                icon: <DeleteIcon fontSize="18px" fill="#767676" />,
                label: t("delete", { ns: "common" }),
                onClick: askConfirmDelete,
                permissions: permissions.delete,
              },
            ]}
            moveRequirement={moveRequirement}
            onUpdateVariant={updateVariant}
            onUpdateSpecialization={updateSpecialization}
            variantRenameError={variantRenameError}
            specializationRenameError={specializationRenameError}
            requirementSubject={requirementSubject}
            isTagLoading={isTagLoading}
          />
        </Flex>
      </Flex>

      {showAskConfirmDelete && showSiteSelection && (
        <Modal
          isOpen={true}
          closeOnOverlayClick={true}
          onClose={() => setShowAskConfirmDelete(false)}
          size="4xl"
        >
          <ModalOverlay />
          <ModalContent
            style={{ background: "white", padding: "2rem" }}
            overflowY={"scroll"}
          >
            <SelectSitesView
              autosize
              title={t("deleteRequirementSub")}
              subtitle={t("selectSitesDeleteRequirement", {
                requirementName: selectedRequirement?.documentType?.name,
              })}
              reminderText={t("noSiteSelected", { ns: "common" })}
              alertText={t("siteSelectionAlert", { ns: "common" })}
              onClose={() => setShowAskConfirmDelete(false)}
              onSaveAction={confirmDelete}
              siteList={availableSites}
              includeFooterButton={true}
              showSelectAll={true}
              operationIsBusy={removeRequirementFromGroupIsBusy}
              siteCount={availableSitesCount}
              hasNextPage={availableSitesHasNextPage}
              fetchNextPage={availableSitesFetchNextPage}
              isFetchingSites={availableSitesIsFetching}
              filterResourceSites={filterSites}
              updateFilterResourceSites={updateAvailableSites}
              setSortResourceSites={setSortSites}
              sortResourceSites={sortSites}
              setSelectAllSites={setSelectAllSites}
              selectAllSites={selectAllSites}
            />
          </ModalContent>
        </Modal>
      )}
      {requirementWithVariantAlreadyExistsError &&  <Alert
        title={t("warning", { ns: "common" })}
        message={t(requirementWithVariantAlreadyExistsError, { ns: "errors" })}
        variant="warning"
        onClose={() => {
          setRequirementWithVariantAlreadyExistsError(null);
        }}
      />}

      {showEditRequirement && (
        <RequirementUpdateModal
          requirement={selectedRequirement}
          onClose={() => setShowEditRequirement(false)}
          variants={variants}
          specializations={specializations}
          handleRequirementUpdate={(requirement: Requirement, siteIds?: string[], selectAllSites?: boolean) => {
            setFilterSites(null);
          updateRequirement(requirement, siteIds, selectAllSites)}}
          createVariantApi={createVariant}
          createSpecializationApi={createSpecialization}
          showSiteSelection={showSiteSelection}
          createVariantIsBusy={createVariantIsBusy}
          createSpecializationIsBusy={createSpecializationIsBusy}
          namespace={namespace}
          propagableSites={availableSites}
          propagableSitesHasNextPage={availableSitesHasNextPage}
          propagableSitesFetchNextPage={availableSitesFetchNextPage}
          propagableSitesCount={availableSitesCount}
          propagableSitesIsFetching={availableSitesIsFetching}
          updatePropagableSites={updateAvailableSites}
          propagableFilterSites={filterSites}
          setSortSites={setSortSites}
          sortSites={sortSites}
        />
      )}

      {showAddDocumentType && (
        <CreateRequirementModal
          {...documentTypesProps}
          isRequirement
          isPropagable={isPropagable}
          requirements={requirements}
          variants={variants}
          specializations={specializations}
          onClose={discardChangesFunction}
          onConfirm={addDocumentTypesToGroup}
          isFetching={documentTypesProps.documentTypesIsFetching}
          showSiteSelection={showSiteSelection}
          propagableSites={availableSites}
          propagableSitesHasNextPage={availableSitesHasNextPage}
          propagableSitesFetchNextPage={availableSitesFetchNextPage}
          propagableSitesCount={availableSitesCount}
          propagableSitesIsFetching={availableSitesIsFetching}
          updatePropagableSites={updateAvailableSites}
          propagableFilterSites={filterSites}
          setSortSites={setSortSites}
          sortSites={sortSites}
          isAddingDocumentsApi={addDocumentIsLoading}
          title={t("createRequirementFromDocuType", { ns: "requirements" })}
          enableVariantSpecSelection={true}
          showAvailableDocuments={false}
          createVariant={createVariant}
          requirementSubject={requirementSubject}
          supplierRequirementSource={supplierRequirementSource}
          setSupplierRequirementSource={setSupplierRequirementSource}
        />
      )}

      {showOrderRequirements && (
        <OrderDocumentsByVariantModal
          title={t("orderRequirements", { ns: "requirements" })}
          onClose={showOrderRequirementsModal}
          documents={
            groupId ? requirementsGroupByVariant : requirementsByVariant
          }
          isButtonDisabled={false}
          isLoading={
            requirementsByVariantFetching ||
            requirementsByVariantIsBusy ||
            requirementsGroupByVariantFetching ||
            requirementsGroupByVariantIsBusy
          }
          variants={variants}
          setVariantId={setVariantId}
          moveDocumentsByVariant={handleMoveRequirementsByVariant}
          labelTagSelect={t("variant", { ns: "siteResources" })}
          emptyText={t("noRequirementsFound", { ns: "requirements" })}
        />
      )}
    </ContentLayout>
  );
};

export default RequirementsView;
