import StateTag from "../../Common/StateTag";
import { useSearchParams } from "react-router-dom";
import { formatDateBasedOnLanguage, formatDateTime } from "../../../../utils";
import { useTranslation } from "react-i18next";
import React, { FC, useState } from "react";
import RenderIf from "../../Permissions/RenderIf";
import { Alert } from "../../../screens/Common/Alert";
import { useNavigate, useParams } from "react-router-dom";
import { Permission } from "../../Permissions/Permissions";
import DeleteButton from "../../Common/table/DeleteButton";
import Supplier from "../../../../domain/entities/supplier";
import InfiniteTable from "../../Common/table/InfiniteTable";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import { DeleteActionAlert } from "../../../screens/Common/DeleteActionAlert";
import { InvitedSupplier } from "../../../../domain/entities/invitedSupplier";
import { ActiveSupplierStatus, SupplierStatus } from "../../../../domain/entities/supplierStatus.enum";
import { UpdateFilter } from "../../../hooks/Site/useSiteSupplierDetailViewModel";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { SupplierEvaluationState } from "../../../../domain/entities/supplierEvaluationState.enum";
import {
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Select,
  Flex,
  Tooltip,
  Button,
  ButtonGroup,
} from "@chakra-ui/react";
import {
  ActiveSuppliersFilter,
  ArchivedSuppliersFilter,
  InvitedSuppliersFilter,
} from "../../../../domain/repositories/supplierRepository";
import SiteSuppliersNestedView from "./SiteSuppliersNestedView";
import { ConfirmAlert } from "../../../screens/Common/ConfirmAlert";
import { FiSend } from "react-icons/fi";
import ActionButton from "../../Common/table/ActionButton";
import SearchInput from "../../../screens/Common/SearchInput";
import { FaListUl } from "react-icons/fa";
import { PiTreeViewFill } from "react-icons/pi";
import { COLORS } from "../../../assets/theme/colors";
import { HiOutlineArchiveBoxXMark } from "react-icons/hi2";
import RestoreButton from "../../Common/table/RestoreButton";

export enum SupplierListType {
  PLAIN = "Plain",
  NESTED = "Nested",
}

interface Props {
  suppliers: Supplier[];
  suppliersNested: Supplier[];
  suppliersHasNextPage: boolean;
  suppliersFetchNextPage: () => void;
  isFetching: boolean;
  sortActive?: SortMeta;
  updateFilterActive?: UpdateFilter;
  filterActive?: ActiveSuppliersFilter;
  setSortActive?: (sort: SortMeta) => void;
  deleteSupplier: (supplierId: string) => void;
  invitedSuppliers: InvitedSupplier[];
  filterInvited: InvitedSuppliersFilter;
  updateFilterInvited?: UpdateFilter;
  sortInvited: SortMeta;
  setSortInvited?: (sort: SortMeta) => void;
  invitedSuppliersHasNextPage: boolean;
  invitedSuppliersFetchNextPage: () => void;
  invitedIsFetching: boolean;
  autosize?: boolean;
  deleteSupplierIsLoading: boolean;
  messageInvitation: string | null;
  renewInvitation: (token: string) => void;
  setMessageInvitation: (message: string) => void;
  setSearchInvited: (message: string) => void;
  setSearch: (message: string) => void;
  restoreSupplierArchived: (supplierId: string) => Promise<void>;
  restoreSupplierArchivedIsLoading: boolean;
}

const SiteSupplierTable: FC<Props> = ({
  suppliers,
  suppliersNested,
  suppliersHasNextPage,
  suppliersFetchNextPage,
  isFetching,
  deleteSupplier,
  filterActive,
  updateFilterActive,
  sortActive,
  setSortActive,
  invitedSuppliers,
  filterInvited,
  sortInvited,
  setSortInvited,
  updateFilterInvited,
  invitedSuppliersHasNextPage,
  invitedSuppliersFetchNextPage,
  invitedIsFetching,
  autosize,
  deleteSupplierIsLoading,
  messageInvitation,
  renewInvitation,
  setMessageInvitation,
  setSearchInvited,
  setSearch,
  restoreSupplierArchived,
  restoreSupplierArchivedIsLoading,
}) => {
  const { siteId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("supplier");

  const [searchParams, _] = useSearchParams();

  const navigateToSupplierDetail = (supplier: Supplier) => {
    navigate(`/sites/${siteId}/suppliers/${supplier?.company?.id}/`);
  };

  const [showAskConfirmDelete, setShowAskConfirmDelete] = useState<
    false | string
  >(false);
  const [showNavigationAlert, setShowNavigationAlert] = useState<
    [boolean, string]
  >([false, ""]);
  const [selectedListType, setSelectedListType] = useState<SupplierListType>(
    SupplierListType.PLAIN
  );
  const [showAskConfirmModal, setShowAskConfirmModal] = useState<boolean>();
  const [supplierStatus, setSupplierStatus] = useState<string>('');
  const [invitationToken, setInvitationToken] = useState<string>();
  const [restoreSupplier, setRestoreSupplier] = useState<string>();

  const confirmDelete = async () => {
    showAskConfirmDelete && (await deleteSupplier(showAskConfirmDelete));
    setShowAskConfirmDelete(false);
  };

  const columns: {
    field: keyof ActiveSuppliersFilter & { archived?: boolean };
    type: "text" | "select" | "date-range";
  }[] = [
    { field: "name", type: "text" },
    { field: "vat", type: "text" },
    { field: "evaluationResult", type: "select" },
    { field: "status", type: "select" },
    { field: "subscribeDate", type: "date-range" },
  ];

  const ArchivedColumns: {
    field: keyof ArchivedSuppliersFilter;
    type: "text" | "select" | "date-range";
  }[] = [
    { field: "name", type: "text" },
    { field: "vat", type: "text" },
    { field: "evaluationResult", type: "select" },
    { field: "subscribeDate", type: "date-range" },
  ];

  const selected = (searchParams.get("selected") ?? "").toLowerCase();

  const invitedColumns: {
    field: keyof InvitedSuppliersFilter;
    type: "text" | "select" | "date-range";
  }[] = [
    { field: "name", type: "text" },
    { field: "email", type: "text" },
    { field: "sendDate", type: "date-range" },
  ];

  return (
    <>
      <Tabs width="100%" defaultIndex={selected === "invited" ? 1 : 0}>
        <TabList>
          <Tab
            width={"100%"}
            textAlign={"center"}
            onClick={() => {
              setSelectedListType(SupplierListType.PLAIN)
              updateFilterActive("archived", "false");
            }}
          >
            {t("listOfSuppliers", { ns: "supplier" })}
          </Tab>
          <Tab width={"100%"} textAlign={"center"}>
            {t("listOfInvitedSuppliers", { ns: "supplier" })}
          </Tab>
          <Tab
            width={"100%"}
            textAlign={"center"}
            onClick={() => {
              updateFilterActive("archived", "true");
            }}
          >
            {t("listOfArchivedSuppliers", { ns: "supplier" })}
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel paddingRight={0} paddingLeft={0}>
            <Flex
              justifyContent={"space-between"}
              alignItems="center"
              w={"100%"}
              gap={8}
              marginBottom={4}
            >
              <ButtonGroup isAttached>
                <Tooltip label={t("plainList")} fontSize="md">
                  <Button
                    variant={
                      selectedListType === SupplierListType.PLAIN
                        ? "solid"
                        : "outline"
                    }
                    sx={{
                      borderRadius: 10,
                      color:
                        selectedListType === SupplierListType.PLAIN
                          ? "white"
                          : COLORS.sikuroBlue,
                      backgroundColor:
                        selectedListType === SupplierListType.PLAIN
                          ? COLORS.sikuroBlue
                          : "white",
                    }}
                    onClick={() => setSelectedListType(SupplierListType.PLAIN)}
                  >
                    <FaListUl />
                  </Button>
                </Tooltip>
                <Tooltip label={t("nestedList")} fontSize="md">
                  <Button
                    variant={
                      selectedListType === SupplierListType.NESTED
                        ? "solid"
                        : "outline"
                    }
                    sx={{
                      borderRadius: 10,
                      color:
                        selectedListType === SupplierListType.NESTED
                          ? "white"
                          : COLORS.sikuroBlue,
                      backgroundColor:
                        selectedListType === SupplierListType.NESTED
                          ? COLORS.sikuroBlue
                          : "white",
                    }}
                    onClick={() => setSelectedListType(SupplierListType.NESTED)}
                  >
                    <PiTreeViewFill />
                  </Button>
                </Tooltip>
              </ButtonGroup>
              <SearchInput onSearch={setSearch} />
            </Flex>

            {selectedListType === SupplierListType.PLAIN && (
              <InfiniteTable
                autosize={autosize}
                tableId="active-suppliers-table"
                infiniteScroll={{
                  dataLength: suppliers.length,
                  hasNextPage: suppliersHasNextPage,
                  fetchNextPage: suppliersFetchNextPage,
                }}
                emptyText={t("noActiveSupplier", { ns: "supplier" })}
                isLoading={isFetching}
                bottomThreshold={100}
              >
                <Thead>
                  <Tr>
                    {columns.map((c) => (
                      <Th key={c.field}>
                        <TableColumnHeader
                          text={t(`columns.${c.field}`)}
                          filter={{
                            isActive:
                              !!filterActive[c?.field] &&
                              (!Array.isArray(filterActive[c.field]) ||
                                !!filterActive[c.field][0]),
                            component: (
                              <ColumnFilterComponent
                                selectOptions={
                                  c.field === "evaluationResult"
                                    ? SupplierEvaluationState
                                    : ActiveSupplierStatus
                                }
                                value={filterActive[c.field]}
                                type={c.type}
                                updateFilter={(value) =>
                                  updateFilterActive(c.field, value)
                                }
                                namespace="enum"
                                optionsTranslationContext={
                                  c.field === "evaluationResult"
                                    ? "supplierEvaluation"
                                    : "supplierStatus"
                                }
                              />
                            ),
                          }}
                          sort={{
                            handler: (direction) =>
                              setSortActive({ field: c.field, direction }),
                            direction:
                              sortActive?.field === c.field
                                ? sortActive.direction
                                : null,
                          }}
                        />
                      </Th>
                    ))}
                    <Th w={20} />
                  </Tr>
                </Thead>

                <Tbody>
                  {suppliers.map((s) => (
                     <Tr
                      key={s.company?.id}
                      backgroundColor={s.archived ? COLORS.lightRed : ""}
                      onClick={() => {
                        let alertText;
                        if (s.status === SupplierStatus.PENDING) {
                          alertText = "navigationBlocked";
                        } else if (s.archived) {
                          alertText = "supplierNavigationBlocked";
                        }
                        s.status !== SupplierStatus.PENDING && !s.archived
                          ? navigateToSupplierDetail(s)
                          : setShowNavigationAlert([true, alertText]);
                      }}
                    >
                      <Td>
                        <Flex flexDirection={"row"} alignItems="center">
                          {s?.company?.name}
                        </Flex>
                      </Td>
                      <Td>{s?.company?.vat}</Td>
                      <Td>
                        <StateTag
                          value={
                            s.evaluationResult ??
                            SupplierEvaluationState.UNEVALUATED
                          }
                          type="supplierEvaluation"
                        />
                      </Td>
                      <Td>
                        <StateTag value={s.status} type="supplierStatus" />
                      </Td>
                      <Td>
                        {formatDateBasedOnLanguage(
                          s?.subscribeDate as unknown as string,
                          true
                        )}
                      </Td>
                      <Td
                        w={20}
                        style={{ textAlign: "right", cursor: "pointer" }}
                        isNumeric
                      >
                        
                          <RenderIf
                            permissions={[
                              Permission.Sites_Manage,
                              Permission.Sites_InviteSupplier,
                            ]}
                          >
                            <DeleteButton
                              tooltipLabel={s.status !== 'pending' ?  t("archive", { ns: "common" }) : t("deleteInvitation", { ns: "supplier" })}
                              onClick={(e) => {
                                e.preventDefault();
                                setSupplierStatus(s.status)
                                setShowAskConfirmDelete(s.company.id);
                              }}
                            />
                          </RenderIf>
                        
                      </Td>
                    </Tr>
                    ))}
                </Tbody>
              </InfiniteTable>
            )}

            {selectedListType === SupplierListType.NESTED && (
              <Flex marginTop={2}>
                <SiteSuppliersNestedView suppliers={suppliersNested} />
              </Flex>
            )}
          </TabPanel>
          <TabPanel paddingRight={0} paddingLeft={0}>
            <Flex
              justifyContent={"end"}
              alignItems="center"
              w={"100%"}
              marginBottom={4}
            >
              <SearchInput onSearch={setSearchInvited} />
            </Flex>
            <InfiniteTable
              autosize={true}
              tableId="invited-suppliers-table"
              infiniteScroll={{
                dataLength: invitedSuppliers.length,
                hasNextPage: invitedSuppliersHasNextPage,
                fetchNextPage: invitedSuppliersFetchNextPage,
              }}
              emptyText={t("noInvitedSupplier", { ns: "supplier" })}
              isLoading={invitedIsFetching}
              bottomThreshold={100}
            >
              <Thead>
                <Tr bg={"gray.300"}>
                  {invitedColumns.map((c) => (
                    <Th key={c.field}>
                      <TableColumnHeader
                        text={t(`columns.${c.field}`)}
                        filter={{
                          isActive:
                            !!filterInvited[c?.field] &&
                            (!Array.isArray(filterInvited[c.field]) ||
                              !!filterInvited[c.field][0]),
                          component: (
                            <ColumnFilterComponent
                              value={filterInvited[c.field]}
                              type={c.type}
                              updateFilter={(value) =>
                                updateFilterInvited(c.field, value)
                              }
                            />
                          ),
                        }}
                        sort={{
                          handler: (direction) =>
                            setSortInvited({ field: c.field, direction }),
                          direction:
                            sortInvited?.field === c.field
                              ? sortInvited.direction
                              : null,
                        }}
                      />
                    </Th>
                  ))}
                  <Th w={20} />
                </Tr>
              </Thead>
              <Tbody>
                {invitedSuppliers.map((s) => (
                  <Tr key={s.name}>
                    <Td textColor={"gray.700"}>{s?.name}</Td>
                    <Td textColor={"gray.700"}>{s?.email}</Td>
                    <Td textColor={"gray.700"}>
                      {formatDateBasedOnLanguage(
                        s?.invitedOn as unknown as string,
                        true
                      )}
                    </Td>
                    <Td
                      w={20}
                      style={{ textAlign: "right", cursor: "pointer" }}
                    >
                      {s.invitationToken && (
                        <Tooltip
                          label={t("renewInvitation", { ns: "settings" })}
                          aria-label={t("renewInvitation", { ns: "settings" })}
                          placement="bottom"
                        >
                          <span>
                            <ActionButton
                              aria-label="send-reminder"
                              icon={<FiSend />}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setInvitationToken(s.invitationToken),
                                  setShowAskConfirmModal(true);
                              }}
                            />
                          </span>
                        </Tooltip>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </InfiniteTable>
          </TabPanel>
          <TabPanel paddingRight={0} paddingLeft={0}>
            <InfiniteTable
              autosize={autosize}
              tableId="archive-suppliers-table"
              infiniteScroll={{
                dataLength: suppliers.length,
                hasNextPage: suppliersHasNextPage,
                fetchNextPage: suppliersFetchNextPage,
              }}
              emptyText={t("noActiveSupplier", { ns: "supplier" })}
              isLoading={isFetching}
              bottomThreshold={100}
            >
              <Thead>
                <Tr>
                  {ArchivedColumns.map((c) => (
                    <Th key={c.field}>
                      <TableColumnHeader
                        text={t(`columns.${c.field}`)}
                        filter={{
                          isActive:
                            !!filterActive[c?.field] &&
                            (!Array.isArray(filterActive[c.field]) ||
                              !!filterActive[c.field][0]),
                          component: (
                            <ColumnFilterComponent
                              selectOptions={
                                c.field === "evaluationResult"
                                  ? SupplierEvaluationState
                                  : SupplierStatus
                              }
                              value={filterActive[c.field]}
                              type={c.type}
                              updateFilter={(value) =>
                                updateFilterActive(c.field, value)
                              }
                              namespace="enum"
                              optionsTranslationContext={
                                c.field === "evaluationResult"
                                  ? "supplierEvaluation"
                                  : "supplierStatus"
                              }
                            />
                          ),
                        }}
                        sort={{
                          handler: (direction) =>
                            setSortActive({ field: c.field, direction }),
                          direction:
                            sortActive?.field === c.field
                              ? sortActive.direction
                              : null,
                        }}
                      />
                    </Th>
                  ))}
                  <Th w={20} />
                </Tr>
              </Thead>

              <Tbody>
                {suppliers.map((s) => (
                  <Tr
                    key={s.company?.id}
                    onClick={() => {
                      !s.archived && navigateToSupplierDetail(s);
                    }}
                  >
                    <Td>{s?.company?.name}</Td>
                    <Td>{s?.company?.vat}</Td>
                    <Td>
                      <StateTag
                        value={
                          s.evaluationResult ??
                          SupplierEvaluationState.UNEVALUATED
                        }
                        type="supplierEvaluation"
                      />
                    </Td>
                    <Td>
                      {formatDateBasedOnLanguage(
                        s?.subscribeDate as unknown as string,
                        true
                      )}
                    </Td>
                    <Td
                      w={20}
                      style={{ textAlign: "right", cursor: "pointer" }}
                      isNumeric
                    >
                      <RestoreButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setRestoreSupplier(s.company.id);
                        }}
                        tooltipLabel={"restore"}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </InfiniteTable>
          </TabPanel>
        </TabPanels>
      </Tabs>
      {showNavigationAlert[0] && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t(showNavigationAlert[1], { ns: "supplier" })}
          variant="info"
          onClose={() => {
            setShowNavigationAlert([false, ""]);
          }}
        />
      )}

      {messageInvitation && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={
            messageInvitation === "success"
              ? t("renewalInvitationSuccess")
              : t(messageInvitation, { ns: "errors" })
          }
          variant="info"
          onClose={() => {
            setMessageInvitation(undefined);
          }}
        />
      )}

      {restoreSupplier && (
        <ConfirmAlert
          variant="question"
          title={t("confirmRestore", { ns: "siteResource" })}
          message={t("confirmRestoreSupplier")}
          onCancel={() => setRestoreSupplier(undefined)}
          isLoading={restoreSupplierArchivedIsLoading}
          onConfirm={() => {
            restoreSupplierArchived(restoreSupplier);
            setRestoreSupplier(undefined);
          }}
        />
      )}
      {showAskConfirmModal && (
        <ConfirmAlert
          onCancel={() => setShowAskConfirmModal(false)}
          onConfirm={() => {
            renewInvitation(invitationToken), setShowAskConfirmModal(false);
          }}
          title={t("warning", { ns: "common" })}
          variant={"question"}
          message={t("reminderSupplier")}
        />
      )}
      <DeleteActionAlert
        onConfirm={confirmDelete}
        isLoading={deleteSupplierIsLoading}
        onCancel={() => setShowAskConfirmDelete(false)}
        mainTitle={t("warning", { ns: "common" })}
        title={t(supplierStatus !== 'pending' ? "confirmDeleteSupplier" : "confirmDeleteInvitation", { ns: "supplier" })}
        leftButtonText={t("confirm", { ns: "common" })}
        rightButtonText={t("cancel", { ns: "common" })}
        isOpen={!!showAskConfirmDelete}
      />
    </>
  );
};

export default SiteSupplierTable;
