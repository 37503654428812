import React from "react";
import BaseField, {BaseFieldProps} from "./BaseField";
import CreatableSelect, {CreatableProps} from "react-select/creatable";
import ReactSelect from "react-select";

export type BaseSelectFieldProps<Option, IsMulti extends boolean> = BaseFieldProps & {
  optionKeys?: {
    value: string
    label: string
  }
  selectProps: CreatableProps<Option, IsMulti, undefined>
}
const BaseSelectField = <Option extends { [key: string]: any }, IsMulti extends boolean>({
  optionKeys = {label: "label", value: "value"}, selectProps, ...baseProps
}: BaseSelectFieldProps<Option, IsMulti>) => {
  const Select = selectProps.onCreateOption ? CreatableSelect : ReactSelect;
  return (
    <BaseField {...baseProps}>
      <Select
        getOptionLabel={(option) => option[optionKeys.label]}
        getOptionValue={(option) => option[optionKeys.value]}
        menuPortalTarget={document.getElementById("root")}
        menuPosition="fixed"
        menuPlacement="auto"
        {...selectProps}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), ...selectProps.styles }}
      />
    </BaseField>
  )
}

export default BaseSelectField;