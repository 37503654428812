import Machine from "../../../domain/entities/machine";
import {
  makeAddTypologyToMachine,
  makeCreateMachine,
  makeDeleteMachine,
  makeGetMachines,
  makeRestoreMachine,
  makeUploadMachines,
} from "../../../core/app/factory/machines/usecases";
import { ResourceListViewModel } from "../../hooks/Resources/useResourceListViewModel";
import { makeGetTypologies } from "../../../core/app/factory/typologies/usecases";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import Typology from "../../../domain/entities/typology";
import {
  PaginatedResults,
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import ImportInfo from "../../../domain/entities/importInfo";
import FileEntity from "../../../domain/entities/file";
import { GetResourcesFilter } from "../../../domain/repositories/workerRepository";
import {
  GetSitesFilter,
  LinkResourcesToSiteWarning,
} from "../../../domain/repositories/siteRepository";
import Site from "../../../domain/entities/site";
import {
  makeAddResourcesToSites,
  makeGetLinkableSites,
} from "../../../core/app/factory/sites/usecases";
import { ResourceType } from "../../screens/Site/ResourceSelectableTable";

class MachinesListViewModel implements ResourceListViewModel<Machine> {
  private getMachinesUseCase = makeGetMachines();
  private restoreMachineUseCase = makeRestoreMachine();
  private deleteMachineUseCase = makeDeleteMachine();
  private crateMachineUseCase = makeCreateMachine();
  private getTypologiesUseCase = makeGetTypologies();
  private linkTypologyUseCase = makeAddTypologyToMachine();
  private uploadMachines = makeUploadMachines();
  private getLinkableSitesUseCase = makeGetLinkableSites();
  private addResourcesToSitesUseCase = makeAddResourcesToSites();

  async get(
    companyId: string,
    filter?: GetResourcesFilter,
    archived?: boolean,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<PaginatedResults<Machine>> {
    return await this.getMachinesUseCase.Call({
      companyId,
      archived,
      filter,
      sort,
      pageParam,
    });
  }

  async delete(companyId: string, resourceId: string): Promise<void> {
    await this.deleteMachineUseCase.Call({ companyId, machineId: resourceId });
  }

  async restore(companyId: string, resourceId: string): Promise<void> {
    await this.restoreMachineUseCase.Call({ companyId, machineId: resourceId });
  }

  async create(
    companyId: string,
    resource: Machine,
    photo: File,
  ): Promise<Machine> {
    const newMachine = await this.crateMachineUseCase.Call({
      companyId,
      machine: resource,
      photo,
    });

    return newMachine;
  }
  async uploadResources(
    companyId: string,
    file: FileEntity,
  ): Promise<ImportInfo> {
    return await this.uploadMachines.Call({ companyId, file });
  }

  async getTypologies(companyId: string): Promise<Typology[]> {
    return await this.getTypologiesUseCase.Call({
      companyId,
      type: DocumentTypeResourceType.MACHINE,
    });
  }
  async getResourceLinkableSites(
    companyId: string,
    type: string,
    filters?: GetSitesFilter,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<{results: Site[] , count: number }> {
    return await this.getLinkableSitesUseCase.Call({
      companyId,
      resourceType: type,
      filters,
      sort,
      pageParam,
    });
  }
  async addResourcesToSites(
    companyId: string,
    resourceIds: string[],
    siteIds: string[],
    selectAllSites?: boolean,
    selectAllResources?: boolean,
    resourceFilters?: GetResourcesFilter,
    siteFilters?: GetSitesFilter
  ): Promise<LinkResourcesToSiteWarning> {
    return await this.addResourcesToSitesUseCase.Call({
      companyId,
      resourceType: ResourceType.MACHINE,
      resourceIds,
      siteIds,
      selectAllSites,
      selectAllResources,
      resourceFilters,
      siteFilters,
    });
  }
}

export { MachinesListViewModel };
