import React, { FC, useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Site from "../../../domain/entities/site";
import { DeleteActionAlert } from "../Common/DeleteActionAlert";
import { Permission } from "../../components/Permissions/Permissions";
import { InfoBanner } from "../../components/Common/alerts/InfoBanner";
import RenderIf, {
  useHasPermissions,
} from "../../components/Permissions/RenderIf";
import TagSelect, { OptionType } from "../../components/Common/TagSelect";
import DocumentsTable from "../../components/Views/Documents/DocumentsTable";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import { UpdateFilter } from "../../hooks/Site/useSiteResourceDetailViewModel";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import {
  useDocumentsFilesDownloader,
  UseDownloader,
} from "../../hooks/Document/useDocumentsFilesDownloader";
import AddDocumentTypesModal from "../../components/Views/common/AddDocumentTypesModal";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import DOMPurify from "dompurify";
import { COLORS } from "../../assets/theme/colors";
import { ResourceDocumentsPermissions } from "../../components/Views/common/SiteResourceDocuments";
import PlusButton from "../../components/Common/PlusButton";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";
import ManageTaskWithAiModal from "../../components/Views/Documents/ManageTaskWithAiModal";
import DownloadAllButton from "../../components/Common/DownloadAllButton";
import { FiAlertTriangle, FiInfo } from "react-icons/fi";
import { DownloadResourceFileScope } from "../../../domain/repositories/documentsDownloader";
import { useToast } from '@chakra-ui/react'
// Props.
export interface ResourceDocumentProps {
  showAddDocumentType: boolean;
  setShowAddDocumentType: (flag: boolean) => void;
  showManageTaskWithAI: boolean;
  setShowManageTaskWithAI: (flag: boolean) => void;
  category: DocumentTypeCategory;
  documentsHook: any;
  resourcePath: string;
  downloader: UseDownloader;
  resourceSites?: Site[];
  isFetching?: boolean;
  isFetchingSites?: boolean;
  sort?: SortMeta;
  setSort?: (sort: SortMeta) => void;
  setFilter?: (filter: any) => void;
  filter?: any;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  setEnableGetResourceSites?: ({ getSites, action }) => void;
  filterResourceSites?: GetSitesFilter;
  updateFilterResourceSites?: UpdateFilter;
  setSortResourceSites?: (sort: SortMeta) => void;
  hasSites?: (action: string, documentTypeId: string) => Promise<boolean>;
  siteCount?: number;
  sortResourceSites?: SortMeta;
  selectAllSites?: boolean;
  setSelectAllSites?: (value: boolean) => void;
  resourceId?: string;
  permissions: {
    documents: ResourceDocumentsPermissions;
    editTypology: Permission;
    addDocument: Permission;
    manageDocumentWithAi: Permission;
  };
  setFilterResourceSites?: (filter: GetSitesFilter) => void;
}

const ResourceDocumentsView: FC<ResourceDocumentProps> = ({
  showAddDocumentType,
  setShowAddDocumentType,
  showManageTaskWithAI,
  setShowManageTaskWithAI,
  documentsHook,
  category,
  downloader,
  resourceSites,
  isFetchingSites,
  setEnableGetResourceSites,
  filterResourceSites,
  updateFilterResourceSites,
  setSortResourceSites,
  sortResourceSites,
  hasSites,
  permissions,
  siteCount,
  fetchNextPage,
  hasNextPage,
  resourceId,
  setFilterResourceSites
  // selectAllSites,
  // setSelectAllSites
}) => {
  const { t } = useTranslation();
  const translate = (key: "addNewDocument" | "documentsOf"): string => {
    switch (category) {
      case DocumentTypeCategory.CHEMICAL:
        return t(key, { ns: "chemicals" });
      case DocumentTypeCategory.COMPANY:
        return t(key, { ns: "companies" });
      case DocumentTypeCategory.SITE:
        return t(key, { ns: "sites" });
      case DocumentTypeCategory.TOOL:
        return t(key, { ns: "tools" });
    }
  };
  const [showInfoBanner, setShowInfoBanner] = useState(true);
  const [addIsLoading, setAddIsLoading] = useState<boolean>(false);
  const [resourceTypologiesIsLoading, setResourceTypologiesIsLoading] =
    useState(false);
  const {
    typologies,
    typologiesAssociated,
    updateResourceTypologies,
    documentTypesProps,
    documentProps,
    docsProps,
    availableDocumentTypes,
    createPropagableDocuments,
    addFileToResourceDocument,
    communicationProps,
    successMsg,
    setSuccessMsg,
    errorMsg,
    setErrorMsg,
  } = documentsHook;

  const aiSectionCode = (() => {
    switch (category) {
      case DocumentTypeCategory.CHEMICAL:
        return AiTaskSectionCode.MY_RESOURCES_CHEMICALS;;
      case DocumentTypeCategory.MACHINE:
        return AiTaskSectionCode.MY_RESOURCES_MACHINES;
      case DocumentTypeCategory.TOOL:
        return AiTaskSectionCode.MY_RESOURCES_TOOLS;
      case DocumentTypeCategory.VEHICLE:
        return AiTaskSectionCode.MY_RESOURCES_VEHICLES;
      case DocumentTypeCategory.WORKER:
        return AiTaskSectionCode.MY_RESOURCES_WORKERS;
      default:
        return null; // or some default value
    }
  })();
  const toast = useToast()
  const documentDownloader = useDocumentsFilesDownloader(
    null,
    resourceId,
    (category + "s") as DownloadResourceFileScope
  );

  const [showConfirmUpdateTypology, setShowConfirmUpdateTypology] = useState<
    string | string[] | null
  >(null);

  const sanitizedHtmlContent = DOMPurify.sanitize(
    t("tagSelect", { ns: "documents", color: COLORS.sikuroBlue }),
  );

  const onConfirmTypology = async () => {
    setResourceTypologiesIsLoading(true);
    await updateResourceTypologies(
      Array.isArray(showConfirmUpdateTypology)
        ? showConfirmUpdateTypology
        : [(showConfirmUpdateTypology as unknown as OptionType).value],
    );
    setShowConfirmUpdateTypology(null);
    setResourceTypologiesIsLoading(false);
  };

  const canManageDocuments = useHasPermissions(permissions.documents.addFile);
  const canEditTypology = useHasPermissions(permissions.editTypology);

  return (
    <>
      <Text textStyle="h1" mb={2} marginTop={4}>
        {translate("documentsOf")}
      </Text>
      <Flex
        flex={1}
        border="1px solid"
        borderColor="gray.300"
        borderRadius="10px"
        px={3}
        paddingBottom={5}
        textAlign="center"
        flexDirection="column"
        alignItems="start"
        justifyContent="start"
      >
        <Flex
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <div style={{ zIndex: 10 }}>
            <TagSelect
              editable={canEditTypology}
              tags={typologies}
              selectedTags={[typologiesAssociated?.id]}
              setSelectedTags={(tags) =>
                canEditTypology && setShowConfirmUpdateTypology(tags)
              }
              createTag={(tagName) =>
                canEditTypology && setShowConfirmUpdateTypology(tagName)
              }
              defaultMenuIsOpen={false}
              isMulti={false}
              style={{ width: 300 }}
              label={t("typologies", { ns: "documents" })}
              isDisabled={!canEditTypology}
            />
            <Text
              mt={-4}
              fontSize={10}
              dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}
            />
          </div>
          <Flex gap={4}>
            <RenderIf permissions={permissions.addDocument}>
              <PlusButton
                tooltipMessage={t("addDocument", { ns: "documents" })}
                onClick={() => setShowAddDocumentType(true)}
              />
            </RenderIf>
            <RenderIf permissions={permissions.documents.downloadAll}>
              <DownloadAllButton
                onClick={() =>
                  documentDownloader.initiateDocumentsZipDownload(
                    (ok: boolean) => {
                      toast({
                        duration: 5000,
                        isClosable: true,
                        colorScheme: ok ? "green" : "red",
                        icon: ok ? <FiInfo /> : <FiAlertTriangle />,
                        description: ok
                          ? t("downloadAllFilesOk", {
                            ns: "documents"
                          })
                          : t("downloadAllFilesError", {
                            ns: "documents"
                          })
                      });
                    }
                  )
                }
              />
            </RenderIf>
          </Flex>
        </Flex>
        {showInfoBanner && (
          <RenderIf permissions={permissions.documents.addFile ?? []}>
            <InfoBanner
              text={t("dragAndDropInfo", { ns: "documents" })}
              onClose={() => setShowInfoBanner(false)}
            />
          </RenderIf>
        )}
        <Flex
          flexDirection={"column"}
          alignItems={"start"}
          width="100%"
          position="relative"
          mt={8}
        >
          <DocumentsTable
            {...docsProps}
            {...documentProps}
            columns={[
              { field: "name", filterType: "text" as const, label: t("name") },
              {
                field: "expiresAt",
                filterType: "date-range" as const,
                label: t("expiresAt", { ns: "siteResources" }),
              },
              {
                field: "isPublic",
                filterType: "select" as const,
                label: t("isPublic", { ns: "requirements" }),
              },
              { field: "tags", filterType: "tags" as const, label: t("tags") },
            ]}
            tags={documentTypesProps.allTags}
            downloader={downloader}
            resourceSites={resourceSites}
            resourceHasSites={hasSites}
            hasSitesNextPage={hasNextPage}
            fetchSitesNextPage={fetchNextPage}
            siteCount={siteCount}
            isFetchingResourceSites={isFetchingSites}
            enableGetData={(getSites, action) =>
              setEnableGetResourceSites({ getSites, action })
            }
            fromResource={true}
            addFileToResourceDocument={(documentId, file, siteIds, selectAll, filters) =>
              canManageDocuments &&
              addFileToResourceDocument(
                documentId,
                file,
                siteIds,
                category,
                selectAll,
                filters
              )
            }
            filterResourceSites={filterResourceSites}
            updateFilterResourceSites={updateFilterResourceSites}
            setSortResourceSites={setSortResourceSites}
            sortResourceSites={sortResourceSites}
            showPublic={true}
            showExpiresAt={true}
            communicationProps={communicationProps}
            fromSite={false}
            successMsg={successMsg}
            setSuccessMsg={setSuccessMsg}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
            permissions={permissions.documents}
            setFilterResourceSites={setFilterResourceSites}
          />
        </Flex>
      </Flex>

      {showConfirmUpdateTypology && (
        <DeleteActionAlert
          isLoading={resourceTypologiesIsLoading}
          onConfirm={onConfirmTypology}
          onCancel={() => setShowConfirmUpdateTypology(null)}
          mainTitle={t("warning", { ns: "common" })}
          title={t("confirmEditTypology", { ns: "documents" })}
          leftButtonText={t("confirm", { ns: "common" })}
          rightButtonText={t("cancel", { ns: "common" })}
          isOpen={!!showConfirmUpdateTypology}
        />
      )}

      {showAddDocumentType && (
        <AddDocumentTypesModal
          {...documentTypesProps}
          title={translate("addNewDocument")}
          documentTypes={availableDocumentTypes}
          onClose={() => setShowAddDocumentType(false)}
          isPropagable={true}
          isAddingDocumentsApi={addIsLoading}
          onConfirm={async (documentTypes) => {
            setAddIsLoading(true);
            await createPropagableDocuments(documentTypes);
            setShowAddDocumentType(false);
            setAddIsLoading(false);
          }}
          showAvailableDocuments={false}
          isFetching={documentTypesProps.documentTypesIsFetching}
        />
      )}
      {showManageTaskWithAI && (
        <ManageTaskWithAiModal
          title={t("uploadWithAi", { ns: "documents" })}
          onClose={() => {
            docsProps.refetchDocuments();
            setShowManageTaskWithAI(false);
          }}
          isPropagable={true}
          aiTaskSectionCode={aiSectionCode}
          aiTasks={documentsHook.documentAIProps.aiTasks}
          createAiTask={documentsHook.documentAIProps.createAiTask}
          aiTaskDetail={documentsHook.documentAIProps.aiTaskDetail}
          aiTaskDetailFetching={documentsHook.documentAIProps.aiTaskDetailFetching}
          setSelectedAiTaskId={documentsHook.documentAIProps.setAiTaskId}
          aiTasksHasNextPage={documentsHook.documentAIProps.aiTasksHasNextPage}
          aiTasksFetchNextPage={documentsHook.documentAIProps.aiTasksFetchNextPage}
          aiTasksFetching={documentsHook.documentAIProps.aiTasksFetching}
          onDeleteTask={documentsHook.documentAIProps.deleteAiTask}
          onConfirmTask={documentsHook.documentAIProps.confirmAiTask}
          onConfirmTaskIsLoading={documentsHook.documentAIProps.confirmAiTaskIsLoading}
          documentTypesForAi={documentsHook.documentAIProps.documentTypesForAi}
          setEnableDocumentTypesForAiQuery={documentsHook.documentAIProps.setEnableDocumentTypesForAiQuery}
          sitesWhereToPropagate={documentsHook.documentAIProps.sitesForAiTask}
        />
      )}
    </>
  );
};

export default ResourceDocumentsView;
