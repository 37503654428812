import {
  Box,
  Flex,
  HStack,
  RadioProps,
  useMediaQuery,
  useRadio,
  useRadioGroup,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BadgeStatus } from "../../../domain/entities/badgeStatus.enum";
import { BadgeReaderState } from "../../../domain/entities/badgeReaderState.enum";
import { SiteState } from "../../../domain/entities/site";
import { COLORS } from "../../assets/theme/colors";
import { UserState } from "../../../domain/entities/user";
import { WorkerStatus } from "../../../domain/entities/worker";

type StatusToggleProps = {
  status: string;
  onChange: (
    status:
      | BadgeStatus
      | BadgeReaderState
      | SiteState
      | UserState
      | WorkerStatus,
  ) => void;
  type: "badge" | "badgeReader" | "site" | "user" | "worker";
  disabled?: boolean;
  isTablet?: boolean;
};

const STATES_MAP = {
  badge: {
    [BadgeStatus.ACTIVE]: COLORS.sikuroBlue,
    [BadgeStatus.BLOCKED]: "red",
    [BadgeStatus.LOST]: "gray",
  },
  badgeReader: {
    [BadgeReaderState.ACTIVE]: COLORS.sikuroBlue,
    [BadgeReaderState.INACTIVE]: "red",
  },
  site: {
    [SiteState.ACTIVE]: COLORS.sikuroBlue,
    [SiteState.SUSPENDED]: [COLORS.yellow, "black"],
    [SiteState.CLOSED]: "red",
  },
  user: {
    [UserState.ENABLED]: COLORS.sikuroBlue,
    [UserState.BLOCKED]: "red",
  },
  worker: {
    [WorkerStatus.ACTIVE]: "green",
    [WorkerStatus.BLOCKED]: "red",
  },
};

const StatusToggle = ({
  status,
  onChange,
  type,
  disabled,
  isTablet,
}: StatusToggleProps) => {
  const options = Object.keys(STATES_MAP[type]);

  const performOnChange = (
    status:
      | BadgeStatus
      | BadgeReaderState
      | SiteState
      | UserState
      | WorkerStatus,
  ) => {
    if (!disabled) {
      onChange(status);
    }
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    value: status,
    name: "framework",
    onChange: performOnChange,
  });

  const group = getRootProps();

  return isTablet ? (
    <HStack {...group} p={2} spacing="24px" justifyContent={"center"}>
      {options.map((value) => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard
            key={value}
            evaluationType={type}
            value={value}
            disabled={disabled}
            {...radio}
          />
        );
      })}
    </HStack>
  ) : (
    <VStack {...group} p={2}>
      {options.map((value) => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard
            key={value}
            evaluationType={type}
            value={value}
            disabled={disabled}
            {...radio}
          />
        );
      })}
    </VStack>
  );
};

function RadioCard(
  props: {
    value: string;
    disabled?: boolean;
    evaluationType: StatusToggleProps["type"];
  } & RadioProps,
) {
  const { getInputProps, getRadioProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getRadioProps();
  return (
    <Box as="label">
      <input {...input} />
      <EvaluationButton
        checkbox={checkbox}
        value={props.value}
        evaluationType={props.evaluationType}
        disabled={props.disabled}
      />
    </Box>
  );
}

export const EvaluationButton = (props: {
  value: string;
  checkbox?: object;
  disabled?: boolean;
  evaluationType: StatusToggleProps["type"];
}) => {
  const { t } = useTranslation();
  return (
    <Flex
      {...(props.checkbox ?? { "data-checked": true })}
      cursor={props.checkbox && !props.disabled ? "pointer" : "default"}
      borderWidth="1px"
      borderRadius="5px"
      _checked={{
        bg: Array.isArray(STATES_MAP[props.evaluationType][props.value])
          ? STATES_MAP[props.evaluationType][props.value][0]
          : STATES_MAP[props.evaluationType][props.value],
        color: Array.isArray(STATES_MAP[props.evaluationType][props.value])
          ? STATES_MAP[props.evaluationType][props.value][1]
          : "white",
      }}
      _focus={{
        boxShadow: "outline",
      }}
      justifyContent="center"
      textAlign="center"
      alignItems="center"
      fontWeight={500}
      lineHeight="16px"
      fontSize="14px"
      width="194px"
      height="48px"
    >
      {t(`status.${props.evaluationType}.${props.value}`)}
    </Flex>
  );
};

export default StatusToggle;
