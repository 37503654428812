import { FiPlus, FiSend } from "react-icons/fi";
import CreateUserView from "./CreateUserView";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Role from "../../../../domain/entities/role";
import UserStateTag from "./Components/UserStateTag";
import { COLORS } from "../../../assets/theme/colors";
import { ConfirmAlert } from "../../Common/ConfirmAlert";
import ContentLayout from "../../../layout/ContentLayout";
import ActionBar from "../../../components/Common/ActionBar";
import User, { UserState } from "../../../../domain/entities/user";
import ActionBarItem from "../../../components/Common/ActionBarItem";
import useUsersViewModel from "../../../hooks/Users/useUsersViewModel";
import DeleteButton from "../../../components/Common/table/DeleteButton";
import { GetUsersFilter } from "../../../../domain/repositories/filters";
import {
  Box,
  Flex,
  HStack,
  Select,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import TableColumnHeader from "../../../components/Common/table/TableColumnHeader";
import { SortDirection } from "../../../../domain/entities/interfaces/paginatedResults";
import ColumnFilterComponent from "../../../components/Common/table/ColumnFilterComponent";
import { BsPersonCircle } from "react-icons/bs";
import SimpleTable from "../../../components/Common/table/SimpleTable";
import { Permission } from "../../../components/Permissions/Permissions";
import RenderIf, {
  useHasPermissions,
} from "../../../components/Permissions/RenderIf";
import ActionButton from "../../../components/Common/table/ActionButton";
import { Alert } from "../../Common/Alert";
import { formatDateBasedOnLanguage } from "../../../../utils";
import TextInput from "../../../components/Common/TextInput";
import SearchInput from "../../Common/SearchInput";

const UsersView = () => {
  // Filters.
  const [filters, setFilters] = useState<GetUsersFilter>({});

  // Hooks.
  const navigate = useNavigate();
  const { t } = useTranslation("settings");
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const {
    users,
    isLoading,
    createUser,
    deleteUser,
    renewInvitation,
    messageInvitation,
    setMessageInvitation,
    userAlreadyInvited,
    setUserAlreadyInvited,
    setSearch,
  } = useUsersViewModel(filters);

  // Local state.
  const [deleteTarget, setDeleteTarget] = useState<User | undefined>();
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showAskConfirmModal, setShowAskConfirmModal] = useState<boolean>();
  const [invitationToken, setInvitationToken] = useState<string>();

  // Helper functions.
  const cancelCreate = () => setShowCreate(false);
  const onCreate = (name: string, email: string, role: Role) => {
    createUser(name, email, role, cancelCreate);
  };

  const cancelDelete = () => setDeleteTarget(undefined);
  const askDelete = (user: User) => setDeleteTarget(user);
  const onDelete = () => deleteUser(deleteTarget.id, cancelDelete);

  const canDeleteAndEdit = useHasPermissions([Permission.Settings_EditUsers]);

  const formatLastLogin = (source: string | Date): string => {
    if (!source) {
      return "-";
    }

    if (typeof source === "string") {
      return formatDateBasedOnLanguage(source, true);
    }

    return formatDateBasedOnLanguage(source, true);
  };

  const sortDirection = (
    field: "name" | "email" | "state" | "lastLogin",
  ): SortDirection => {
    if (field !== filters.sortField) {
      return null;
    }

    return filters?.sortDirection;
  };

  const updateSort = (
    field: "name" | "email" | "state" | "lastLogin",
    direction: SortDirection,
  ) => {
    setFilters({ ...filters, sortField: field, sortDirection: direction });
  };

  const updateFilterState = (e: ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.value) {
      case UserState.BLOCKED:
        setFilters({ ...filters, state: UserState.BLOCKED });
        break;
      case UserState.ENABLED:
        setFilters({ ...filters, state: UserState.ENABLED });
        break;
      case UserState.INVITED:
        setFilters({ ...filters, state: UserState.INVITED });
        break;
      default:
        setFilters({ ...filters, state: undefined });
        break;
    }
  };

  const updateLastLoginFilter = (range: [Date?, Date?]) => {
    setFilters({ ...filters, fromDate: range[0], toDate: range[1] });
  };

  return (
    <>
      <ContentLayout
        action={
          <RenderIf permissions={[Permission.Settings_EditUsers]}>
            <ActionBar>
              <ActionBarItem
                onClick={() => setShowCreate(true)}
                icon={FiPlus}
                description={t("users.add")}
              />
            </ActionBar>
          </RenderIf>
        }
      >
        <Flex
          flex={1}
          h="100%"
          width={isMobile ? "900px" : "100%"}
          padding={10}
          textAlign="center"
          flexDirection="column"
          alignItems="start"
          justifyContent="start"
        >
          <Flex justifyContent={"space-between"} alignItems="center" w={"100%"}>
            <Box textAlign={"start"}>
              <Text
                textColor={COLORS.sikuroBlue}
                fontSize={20}
                fontWeight={"bold"}
              >
                {t("users.title")}
              </Text>
              <Text fontSize={16} color="black" fontWeight={"normal"}>
                {t("users.subtitle")}
              </Text>
            </Box>
            <SearchInput onSearch={setSearch} />
          </Flex>
          {
            // No user available.
            users?.length <= 0 && !isLoading && (
              <Text marginBottom={4} fontWeight="bold">
                {t("users.noUserFound")}
              </Text>
            )
          }
          <Flex
            flexDirection={"column"}
            alignItems={"start"}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
            width="100%"
            position="relative"
            overflow={"hidden"}
            marginTop={3}
          >
            <SimpleTable
              tableId="users-table"
              emptyText={t("noUsers", { ns: "settings" })}
              showEmptyText={users?.length === 0}
              isLoading={isLoading}
            >
              <Thead bg={"gray.300"}>
                <Tr>
                  <Th>
                    <TableColumnHeader
                      text={t("users.name")}
                      sort={{
                        direction: sortDirection("name"),
                        handler: (d) => updateSort("name", d),
                      }}
                      filter={{
                        isActive: !!filters["name"],
                        component: (
                          <TextInput
                            value={filters["name"] as string}
                            onChange={(value) =>
                              setFilters({
                                ...filters,
                                name: value,
                              })
                            }
                            placeholder={t("search", { ns: "common" })}
                          />
                        ),
                      }}
                    />
                  </Th>
                  <Th>
                    <TableColumnHeader
                      text={t("users.email")}
                      sort={{
                        direction: sortDirection("email"),
                        handler: (d) => updateSort("email", d),
                      }}
                    />
                  </Th>
                  <Th>
                    <TableColumnHeader
                      text={t("users.state")}
                      filter={{
                        component: (
                          <Select
                            value={filters?.state}
                            onChange={updateFilterState}
                          >
                            <option value={undefined}>
                              {t("users.allStates")}
                            </option>
                            <option value={UserState.BLOCKED}>
                              {t("users.blocked")}
                            </option>
                            <option value={UserState.ENABLED}>
                              {t("users.enabled")}
                            </option>
                            <option value={UserState.INVITED}>
                              {t("users.invited")}
                            </option>
                          </Select>
                        ),
                        isActive:
                          filters?.state !== undefined &&
                          filters?.state !== null,
                      }}
                    />
                  </Th>
                  <Th colSpan={2}>
                    <TableColumnHeader
                      text={t("users.lastLogin")}
                      sort={{
                        direction: sortDirection("lastLogin"),
                        handler: (d) => updateSort("lastLogin", d),
                      }}
                      filter={{
                        component: (
                          <ColumnFilterComponent
                            type="date-range"
                            value={[filters?.fromDate, filters.toDate]}
                            updateFilter={(range) =>
                              updateLastLoginFilter(range as [Date?, Date?])
                            }
                          />
                        ),
                        isActive:
                          (filters?.fromDate !== null &&
                            filters?.fromDate !== undefined) ||
                          (filters?.toDate !== null &&
                            filters?.toDate !== undefined),
                      }}
                    />
                  </Th>
                  <Th w={20} />
                </Tr>
              </Thead>

              <Tbody>
                {users &&
                  users?.map((user) => (
                    <Tr
                      key={user.id}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        canDeleteAndEdit &&
                          navigate(`/settings/users/${user.id}`);
                      }}
                    >
                      <Td>
                        <HStack gap={2}>
                          {user?.isCurrentUser && (
                            <div>
                              <BsPersonCircle />
                            </div>
                          )}
                          <div>{user.name}</div>
                        </HStack>
                      </Td>
                      <Td>{user.email}</Td>
                      <Td>
                        <UserStateTag state={user.state} />
                      </Td>
                      <Td>{formatLastLogin(user.lastLogin)}</Td>
                      <Td isNumeric>
                        {user.invitationToken && (
                          <Tooltip
                            label={t("renewInvitation")}
                            aria-label={t("renewInvitation")}
                            placement="bottom"
                          >
                            <span>
                              <ActionButton
                                aria-label="send-reminder"
                                icon={<FiSend />}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setInvitationToken(user.invitationToken),
                                    setShowAskConfirmModal(true);
                                }}
                              />
                            </span>
                          </Tooltip>
                        )}
                        {canDeleteAndEdit &&
                          !(user?.role?.name === "owner") &&
                          !user.isCurrentUser && (
                            <DeleteButton
                              aria-label="delete-role"
                              onClick={(e) => {
                                e.stopPropagation();
                                askDelete(user);
                              }}
                            />
                          )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </SimpleTable>
          </Flex>
        </Flex>
      </ContentLayout>

      {
        // Upsert modal view.
        showCreate && (
          <CreateUserView onCancel={cancelCreate} onCreate={onCreate} />
        )
      }

      {userAlreadyInvited && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t("userAlreadyOnTheList", { ns: "errors" })}
          variant="info"
          onClose={() => {
            setUserAlreadyInvited(false);
          }}
        />
      )}

      {messageInvitation && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={
            messageInvitation === "success"
              ? t("renewalInvitationSuccess")
              : t(messageInvitation, { ns: "errors" })
          }
          variant="info"
          onClose={() => {
            setMessageInvitation(undefined);
          }}
        />
      )}

      {showAskConfirmModal && (
        <ConfirmAlert
          onCancel={() => setShowAskConfirmModal(false)}
          onConfirm={() => {
            renewInvitation(invitationToken), setShowAskConfirmModal(false);
          }}
          title={t("warning", { ns: "common" })}
          variant={"question"}
          message={t("users.reminderUser", { ns: "settings" })}
        />
      )}

      {
        // Confirm delete user alert.
        deleteTarget && (
          <ConfirmAlert
            variant="warning"
            onConfirm={onDelete}
            onCancel={cancelDelete}
            title={t("warning", { ns: "common" })}
            message={t("users.confirmDeleteUser?", {
              name: deleteTarget?.name,
              email: deleteTarget?.email,
            })}
          />
        )
      }
    </>
  );
};

export default UsersView;
