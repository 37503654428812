import { useEffect, useState } from "react";
import Typology from "../../../domain/entities/typology";
import { DocumentPresetTarget } from "../../../domain/entities/documentPresetTarget.enum";
import DocumentsPresetsListViewModel from "../../viewmodels/documentsPresets/DocumentsPresetsListViewModel";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import documentType from "../../../domain/entities/documentType";
import DocumentPreset from "../../../domain/entities/documentPreset";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import useAddDocumentType from "../Document/useAddDocumentType";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import Tag from "../../../domain/entities/tag";

const useDocumentsPresetsList = (target: DocumentPresetTarget) => {
  const { companyId } = useAuth();
  const queryClient = useQueryClient();
  const viewModel = new DocumentsPresetsListViewModel(target);
  const [search, setSearch] = useState<string>("");
  const [filteredTags, setFilteredTags] = useState<string[]>([]);
  const [typologiesFilter, setTypologiesFilter] = useState<Array<Typology>>([]);
  const [renameTypologyError, setRenameTypologyError] = useState<string>("");
  const [presetDocumentByTypology, setPresetDocumentByTypology] = useState(false);
  const [typologyId, setTypologyId] = useState<string>()
  const [oldTarget, setOldTarget] = useState<DocumentPresetTarget>()

  useEffect(() => {
    if (typologyId) {
      setPresetDocumentByTypology(true)
    }
  }, [typologyId]);

  useEffect(() => {
    if(target !== oldTarget ) {
      setTypologiesFilter([])
    }
    setOldTarget(target)
  }, [target])


  const {
    data: typologies,
    isLoading: typologiesLoading,
    isFetching: typologiesFetching,
    error: typologiesError,
    refetch: refetchTypologies,
  } = useQuery(
    ["typologies", companyId, target],
    async () =>
      await viewModel.listTypologies(
        companyId,
        target as unknown as DocumentTypeResourceType,
      ),
  );

  const {
    data: presets,
    isLoading: presetsLoading,
    isFetching: presetsFetching,
    error: presetsError,
    refetch: refetchPresets,
  } = useQuery(
    ["presets", companyId, target, typologiesFilter],
    async () =>
      await viewModel.listPresets(companyId, target, typologiesFilter),
    {
      enabled: !!typologies,
    },
  );

  const {
    data: presetsByTypology,
    isLoading: presetsByTypologyLoading,
    isFetching: presetsByTypologyFetching,
    refetch: refetchPresetsByTypology,
  } = useQuery<DocumentPreset[], Error>(
    ['presetsByTypology', companyId, target, typologyId],
    async () => await viewModel.getPresetsByTypology(companyId, target, typologyId),
    { retry: false, enabled: presetDocumentByTypology && typologyId !== undefined  }
  );

  const movePresetMutation = useMutation(
    async (presets: DocumentPreset[]) =>
      await viewModel.updateDocumentPreset(companyId, presets, target),
    {
      onMutate: async (presets: DocumentPreset[]) => {
        await queryClient.cancelQueries([
          "presets",
          companyId,
          target,
          typologiesFilter,
        ]);
        const previousPresets = queryClient.getQueryData([
          "presets",
          companyId,
          target,
          typologiesFilter,
        ]) as DocumentPreset[];
        // Clone the previousPresets list
        //const updatedPresets = [...previousPresets];
        // // Find the old preset to be updated
        // const oldPreset = updatedPresets.find(preset => preset.id === newPreset.id);
        // // Update the preset
        // updatedPresets = updatedPresets.map(preset => preset.id === newPreset.id ? newPreset : preset);
        // // Reorder presets based on the order update logic in the MirageJS code
        // updatedPresets.forEach(preset => {
        //   if (preset.id !== newPreset.id) {
        //     if (newPreset.order < oldPreset.order && preset.order >= newPreset.order && preset.order < oldPreset.order) {
        //       preset.order = preset.order + 1;
        //     } else if (newPreset.order > oldPreset.order && preset.order <= newPreset.order && preset.order > oldPreset.order) {
        //       preset.order = preset.order - 1;
        //     }
        //   }
        // });
        // // Sort the presets by order
        // updatedPresets.sort((a, b) => a.order - b.order);
        // // Optimistically update the cache with the reordered list
        //queryClient.setQueryData(['presets', companyId, target, typologiesFilter], updatedPresets);

        return { presets };
      },
      onError: (err, newPreset, context) => {
        queryClient.setQueryData(
          ["presets", companyId, target, typologiesFilter],
          queryClient.getQueryData([
            "presets",
            companyId,
            target,
            typologiesFilter,
          ]) as DocumentPreset[],
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          "presets",
          companyId,
          target,
          typologiesFilter,
        ]);
      },
    },
  );

  const linkTypologyToPresetMutation = useMutation(
    async ({ typology, preset }: { typology: Tag; preset: DocumentPreset }) =>
      await viewModel.addTypologyToPreset(
        companyId,
        preset.id,
        typology,
        target,
      ),
    {
      onSuccess: async () => {
        await refetchPresets();
        await refetchTypologies();
      },
    },
  );

  const unlinkTypologyFromPresetMutation = useMutation(
    async ({
      typology,
      preset,
    }: {
      typology: Typology;
      preset: DocumentPreset;
    }) => await viewModel.removeTypologyFromPreset(companyId, preset, typology),
    {
      onSuccess: async () => {
        await refetchPresets();
        await refetchTypologies();
      },
    },
  );

  const movePresetsByTypologyMutation = useMutation(
    async (documentPresents: DocumentPreset[]) => {
      return viewModel.updatePresetsByTypologyOrder(companyId, typologyId, target, documentPresents);
    },
    {
      onMutate: async (newDocumentPresets: DocumentPreset[]) => {
        await queryClient.cancelQueries(['presetsByTypology', companyId, typologyId, target]);
        return;
      },
      onError: (err, newRequirement, context) => {
        queryClient.setQueryData(
          ['presetsByTypology',companyId, typologyId, target],
          queryClient.getQueryData(['presetsByTypology',companyId, typologyId, target]) as DocumentPreset[]
        );
      },
      onSuccess: () => refetchPresetsByTypology(),
    }
  );

  const setDocumentTypesAsPresetMutation = useMutation(
    async (documentTypes: documentType[]) => {
      for (const typeD of documentTypes) {
        await viewModel.setDocumentTypeAsPreset(companyId, typeD, target);
      }
    },
    {
      onSuccess: async () => await refetchPresets(),
    },
  );

  const unsetDocumentTypeAsPresetMutation = useMutation(
    async (presetId: string) =>
      await viewModel.unsetDocumentTypeAsPreset(companyId, presetId),
    {
      onSuccess: async () => await refetchPresets(),
    },
  );

  const updateTypologyMutation = useMutation(
    async (tag: Tag) => {
      return await viewModel.updateTypologyToPreset(companyId, tag);
    },
    {
      onSuccess: async () => {
        await refetchTypologies(), await refetchPresets();
      },
      onError: async (e) => {
        await refetchTypologies(), await refetchPresets();
        console.error(e);
      },
    },
  );

  const updateTypologyToPreset = async (tag: Tag) => {
    try {
      await updateTypologyMutation.mutateAsync(tag);
    } catch (err) {
      setRenameTypologyError("existingTypology");
    }
  };

  const clearError = async () => {
    if (typologiesError) await refetchPresets();
    if (presetsError) await refetchPresets();
  };



  const error = typologiesError?.toString() || presetsError?.toString();
  const isLoading = typologiesLoading;
  const isTagLoading =
    typologiesFetching ||
    presetsFetching ||
    movePresetMutation.isLoading ||
    linkTypologyToPresetMutation.isLoading ||
    unlinkTypologyFromPresetMutation.isLoading ||
    setDocumentTypesAsPresetMutation.isLoading ||
    unsetDocumentTypeAsPresetMutation.isLoading ||
    updateTypologyMutation.isLoading;

  return {
    isLoading,
    isTagLoading,
    error,
    typologies,
    presets,
    typologiesFilter,
    setTypologiesFilter,
    clearError,
    movePreset: movePresetMutation.mutate,
    linkTypologyToPreset: linkTypologyToPresetMutation.mutate,
    unlinkTypologyFromPreset: unlinkTypologyFromPresetMutation.mutateAsync,
    unlinkTypologyFromPresetIsLoading:
      unlinkTypologyFromPresetMutation.isLoading,
    setDocumentTypesAsPreset: setDocumentTypesAsPresetMutation.mutateAsync,
    creatingPresets: setDocumentTypesAsPresetMutation.isLoading,
    unsetDocumentTypeAsPreset: unsetDocumentTypeAsPresetMutation.mutateAsync,
    unsetDocumentTypeAsPresetIsLoading:
      unsetDocumentTypeAsPresetMutation.isLoading,
    filteredTags,
    setFilteredTags,
    search,
    setSearch,
    updateTypologyToPreset,
    renameTypologyError,
    presetDocumentByTypology,
    presetsByTypology,
    presetsByTypologyLoading,
    presetsByTypologyFetching,
    refetchPresetsByTypology,
    setPresetDocumentByTypology,
    setTypologyId,
    typologyId,
    presetsLoading: presetsLoading || presetsFetching,
    movePresetsByTypology: movePresetsByTypologyMutation.mutateAsync,
    documentTypesProps: useAddDocumentType(
      viewModel,
      target as unknown as DocumentTypeCategory,
    ),
  };
};

export { useDocumentsPresetsList };
