import {
	makeAddFileToDocument,
	makeAddFileToResourceDocument,
	makeConfirmAiTask,
	makeCreateAiTask,
	makeDeleteAiTask,
	makeDeleteFileFromDocument,
	makeDeleteResourceDocument,
	makeGetAiTaskDetail,
	makeGetAiTasks,
	makeGetAiTaskSites,
	makeGetDocumentFiles,
	makeGetDocumentFilesLog,
	makeGetDocumentLog,
	makeUpdateDocument,
	makeUpdateFile,
} from '../../../core/app/factory/documents/usecases';
import { makeGetDocumentTypesForAi } from '../../../core/app/factory/documentTypes/usecases';
import { makeGetDocumentTypes } from '../../../core/app/factory/requirements/useCases';
import { makeGetTags } from '../../../core/app/factory/tags/usecases';
import { makeGetResourceTypologiesAssociated } from '../../../core/app/factory/typologies/usecases';
import AiTask from '../../../domain/entities/aiTask';
import AiTaskDocument from '../../../domain/entities/aiTaskDocument';
import { AiTaskSectionCode } from '../../../domain/entities/aiTaskSectionCode';
import Document from '../../../domain/entities/document';
import { DocumentActionLogEntry, DocumentFileLogEntry } from '../../../domain/entities/documentLogEntry';
import DocumentType from '../../../domain/entities/documentType';
import { DocumentTypeCategory } from '../../../domain/entities/documentTypeCategory.enum';
import DocumentTypeWithPublic from '../../../domain/entities/documentTypeWithPublic';
import { default as File, default as FileEntity } from '../../../domain/entities/file';
import { SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import { ResourceDocumentEvaluationState } from '../../../domain/entities/resourceDocumentEvaluationState.enum';
import Site, { DocumentSites } from '../../../domain/entities/site';
import Tag from '../../../domain/entities/tag';
import Typology from '../../../domain/entities/typology';
import { GetDocumentFilesFilter, GetDocumentLogFilter, GetDocumentsFilter } from '../../../domain/repositories/documentRepository';
import { GetSitesFilter } from '../../../domain/repositories/siteRepository';
import { DocumentTypeResourceType } from '../../../mock/models/document-type';
import { AddDocumentTypeViewModel } from '../../hooks/Document/useAddDocumentType';
import { DocumentAIViewModel } from '../../hooks/Document/useDocumentAI';
import { DocumentInfoViewModel } from '../../hooks/Document/useDocumentInfo';
import { DocumentsViewModel } from '../../hooks/Document/useDocuments';

abstract class ResourceDocumentsViewModel implements AddDocumentTypeViewModel, DocumentInfoViewModel, DocumentsViewModel, DocumentAIViewModel {
	private deleteResourceDocumentUseCase = makeDeleteResourceDocument();
	private updateDocumentUseCase = makeUpdateDocument();

	constructor(private resourceId: string, private target: DocumentTypeCategory) {}

	abstract getDocuments(companyId: string, filter?: GetDocumentsFilter, sort?: SortMeta, pageParam?: number): Promise<Document[]>;
	abstract createDocument(companyId: string, documents: string[]): Promise<void>;
	abstract createPropagableDocuments(companyId: string, documents: DocumentTypeWithPublic[]): Promise<void>;
	abstract evaluateDocument(
		companyId: string,
		documentId: string,
		evaluationState?: ResourceDocumentEvaluationState,
		expirationDate?: Date,
		noEvaluationExpiration?: boolean,
		siteIds?: string[]
	): Promise<void>;
	abstract addTypologyToResource(companyId: string, resourceId: string, typologyId: string): Promise<void>;
	abstract removeTypologyFromResource(companyId: string, resourceId: string, typologyId: string): Promise<void>;
	abstract getResource(companyId: string, resourceId: string);
	abstract getTypologies(companyId: string);

	async getDocumentFiles(companyId: string, documentId: string, filter?: GetDocumentFilesFilter, sort?: SortMeta): Promise<File[]> {
		return await makeGetDocumentFiles().Call({ companyId, documentId, filter, sort });
	}

	async deleteDocumentFile(
		companyId: string,
		documentId: string,
		fileId: string,
		siteId?: string,
		target?: string,
		resourceId?: string,
		siteIds?: string[],
		selectAll?: boolean,
		filters?: GetSitesFilter,
	): Promise<void> {
		await makeDeleteFileFromDocument().Call({ companyId, documentId, fileId, siteIds, target, resourceId, selectAll, filters });
	}

	async addFileToDocument(
		companyId: string,
		documentId: string,
		file: FileEntity,
		siteId?: string,
		target?: string,
		resourceId?: string,
		siteIds?: string[],
		toSupplier?: boolean,
		selectAll?: boolean,
		filters?: GetSitesFilter
	): Promise<void> {
		await makeAddFileToDocument().Call({
			companyId,
			documentId,
			file,
			siteId,
			target,
			resourceId,
			siteIds,
			toSupplier,
			selectAll,
			filters,
		});
	}
	async addFileToResourceDocument(
		companyId: string,
		documentId: string,
		file: File,
		siteIds?: string[],
		target?: string,
		resourceId?: string,
		selectAll?: boolean,
		filters?: GetSitesFilter
	): Promise<void> {
		await makeAddFileToResourceDocument().Call({
			companyId,
			documentId,
			file,
			siteIds,
			target,
			resourceId,
			selectAll,
			filters,
		});
	}

	async getDocumentLogs(
		companyId: string,
		documentId: string,
		filter?: GetDocumentLogFilter,
		sort?: SortMeta,
		resourceId?: string,
		siteId?: string,
		target?: string,
		pageParam?: number
	): Promise<DocumentFileLogEntry[]> {
		return await makeGetDocumentLog().Call({ companyId, documentId, filter, sort, resourceId, siteId, target, pageParam });
	}

	async getDocumentFilesLogs(
		companyId: string,
		documentId: string,
		filter?: GetDocumentLogFilter,
		sort?: SortMeta,
		pageParam?: number
	): Promise<DocumentActionLogEntry[]> {
		return await makeGetDocumentFilesLog().Call({ companyId, documentId, filter, sort, pageParam });
	}

	async deleteDocument(companyId: string, documentId: string): Promise<Document[]> {
		return await this.deleteResourceDocumentUseCase.Call({ companyId, documentId, resourceId: this.resourceId, target: this.target });
	}

	async updateDocument(companyId: string, document: Document, siteIds?: string[]): Promise<void> {
		return await this.updateDocumentUseCase.Call({ companyId, document, resourceId: this.resourceId, target: this.target, siteIds });
	}

	async getTags(companyId: string, search?: string): Promise<Tag[]> {
		return await makeGetTags().Call({ companyId, search });
	}

	async getDocumentTypes(companyId: string, search?: string, tags?: string[], sort?: SortMeta, pageParam?: number): Promise<DocumentType[]> {
		return await makeGetDocumentTypes().Call({
			companyId,
			filter: this.target as unknown as DocumentTypeCategory,
			includePublic: true,
			search,
			tags,
			sort,
			pageParam,
		});
	}

	async getDocumentTypesForAi(companyId: string, aiTaskId: string, resourceType: DocumentTypeCategory): Promise<DocumentType[]> {
		return await makeGetDocumentTypesForAi().Call({
			companyId,
			aiTaskId,
			resourceType,
		});
	}

	async getTypologiesAssociated(companyId: string, resourceId: string, type: DocumentTypeCategory): Promise<Typology> {
		return await makeGetResourceTypologiesAssociated().Call({ companyId, resourceId, type });
	}

	async updateFile(
		companyId: string,
		documentId: string,
		fileId: string,
		updatedFiles: Partial<FileEntity>[],
		siteIds?: string[],
		target?: string,
		resourceId?: string,
		selectAll?: boolean,
		filters?: GetSitesFilter
	): Promise<void> {
		return await makeUpdateFile().Call({ companyId, documentId, updatedFiles, fileId, siteIds, target, resourceId, selectAll, filters });
	}

	getDocumentSites(companyId: string, documentId: string): Promise<DocumentSites> {
		throw new Error('Method not implemented.');
	}

	// AI section fuctions

	async createAiTask(
		companyId: string,
		files: FileEntity,
		sectionCode: AiTaskSectionCode,
		resourceId: string,
		type: DocumentTypeCategory,
		siteId?: string,
		isSupplier?: boolean,
		requirementSource?: string
	): Promise<string> {
		return await makeCreateAiTask().Call({ companyId, files, sectionCode, resourceId, resourceType: type as unknown as DocumentTypeResourceType, siteId, isSupplier, requirementSource });
	}

	async getAiTasks(
		companyId: string,
		sectionCode: AiTaskSectionCode,
		resourceType?: DocumentTypeCategory,
		resourceId?: string,
		siteId?: string,
		supplierId?: string,
		requirementSource?: string
	): Promise<AiTask[]> {
		return await makeGetAiTasks().Call({ companyId, sectionCode, resourceType, resourceId, siteId, supplierId, requirementSource });
	}

	async getAiTaskDetail(companyId: string, taskId: string): Promise<AiTask> {
		return await makeGetAiTaskDetail().Call({ companyId, taskId });
	}

	async deleteAiTask(companyId: string, taskId: string): Promise<void> {
		return await makeDeleteAiTask().Call({ companyId, taskId });
	}

	async sendTaskConfirmation(companyId: string, taskId: string, aiTaskDocuments: AiTaskDocument[], siteIds: string[]): Promise<void> {
		return await makeConfirmAiTask().Call({ companyId, taskId, aiTaskDocuments, siteIds });
	}

	async getAiTaskSites(
		companyId: string,
		sectionCode: AiTaskSectionCode,
		taskId: string,
		sortSites: SortMeta,
		filterSites: GetSitesFilter,
		pageParam?: number
	) {
		return await makeGetAiTaskSites().Call({ companyId, sectionCode, taskId, sortSites, filterSites, pageParam });
	}
}

export { ResourceDocumentsViewModel };
