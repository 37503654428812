import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  SortDirection,
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import { ResourceDocumentEvaluationState } from "../../../domain/entities/resourceDocumentEvaluationState.enum";
import { useAuth } from "../../providers/Auth0JWTProvider";
import SupplierDetailViewModel from "../../viewmodels/suppliers/SupplierDetailViewModel";
import useDocumentInfo from "../Document/useDocumentInfo";
import CreateDocumentSupplierParams from "./createSupplierDocumentParameters";
import UpdateSupplierDocumentParams from "./updateSupplierDocumentParam";
import { updateFilterWithDelete } from "../../../utils";
import Document from "../../../domain/entities/document";
import StaffViewModel from "../../viewmodels/staff/StaffViewModel";
import Staff from "../../../domain/entities/staff";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";

export type EvaluateSupplierDocumentParams = {
  documentId: string;
  evaluationState: ResourceDocumentEvaluationState;
  expirationDate?: Date;
  noEvaluationExpiration?: boolean;
  siteIds?: string[];
  selectAll?: boolean;
  filters?: GetSitesFilter;
};

const useSupplierDetailModel = () => {
  const { companyId } = useAuth();
  const { supplierId } = useParams();

  const vm = new SupplierDetailViewModel(companyId, supplierId);
  const staffViewModel = new StaffViewModel();

  const [document, setDocument] = useState<string | null>(null);
  const [filterDocuments, setFilterDocuments] = useState({});
  const [filterDocumentEvaluations, setFilterDocumentEvaluations] = useState(
    {}
  );
  const [sortDocuments, setSortDocuments] = useState<SortMeta>();
  const [sortDocumentEvaluations, setSortDocumentEvaluations] = useState({});

  const getSupplierQuery = useQuery(
    ["company-supplier-detail", companyId, supplierId],
    () => vm.getSupplier(),
    {
      enabled: !!(companyId && supplierId),
      retry: false,
    }
  );

  const getDocumentsQuery = useInfiniteQuery<Document[], Error>(
    [
      "get-company-supplier-company-documents",
      companyId,
      supplierId,
      filterDocuments,
      sortDocuments,
    ],
    async ({ pageParam = 1 }) => {
      return await vm.getSupplierDocuments(
        filterDocuments,
        sortDocuments,
        pageParam
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      retry: false,
    }
  );

  const { data: systemStaff } = useQuery<Staff[], Error>(
    ["system-staff-list", supplierId],
    async () => staffViewModel.getSystemStaff(supplierId),
    {
      initialData: [],
    }
  );

  const documentInfoProps = useDocumentInfo(vm, null, `company-supplier`, null);

  const getDocumentEvaluationsQuery = useQuery(
    [
      "supplier-document-evaluations",
      companyId,
      document,
      filterDocumentEvaluations,
      sortDocumentEvaluations,
    ],
    () =>
      document
        ? vm.getDocumentEvaluations(
            document,
            filterDocumentEvaluations,
            Object.keys(sortDocumentEvaluations).length > 0
              ? {
                  field: Object.keys(sortDocumentEvaluations)[0],
                  direction: Object.values(
                    sortDocumentEvaluations
                  )[0] as SortDirection,
                }
              : undefined
          )
        : [],
    {
      initialData: [],
    }
  );

  const evaluateDocumentMutation = useMutation(
    ["evaluate-company-supplier-document", companyId, supplierId],
    ({
       documentId,
       evaluationState,
       expirationDate,
       noEvaluationExpiration,
       siteIds,
       selectAll,
      filters
     }: EvaluateSupplierDocumentParams) => {
      return vm.evaluateSupplierDocument(
        documentId,
        evaluationState,
        expirationDate,
        noEvaluationExpiration,
        siteIds,
        selectAll,
        filters
      );
    },
    {
      onSuccess: () => {
        getDocumentsQuery.refetch();
        getDocumentEvaluationsQuery.refetch();
      },
    },
);

  const updateFilter =
    (setter) => (column: string, value: string | string[]) => {
      return updateFilterWithDelete(setFilterDocuments, column, value);
    };

  const updateSort = (setter) => (column: string, value: SortDirection) =>
    setter({ [column]: value });

  const getDocumentDetails = (documentId: string) => setDocument(documentId);

  const createSupplierDocumentMutation = useMutation(
    ["create-supplier-document", companyId, supplierId],
    async ({
      isPublic,
      documentTypeId,
      siteIds,
      result,
      expiresAt,
      files,
    }: CreateDocumentSupplierParams) => {
      return await vm.createSupplierDocument(
        isPublic,
        documentTypeId,
        siteIds,
        result,
        expiresAt,
        files
      );
    },
    {
      onSuccess: () => {
        getDocumentsQuery.refetch();
        getDocumentEvaluationsQuery.refetch();
      },
    }
  );

  const updateDocumentMutation = useMutation(
    ["update-supplier-document", companyId, supplierId],
    ({ document, siteIds }: UpdateSupplierDocumentParams) => {
      return vm.updateSupplierDocument(document, siteIds);
    },
    {
      onSuccess: () => {
        getDocumentsQuery.refetch();
        getDocumentEvaluationsQuery.refetch();
      },
    }
  );
  const getSupplierTagsQuery = useQuery(
    ["company-supplier-tags", companyId, supplierId],
    () => {
      return vm.getSupplierTags(companyId, supplierId);
    },
    {
      enabled: !!(companyId && supplierId),
      retry: false,
    }
  );

  const { data: staff, isLoading: staffIsFetching } = useQuery<Staff[], Error>(
    ["staff-list", companyId],
    async () => staffViewModel.getStaff(companyId),
    {
      initialData: [],
    }
  );

  const documents = getDocumentsQuery.data?.pages?.flat() ?? [];
  const tags = getSupplierTagsQuery.data;

  return {
    supplier: getSupplierQuery.data,
    documents,
    refetchDocument: getDocumentsQuery.refetch,
    tags,
    documentInfoProps,
    sortDocuments,
    setSortDocuments,
    filterDocuments,
    updateFilterDocuments: updateFilter(setFilterDocuments),
    updateFilterDocumentEvaluations: updateFilter(setFilterDocumentEvaluations),
    updateSortDocumentEvaluations: updateSort(setSortDocumentEvaluations),
    updateSortDocuments: setSortDocuments,
    filterDocumentEvaluations,
    sortDocumentEvaluations,
    getDocumentEvaluations: getDocumentDetails,
    isFetching: getDocumentsQuery.isFetching,
    documentEvaluations: getDocumentEvaluationsQuery.data,
    documentEvaluationsFetching: getDocumentEvaluationsQuery.isLoading,
    createDocument: createSupplierDocumentMutation.mutateAsync,
    createDocumentIsLoading: createSupplierDocumentMutation.isLoading,
    updateDocument: updateDocumentMutation.mutate,
    evaluateDocument: evaluateDocumentMutation.mutate,
    systemStaff,
  };
};

export { useSupplierDetailModel };
