import GetSite from '../../../../domain/interactors/site/getSite';
import GetSites from '../../../../domain/interactors/site/getSites';
import CreateSite from '../../../../domain/interactors/site/createSite';
import UpdateSite from '../../../../domain/interactors/site/updateSite';
import { makeSiteRepository } from './repositories';
import RemoveRequirementFromSite from '../../../../domain/interactors/site/removeRequirementFromSite';
import AddRequirementToSite from '../../../../domain/interactors/site/addRequirementToSite';
import GetSiteDocuments from '../../../../domain/interactors/site/getDocuments';
import CreateSiteDocument from '../../../../domain/interactors/site/createDocument';
import GetWorkerSites from '../../../../domain/interactors/site/getWorkerSites';
import GetMachineSites from '../../../../domain/interactors/site/getMachineSites';
import GetVehicleSites from '../../../../domain/interactors/site/getVehicleSites';
import UpdateSiteState from '../../../../domain/interactors/site/updateSiteState';
import GetSiteOptions from '../../../../domain/interactors/site/getSiteOptions';
import UpdateSiteOptions from '../../../../domain/interactors/site/updateSiteOptions';
import GetPendingEvaluations from '../../../../domain/interactors/site/getPendingEvaluations';
import ApprovePendingEvaluation from '../../../../domain/interactors/site/approvePendingEvaluation';
import AddRequirementToWorkingSite from '../../../../domain/interactors/site/addRequirementToWorkingSite';
import GetSitesForRoleAssignment from '../../../../domain/interactors/site/getSitesForRoleAssignment';
import GetToolSites from '../../../../domain/interactors/site/getToolSites';
import GetChemicalSites from '../../../../domain/interactors/site/getChemicalSites';
import GetSiteSupplierResourcesDocuments from '../../../../domain/interactors/site/getSiteSupplierResourcesDocuments';
import GetSiteSupplierDocuments from '../../../../domain/interactors/site/getSiteSupplierDocuments';
import GetSiteSupplierWorkers from '../../../../domain/interactors/site/getSiteSupplierWorkers';
import GetSiteSupplierMachines from '../../../../domain/interactors/site/getSiteSupplierMachines';
import GetSiteSupplierVehicles from '../../../../domain/interactors/site/getSiteSupplierVehicles';
import GetSiteSupplierTools from '../../../../domain/interactors/site/getSiteSupplierTools';
import GetSiteSupplierChemicals from '../../../../domain/interactors/site/getSiteSupplierChemicals';
import UpdateScheduledEmail from '../../../../domain/interactors/site/updateScheduleEmail';
import GetScheduleEmail from '../../../../domain/interactors/site/getScheduleEmail';
import GetResourceLinkableSites from '../../../../domain/interactors/site/getResourceLinkableSites';
import AddResourcesToSites from '../../../../domain/interactors/site/addResourcesToSites';
import GetSiteUsers from '../../../../domain/interactors/site/getSiteUsers';
import GetSiteAvailableUsers from "../../../../domain/interactors/site/getSiteAvailableUsers";
import UpsertSiteUser from "../../../../domain/interactors/site/upsertSiteUser";
import DeleteSiteUser from "../../../../domain/interactors/site/deleteSiteUser";

export const makeGetSite = () => {
	return new GetSite(makeSiteRepository());
};

export const makeGetSites = () => {
	return new GetSites(makeSiteRepository());
};

export const makeGetSitesForRoleAssignment = () => {
	return new GetSitesForRoleAssignment(makeSiteRepository());
};

export const makeUpdateSite = () => {
	return new UpdateSite(makeSiteRepository());
};

export const makeUpdateSiteState = () => {
	return new UpdateSiteState(makeSiteRepository());
};

export const makeCreateSite = () => {
	return new CreateSite(makeSiteRepository());
};

export const makeAddRequirementToSite = () => {
	return new AddRequirementToSite(makeSiteRepository());
};

export const makeAddRequirementToWorkingSite = () => {
	return new AddRequirementToWorkingSite(makeSiteRepository());
};

export const makeRemoveRequirementFromSite = () => {
	return new RemoveRequirementFromSite(makeSiteRepository());
};

export const makeGetSiteDocuments = () => {
	return new GetSiteDocuments(makeSiteRepository());
};

export const makeCreateSiteDocument = () => {
	return new CreateSiteDocument(makeSiteRepository());
};

export const makeGetWorkerSites = () => {
	return new GetWorkerSites(makeSiteRepository());
};
export const makeGetResourceLinkableSites = () => {
	return new GetResourceLinkableSites(makeSiteRepository());
};

export const makeGetMachineSites = () => {
	return new GetMachineSites(makeSiteRepository());
};

export const makeGetVehicleSites = () => {
	return new GetVehicleSites(makeSiteRepository());
};

export const makeGetToolSites = () => {
	return new GetToolSites(makeSiteRepository());
};

export const makeGetChemicalSites = () => {
	return new GetChemicalSites(makeSiteRepository());
};

export const makeGetSiteOptions = () => {
	return new GetSiteOptions(makeSiteRepository());
};

export const makeGetScheduleEmail = () => {
	return new GetScheduleEmail(makeSiteRepository());
};

export const makeGetSiteUsers = () => {
	return new GetSiteUsers(makeSiteRepository());
}
export const makeGetSiteAvailableUsers = () => {
	return new GetSiteAvailableUsers(makeSiteRepository());
}
export const makeUpdateSiteOptions = () => {
	return new UpdateSiteOptions(makeSiteRepository());
};
export const makeUpsertSiteUser = () => {
	return new UpsertSiteUser(makeSiteRepository());
};
export const makeDeleteSiteUser = () => {
	return new DeleteSiteUser(makeSiteRepository());
};

export const makeUpdateScheduledEmail = () => {
	return new UpdateScheduledEmail(makeSiteRepository());
};

export const makeGetPendingEvaluations = () => {
	return new GetPendingEvaluations(makeSiteRepository());
};

export const makeApprovePendingEvaluation = () => {
	return new ApprovePendingEvaluation(makeSiteRepository());
};

export const makeGetSiteSupplierDocuments = () => {
	return new GetSiteSupplierDocuments(makeSiteRepository());
};

export const makeGetSiteSupplierResourcesDocuments = () => {
	return new GetSiteSupplierResourcesDocuments(makeSiteRepository());
};

export const makeGetSiteSupplierWorkers = () => {
	return new GetSiteSupplierWorkers(makeSiteRepository());
};

export const makeGetSiteSupplierMachines = () => {
	return new GetSiteSupplierMachines(makeSiteRepository());
};

export const makeGetSiteSupplierVehicles = () => {
	return new GetSiteSupplierVehicles(makeSiteRepository());
};

export const makeGetSiteSupplierTools = () => {
	return new GetSiteSupplierTools(makeSiteRepository());
};

export const makeGetSiteSupplierChemicals = () => {
	return new GetSiteSupplierChemicals(makeSiteRepository());
};
export const makeGetLinkableSites = () => {
	return new GetResourceLinkableSites(makeSiteRepository());
};
export const makeAddResourcesToSites = () => {
	return new AddResourcesToSites(makeSiteRepository());
};
