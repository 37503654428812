import { FC, useEffect, useState } from "react";
import LoadingView from "../Common/LoadingView";
import { COLORS } from "../../assets/theme/colors";
import { useTranslation } from "react-i18next";
import ContentLayout from "../../layout/ContentLayout";
import ActionBar from "../../components/Common/ActionBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MdClose, MdOutlineHistory } from "react-icons/md";
import ActionBarItem from "../../components/Common/ActionBarItem";
import DetailsHeader from "../../components/Common/DetailsHeader";
import { Permission } from "../../components/Permissions/Permissions";
import { InfoBanner } from "../../components/Common/alerts/InfoBanner";
import SupplierStatus from "../../components/Views/Sites/SupplierStatus";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import SiteResourceDocuments, {
  ResourceDocumentsPermissions,
} from "../../components/Views/common/SiteResourceDocuments";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { useSiteSupplierDetailViewModel } from "../../hooks/Site/useSiteSupplierDetailViewModel";
import ResourceEvaluationHistoryModal from "../../components/Views/common/ResourceEvaluationHistoryModal";
import { EvaluationButton } from "../../components/Common/ResourceEvaluationToggle";
import {
  useSupplierOrSiteFilesDownloader,
} from "../../hooks/Document/useDocumentsFilesDownloader";

import {
  Box,
  Flex,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import RejectionSummary, {
  mapRejectionGroups,
} from "../../components/Views/Documents/RejectionSummary";
import ContactField from "../../components/Common/table/ContactField";
import SiteSupplierResourcesViewModal from "../Site/SiteSupplierResourcesViewModal";
import { ResourceLinks } from "../Site/SiteSupplierDetailView";
import RenderIf from "../../components/Permissions/RenderIf";

interface Props {
  fromList: boolean;
  permissions: SiteSupplierDetailPermissions;
  isWorkingSite?: boolean;
  isSubcontract?: boolean;
}

type SiteSupplierDetailPermissions = {
  downloadDocuments: Permission;
  showGlobalEvaluation: Permission;
  setGlobalEvaluation: Permission;
  viewRequirements: Permission;
  addRequirement: Permission;
  editTags: Permission;
  setStatus: Permission;
  showResources: Permission;
  documents: ResourceDocumentsPermissions;
};

const WorkingSiteSubcontractorDetailView: FC<Props> = ({
  fromList,
  permissions,
  isWorkingSite = true,
  isSubcontract,
}) => {
  const { siteId } = useParams();
  const { supplierId } = useParams();
  const { t } = useTranslation("supplier");
  const navigate = useNavigate();
  const location = useLocation();
  const [isTablet] = useMediaQuery("(max-width: 1300px)");
  const [showResources, setShowResources] = useState<boolean>(false);
  const downloader = useSupplierOrSiteFilesDownloader(siteId, "site");
  const [resourceType, setResourceType] = useState<DocumentTypeResourceType>();
  const [
    showResourceEvaluationHistoryModal,
    setShowResourceEvaluationHistoryModal,
  ] = useState(false);
  const [showInfoBanner, setShowInfoBanner] = useState(true);
  const [selectedTab, setSelectedTab] = useState(
    location.state?.fromPrevUrl === "site" ? 1 : 0
  );

  const {
    siteResource,
    siteResourceRefetch,
    siteSupplierResources,
    siteSupplierResourcesFetching,
    setSiteSupplierResourcesSort,
    siteSupplierResourcesSort,
    filterSiteSupplierResources,
    updateFilterSiteSupplierResources,
    siteResourceDocuments,
    siteResourceDocumentsFetchNextPage,
    siteResourceDocumentsHasNextPage,
    siteResourceDocumentsRefetch,

    siteResourceEvaluations,
    getSiteResourceEvaluations,
    getSiteResourceEvaluationsIsLoading,
    setEnableGetEvaluationsHistory,
    evaluateDocument,
    filterResourceEvaluations,
    updateFilterResourceEvaluations,
    sortResourceEvaluations,
    updateSortResourceEvaluations,

    sortCompanyDocuments,
    updateSortCompanyDocuments,
    updateFilterCompanyDocuments,
    filterCompanyDocuments,

    sortDocuments,
    updateSortDocuments,
    updateFilterDocuments,
    filterDocuments,
    documentEvaluations,
    documentEvaluationsFetching,
    filterDocumentEvaluations,
    updateFilterDocumentEvaluations,
    updateSortDocumentEvaluations,
    sortDocumentEvaluations,
    documentTypesProps,
    documentInfoProps,
    siteResourceSiteDocuments,
    siteResourceSiteDocumentsFetchNextPage,
    siteResourceSiteDocumentsHasNextPage,
    siteResourceSiteDocumentsRefetch,
    siteVariants,
    siteSpecializations,
    setSupplierDocumentType,
    setSupplierStatus,
    isCompanyDocumentsFetching,
    isSiteDocumentsFetching,
    communicationProps,
    statusIsLoading,
    systemStaff,
    setHookDocumentIdAndType
  } = useSiteSupplierDetailViewModel(siteId, supplierId);

  const resourceColumns = ["name"];
  const resourceWorkerColumns = [
    "lastName",
    "firstName",
    "fiscalCode",
    "jobTitle",
  ];

  const street = siteResource?.company?.address?.street;
  const cap = siteResource?.company?.address?.cap;
  const city = siteResource?.company?.address?.city;
  const addressString = [
    street && `${street}`,
    cap && `${cap}`,
    city && `${city}`,
  ]
    .filter(Boolean)
    .join(" - ");

  const detailAttributes = [
    "name",
    "fiscalCode",
    "vat",
    "email",
    "pec",
    "address",
    "phoneNumber",
  ].map((column) => ({
    label: t(column, { ns: "companies" }),
    value: column === "address" ? addressString : siteResource?.company[column],
  }));

  const contactAttributes = [
    "doctor",
    "rspp",
    "contact",
    "employer",
    "rls",
  ].map((role) => ({
    label: t(role, { ns: "companies" }),
    value: (
      <ContactField staff={systemStaff?.find((staff) => staff.role === role)} />
    ),
  }));

  const onTabSelect = (t) => {
    setSupplierDocumentType(
      t === 0 ? DocumentTypeCategory.COMPANY : DocumentTypeCategory.SITE
    );
    setSelectedTab(t);
  };

  useEffect(() => {
    documentTypesProps.setSupplierId(supplierId);
  }, []);

  if (!siteResource) {
    return <LoadingView />;
  }

  return (
    <ContentLayout
      action={
        <ActionBar>
          {fromList && (
            <ActionBarItem
              bgColor={COLORS.sikuroBlue}
              color="white"
              icon={MdClose}
              description={t("close", { ns: "common" })}
              onClick={() => navigate(`/sites/${siteId}/suppliers`)}
            />
          )}
        </ActionBar>
      }
    >
      {siteResource && (
        <>
          <Flex px={3} py={5} gap="20px" flexWrap={"wrap"}>
            <DetailsHeader
              isLoaded={!!siteResource.company.name}
              title={t("details", { ns: "common" })}
              shouldShowImage={true}
              image={{ url: siteResource.company.logo, disableFlag: true }}
              attributes={[...detailAttributes, ...contactAttributes]}
            />
            <VStack gap={2} flex="1 1">
              <SupplierStatus
                status={siteResource.status}
                onChange={(s) => setSupplierStatus(s)}
                permissions={permissions.setStatus}
                statusIsLoading={statusIsLoading}
              />
              <ResourceLinks
                setType={setResourceType}
                showResourcesModal={setShowResources}
              />
            </VStack>
          </Flex>
          <Flex padding={3}>
            <VStack
              rounded="md"
              borderWidth="1px"
              borderColor="gray.300"
              px={5}
              pt={3}
            >
              <Flex
                justifyContent="center"
                gap={5}
                alignItems="center"
                w="100%"
              >
                <EvaluationButton
                  evaluationType="resource"
                  value={siteResource.evaluationResult}
                  disabled={true}
                />

                <IconButton
                  fontSize="3xl"
                  backgroundColor="transparent"
                  onClick={() => {
                    setEnableGetEvaluationsHistory(true);
                    setShowResourceEvaluationHistoryModal(true);
                  }}
                  aria-label="document-history"
                >
                  <MdOutlineHistory />
                </IconButton>
              </Flex>

              <Tabs
                onChange={onTabSelect}
                width="100%"
                height="100%"
                defaultIndex={selectedTab}
                marginTop={5}
                marginBottom={5}
              >
                <TabList>
                  <Tab width="50%">
                    {t("companyDocuments", { ns: "documents" })}
                  </Tab>
                  <Tab width="50%">
                    {t("siteDocuments", { ns: "documents" })}
                  </Tab>
                </TabList>
                <TabPanels>
                  {["company", "site"].map((type: "company" | "site") => (
                    <TabPanel key={type}>
                      <Box mt={2}>
                        <RejectionSummary
                          groups={mapRejectionGroups([
                            {
                              resource: type,
                              documents:
                                type === "company"
                                  ? siteResourceDocuments
                                  : siteResourceSiteDocuments,
                            },
                          ])}
                        />
                      </Box>

                      <>
                        {showInfoBanner && (
                          <RenderIf permissions={permissions.documents.addFile ?? []}>
                            <InfoBanner
                              text={t("dragAndDropInfo", { ns: "documents" })}
                              onClose={() => setShowInfoBanner(false)}
                            />
                          </RenderIf>
                        )}
                      </>
                      <SiteResourceDocuments
                        downloader={downloader}
                        evaluateDocument={evaluateDocument}
                        siteResourceRefetch={siteResourceRefetch}
                        isSubcontract={isSubcontract}
                        {...(type === "company"
                          ? {
                            documents: siteResourceDocuments,
                            documentsRefetch: siteResourceDocumentsRefetch,
                            documentsHasNextPage:
                              siteResourceDocumentsHasNextPage,
                            documentsFetchNextPage:
                              siteResourceDocumentsFetchNextPage,
                            sortDocuments: sortCompanyDocuments,
                            filterDocuments: filterCompanyDocuments,
                            updateFilterDocuments:
                              updateFilterCompanyDocuments,
                            updateSortDocuments: updateSortCompanyDocuments,
                            isWorkingSite: isWorkingSite,
                          }
                          : {
                            documents: siteResourceSiteDocuments,
                            documentsRefetch:
                              siteResourceSiteDocumentsRefetch,
                            documentsHasNextPage:
                              siteResourceSiteDocumentsHasNextPage,
                            documentsFetchNextPage:
                              siteResourceSiteDocumentsFetchNextPage,
                            sortDocuments: sortDocuments,
                            filterDocuments: filterDocuments,
                            updateFilterDocuments: updateFilterDocuments,
                            updateSortDocuments: updateSortDocuments,
                            isWorkingSite: isWorkingSite,
                          })}
                        setSiteResourceHookDocumentId={setHookDocumentIdAndType}
                        documentEvaluations={documentEvaluations}
                        documentEvaluationsFetching={
                          documentEvaluationsFetching
                        }
                        filterDocumentEvaluations={filterDocumentEvaluations}
                        updateFilterDocumentEvaluations={
                          updateFilterDocumentEvaluations
                        }
                        sortDocumentEvaluations={sortDocumentEvaluations}
                        updateSortDocumentEvaluations={
                          updateSortDocumentEvaluations
                        }
                        {...documentInfoProps}
                        isAddingFile={
                          isCompanyDocumentsFetching || isSiteDocumentsFetching
                        }
                        isFetchingDocuments={
                          isCompanyDocumentsFetching || isSiteDocumentsFetching
                        }
                        isReadOnly={isWorkingSite || type === "company"}
                        requirementSubject={type as RequirementSubject}
                        communicationProps={communicationProps}
                        permissions={permissions.documents}
                        resourceCategory={type as DocumentTypeCategory}
                        resourceId={supplierId}
                        deltaWidth={"220px"}
                        isTablet={isTablet}
                      />
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </VStack>
          </Flex>
        </>
      )}
      {showResources && (
        <SiteSupplierResourcesViewModal
          type={resourceType}
          resourceColumns={
            resourceType === "worker" ? resourceWorkerColumns : resourceColumns
          }
          resources={siteSupplierResources}
          isWorkingSite={true}
          siteResourcesHasNextPage={null}
          siteResourcesFetchNextPage={null}
          isFetching={siteSupplierResourcesFetching}
          setShowResources={(show) => setShowResources(show)}
          setSiteSupplierResourcesSort={(sort) =>
            setSiteSupplierResourcesSort(sort)
          }
          siteSupplierResourcesSort={siteSupplierResourcesSort}
          filterSiteSupplierResources={filterSiteSupplierResources}
          updateFilterSiteSupplierResources={(
            column: string,
            value: string | string[]
          ) => updateFilterSiteSupplierResources(column, value)}
          variants={siteVariants}
          specializations={siteSpecializations}
        />
      )}
      {showResourceEvaluationHistoryModal && (
        <ResourceEvaluationHistoryModal
          onClose={() => {
            setEnableGetEvaluationsHistory(false);
            setShowResourceEvaluationHistoryModal(false);
          }}
          evaluations={siteResourceEvaluations}
          getEvaluations={getSiteResourceEvaluations}
          getEvaluationsIsLoading={getSiteResourceEvaluationsIsLoading}
          filterResourceEvaluations={filterResourceEvaluations}
          updateFilterResourceEvaluations={updateFilterResourceEvaluations}
          sortResourceEvaluations={sortResourceEvaluations}
          updateSortResourceEvaluations={updateSortResourceEvaluations}
        />
      )}
    </ContentLayout>
  );
};
export default WorkingSiteSubcontractorDetailView;
