import { injectable } from 'tsyringe';
import Document from '../../domain/entities/document';
import Machine from '../../domain/entities/machine';
import MachineRepository, { GetMachinesFilter } from '../../domain/repositories/machineRepository';
import { GetDocumentsFilter } from '../../domain/repositories/documentRepository';
import { PaginatedResults, SortMeta } from '../../domain/entities/interfaces/paginatedResults';
import { ApiService } from '../utilities/apiService';
import { dateIntervals } from '../utilities/filters';
import ImportInfo from '../../domain/entities/importInfo';
import FileEntity from '../../domain/entities/file';
import DocumentTypeWithPublic from '../../domain/entities/documentTypeWithPublic';
import { ResourceDocumentEvaluationState } from '../../domain/entities/resourceDocumentEvaluationState.enum';
import { dateToRFC3339 } from '../../utils';
import { mapApiResponseToDocument } from '../adapters/getResourceDocuments';
import { BadgeStatusSite } from '../../presentation/hooks/Badge/useBadgeDetailViewModel';
import { GetAvailableBadgesFilters, GetBadgesFilters } from '../../domain/repositories/badgeRepository';
import Badge from '../../domain/entities/badge';
import { GetSitesFilter } from "../../domain/repositories/siteRepository";

@injectable()
class ServerMachineRepository implements MachineRepository {
	constructor(private apiService: ApiService) {}

	async getMachineById(companyId: string, machineId: string): Promise<Machine | undefined> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/machines/${machineId}`,
		);
		if (!response.ok) {
			return new Promise(undefined);
		}
		return await response.json();
	}

	async getMachines(
		companyId: string,
		filter?: GetMachinesFilter,
		archived?: boolean,
		sort?: SortMeta,
		pageParam?: number,
	): Promise<PaginatedResults<Machine>> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: '25',
			...filter,
			...sort,
		});

		if (archived) {
			params.append('archived', 'true');
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/machines?${params.toString()}`,
		);
		const machines = await response.json();
		return machines;
	}

	async getResourceBadge(
		companyId: string,
		resource: string,
		resourceId: string,
		sort?: SortMeta,
		filter?: GetBadgesFilters,
		pageParam?: number,
		supplierId?: string,
	): Promise<PaginatedResults<Badge>> {
		const { tagIds, ...restFilter } = filter || {};

		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: '25',
			...{
				...restFilter,
			},
			...sort,
		});

		tagIds?.length > 0 && tagIds.forEach((tag) => params.append('tagIds[]', tag));
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/${resource}/${resourceId}/badges?${params.toString()}`,
		);
		const badges = await response.json();

		return badges;
	}

	async getBadgeSites(companyId: string, badgeId: string): Promise<BadgeStatusSite[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/badges/${badgeId}/sites`,
		);
		const badgeSites = await response.json();
		return badgeSites['results'] ?? [];
	}

	async getAvailableBadges(
		companyId: string,
		resource: string,
		resourceId: string,
		sort?: SortMeta,
		filter?: GetAvailableBadgesFilters,
	): Promise<Badge[]> {
		const { tagIds, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			...{ ...restFilter },
			...sort,
		});
		tagIds?.length > 0 && tagIds.forEach((tag) => params.append('tagIds[]', tag));
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/${resource}/${resourceId}/badges/linkable?${params.toString()}`,
		);
		const data = await response.json();
		return data.results;
	}

	async restoreMachine(companyId: string, machineId: string): Promise<void> {
		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/machines/${machineId}/restore`, {
			method: 'PUT',
		});
	}

	async getAvailableMachinesCount(companyId: string, filter?: GetMachinesFilter, sort?: SortMeta): Promise<number> {
		const params = new URLSearchParams({
			...filter,
			...sort,
		});
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/machines-count?${params.toString()}`,
		);
		const machines = await response.json();
		return machines?.results.length;
	}

	async createMachine(companyId: string, machine: Machine, photo?: File): Promise<Machine> {
		const formData = new FormData();
		const appendedParameters = new Set();

		if (photo) {
			formData.append('photo', photo);
			appendedParameters.add('photo');
		}

		Object.keys(machine).map((parameter) => {
			if (!appendedParameters.has(parameter) && parameter !== 'photo') {
				formData.append(parameter, machine[parameter]);
				appendedParameters.add(parameter);
			}
		});

		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/machines`, {
			method: 'POST',
			body: formData,
		});
		return await response.json();
	}

	async uploadMachines(companyId: string, file: FileEntity): Promise<ImportInfo> {
		const formData = new FormData();
		const appendedParameters = new Set();

		formData.append('file', file.binaries[0]);
		appendedParameters.add('file');
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/machines/import`, {
			method: 'POST',
			body: formData,
		});
		return response.ok ? await response.json() : Promise.reject(new Error('error.conflict'));
	}

	async updateMachine(companyId: string, parameters: Machine, imageFile?: File): Promise<Machine> {
		const formData = new FormData();
		const appendedParameters = new Set();

		if (imageFile) {
			formData.append('photo', imageFile);
			appendedParameters.add('photo');
		}

		Object.keys(parameters).map((parameter) => {
			parameters[parameter] = parameters[parameter] ?? '';
			if (!appendedParameters.has(parameter)) {
				formData.append(parameter, parameters[parameter]);
				appendedParameters.add(parameter);
			}
		});

		if (!imageFile) {
			formData.delete('photo');
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/machines/${parameters.id}`,
			{
				method: 'POST',
				body: formData,
			},
		);

		return await response.json();
	}

	async deleteMachine(companyId: string, machineId: string): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/machines/${machineId}`,
			{ method: 'DELETE' },
		);
		if (!response.ok) {
			throw new Error('Failed to delete machine');
		}
	}

	async evaluateMachineDocument(
		companyId: string,
		machineId: string,
		documentId: string,
		evaluationState?: ResourceDocumentEvaluationState,
		expirationDate?: Date,
		noEvaluationExpiration?: boolean,
		siteIds?: string[],
		selectAll?: boolean,
		filters?: GetSitesFilter
	): Promise<void> {
		const requestBody = {
			sitesIds: siteIds,
			selectAll,
			...(evaluationState !== null && { result: evaluationState }),
			...(expirationDate && (expirationDate !== null ? { expiresAt: dateToRFC3339(expirationDate, true) } : { expiresAt: '' })),
			noEvaluationExpiration,
		};
		for (const key in filters) {
			if (Object.prototype.hasOwnProperty.call(filters, key)) {
				requestBody[key] = filters[key];
			}
		}
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/machines/${machineId}/documents/${documentId}/evaluations`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(requestBody),
			},
		);
	}

	async getMachineDocuments(companyId: string, machineId, filter?: GetDocumentsFilter, sort?: SortMeta, pageParam?: number): Promise<Document[]> {
		const { tags, expiresAt, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			...(pageParam ? { perPage: String(25), page: pageParam.toString() } : {}),
			...restFilter,
			...dateIntervals(expiresAt),
			...sort,
		});
		tags?.length > 0 && tags.forEach((tag) => params.append('tagIds[]', tag));

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/machines/${machineId}/documents?${params.toString()}`,
		);
		const documents = await response.json();
		return documents.results ? Object.values(documents.results).map(mapApiResponseToDocument) : [];
	}

	async createMachineDocument(companyId: string, machineId: string, documents: string[]): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/machines/${machineId}/documents`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ documentTypesIds: documents }),
			},
		);
		return await response.json();
	}

	async createPropagableMachineDocument(companyId: string, machineId: string, documents: DocumentTypeWithPublic[]): Promise<void> {
		const transformedDocuments = documents.map((doc) => ({
			documentTypeId: doc.id,
			isPublic: doc.isPublic,
		}));

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/machines/${machineId}/documents`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ documents: transformedDocuments }),
			},
		);
		return await response.json();
	}

	async addTypologyToMachine(companyId: string, machineId: string, typologyId: string): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/machines/${machineId}/typologies/${typologyId}`,
			{
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
			},
		);
	}

	async removeTypologyFromMachine(companyId: string, machineId: string, typologyId: string): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/machines/${machineId}/typologies/${typologyId}`,
			{
				method: 'DELETE',
			},
		);
	}

	async linkBadgesResource(companyId: string, resource: string, resourceId: string, badgeIds: string[]): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/${resource}s/${resourceId}/badges/link`,
			{
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ badgeIds }),
			},
		);
	}

	async migrateToVehicle(companyId: string, machineId: string, typologyId: string): Promise<string> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/machines/${machineId}/migrate-to-vehicle`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ typologyId }),
			},
		);
		const { vehicleId } = await response.json();
		return vehicleId;
	}
}

export default ServerMachineRepository;
