import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingView from "../Common/LoadingView";
import { COLORS } from "../../assets/theme/colors";
import { formatDateBasedOnLanguage, formatStringToDate } from "../../../utils";
import ContentLayout from "../../layout/ContentLayout";
import ActionBar from "../../components/Common/ActionBar";
import BaseModal from "../../components/Common/alerts/BaseModal";
import ActionBarItem from "../../components/Common/ActionBarItem";
import DetailsHeader from "../../components/Common/DetailsHeader";
import { SiteToolParams } from "../../../domain/entities/siteTool";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteWorkerParams } from "../../../domain/entities/siteWorker";
import { SiteMachineParams } from "../../../domain/entities/siteMachine";
import { SiteVehicleParams } from "../../../domain/entities/siteVehicle";
import { SiteChemicalParams } from "../../../domain/entities/siteChemical";
import { Column } from "../../components/Views/Documents/GeneralDocumentsView";
import SupplierDocumentsTable, {
  SupplierDocumentsProps,
} from "../../components/Views/common/SupplierDocumentsTable";
import SupplierResourceViewModel from "../../viewmodels/suppliers/SupplierResourceViewModel";
import { ResourceEvaluationState } from "../../../domain/entities/resourceEvaluationState.enum";
import { useSupplierOrSiteFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import ResourceEvaluationHistoryModal from "../../components/Views/common/ResourceEvaluationHistoryModal";
import useSupplierResourceDetailViewModel from "../../hooks/Suppliers/useSupplierResourceDetailViewModel";
import SelectBadgesTable from "../../components/Views/Badge/SelectBadgesTable";
import { Alert } from "../Common/Alert";
import { DeleteActionAlert } from "../Common/DeleteActionAlert";
import DOMPurify from "dompurify";
import RenderIf from "../../components/Permissions/RenderIf";
import { BiLink } from "react-icons/bi";

type Props = {
  viewModel: SupplierResourceViewModel<
    | SiteWorkerParams
    | SiteMachineParams
    | SiteToolParams
    | SiteVehicleParams
    | SiteChemicalParams
  >;
  columns: string[];
  permissions: SupplierDocumentsProps["permissions"];
};

const SupplierResourceView: FC<Props> = ({
  viewModel,
  columns,
  permissions,
}) => {
  const { t } = useTranslation("supplier");
  const {
    supplierResource,
    isLoadingSupplierResource,
    evaluateSupplierResource,
    supplierResourceDocuments,
    refetchSupplierResourceDocuments,
    supplierResourcesHasNextPage,
    supplierResourcesFetchNextPage,

    supplierResourceEvaluations,
    getSupplierResourceEvaluations,
    setEnableGetEvaluationsHistory,
    filterResourceEvaluations,
    updateFilterResourceEvaluations,
    sortResourceEvaluations,
    updateSortResourceEvaluations,
    evaluateDocument,

    sortDocuments,
    updateSortDocuments,
    updateFilterDocuments,
    filterDocuments,
    getDocumentEvaluations,
    documentEvaluations,
    documentEvaluationsFetching,
    filterDocumentEvaluations,
    updateFilterDocumentEvaluations,
    updateSortDocumentEvaluations,
    sortDocumentEvaluations,
    documentInfoProps,
    createSupplierResourceDocument,
    updateSupplierResourceDocument,
    resourceBadges,
    setEnableGetBadges,
    badgeIds,
    badgeSites,
    fetchBadgeNextPage,
    hasBadgeNextPage,
    filterResourceBadge,
    isFetchingBadge,
    setBadgeIds,
    setBadgeId,
    setSortResourceBadge,
    sortResourceBadge,
    updateFilterBadge,
    tags,
    availableBadgesFilters,
    availableBadgesIsLoading,
    availableBadgesRefetch,
    availableBadgesResource,
    availableBadgesSort,
    linkBadgesToResource,
    linkBadgesToResourceIsLoading,
    badgeId,
    setFilterResourceBadge,
    setAvailableBadgesSort,
    setShowInfoSuccess,
    showInfoSuccess,
    unlinkBadgeResource,
    updateFilterAvailableBadge,
    unlinkBadgeResourceIsLoading,
    supplierResourceSites,
    setSortSupplierResourceSites,
    sortSupplierResourceSites,
    updateSupplierResourceSites,
    filterResourceSites,
    hasSites,
    setFilterResourceSites,
    supplierResourceSitesHasNextPage,
    supplierResourceSitesFetchNextPage,
    supplierResourceSitesIsFetching,
    supplierResourceSiteCount,
  } = useSupplierResourceDetailViewModel(viewModel);

  const downloader = useSupplierOrSiteFilesDownloader(
    viewModel.supplierId,
    "company",
  );

  useEffect(() => {
    if (!linkBadgesToResourceIsLoading) {
      setLinkBadgeModal(false);
    }
  }, [linkBadgesToResourceIsLoading]);

  useEffect(() => {
    if(viewModel.type === "worker" || viewModel.type === "machine" || viewModel.type === "vehicle"){
      setEnableGetBadges(true)
    }
  }, [viewModel.type]);

  const [
    showConfirmEvaluateResourceModal,
    setShowConfirmEvaluateResourceModal,
  ] = useState<false | ResourceEvaluationState>(false);
  const [
    showResourceEvaluationHistoryModal,
    setShowResourceEvaluationHistoryModal,
  ] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [linkBadgeModal, setLinkBadgeModal] = useState(false);

  const columnsTable: Column[] = [
    { field: "name", filterType: "text" as const, label: t("name") },
    {
      field: "expiresAt",
      filterType: "date-range" as const,
      label: t("expireDate", { ns: "documents" }),
    },
    { field: "tags", filterType: "tags" as const, label: t("tags") },
  ];

  const getDetailValue = (column: string) => {
    if (["dateOfBirth", "dateOfEmployment"].includes(column))
      return formatDateBasedOnLanguage(supplierResource[column]);
    return supplierResource[column];
  };

  const getResourceName = () => {
    if (supplierResource && columns.includes("firstName")) {
      return `${supplierResource["firstName"]} ${supplierResource["lastName"]}`;
    } else if (supplierResource && columns.includes("name")) {
      return supplierResource["name"];
    } else {
      return "";
    }
  };

  const sanitizedHtmlContent = DOMPurify.sanitize(
    t("linkToBadges", { ns: "badges", resource: getResourceName() }),
  );
  const sanitizedHtmlContentUnlinkBadges = DOMPurify.sanitize(
    t("unlinkResourceFromBadge", { ns: "badges", resource: getResourceName() }),
  );

  const navigate = useNavigate();

  if (!supplierResource) {
    // SupplierResource data not yet available.
    return <LoadingView />;
  }
  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            bgColor={COLORS.sikuroBlue}
            color="white"
            icon={MdClose}
            description={t("close", { ns: "common" })}
            onClick={() =>
              navigate(
                `/suppliers/${viewModel.supplierId}/resources/${viewModel.type}s`,
              )
            }
          />
          <RenderIf permissions={permissions.linkBadge}>
            <ActionBarItem
              icon={BiLink}
              description={t("linkToBadgesTooltip", {
                ns: "badges",
                resource: `${getResourceName()}`,
              })}
              onClick={() => {
                availableBadgesRefetch();
                setLinkBadgeModal(true);
              }}
            />
          </RenderIf>
        </ActionBar>
      }
    >
      {supplierResource && (
        <Box px={3} py={5}>
          <DetailsHeader
            title={t("details", { ns: "common" })}
            image={{ url: supplierResource.photo }}
            attributes={columns.map((column) => ({
              label: t(column, { ns: "siteResources" }),
              value: getDetailValue(column),
            }))}
            isLoaded={!isLoadingSupplierResource}
            shouldShowImage={true}
          />
          <Box
            mt={5}
            p={3}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
          >
            {viewModel.type === "worker" ||
            viewModel.type === "machine" ||
            viewModel.type === "vehicle" ? (
              <Tabs height="100%" defaultIndex={0}>
                <TabList>
                  <Tab width="50%">{t("documents", { ns: "common" })}</Tab>
                  <Tab width="50%">{t("badges", { ns: "badges" })}</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel key={"documents"} paddingLeft={0} paddingRight={0}>
                    <SupplierDocumentsTable
                      tableColumns={columnsTable}
                      downloader={downloader}
                      documents={supplierResourceDocuments}
                      refetchDocument={refetchSupplierResourceDocuments}
                      documentsHasNextPage={supplierResourcesHasNextPage}
                      documentsFetchNextPage={supplierResourcesFetchNextPage}
                      evaluateDocument={evaluateDocument}
                      sortDocuments={sortDocuments}
                      setSortDocuments={updateSortDocuments}
                      filterDocuments={filterDocuments}
                      updateFilterDocuments={updateFilterDocuments}
                      updateSortDocuments={updateSortDocuments}
                      getDocumentEvaluations={getDocumentEvaluations}
                      documentEvaluations={documentEvaluations}
                      documentEvaluationsFetching={documentEvaluationsFetching}
                      filterDocumentEvaluations={filterDocumentEvaluations}
                      updateFilterDocumentEvaluations={
                        updateFilterDocumentEvaluations
                      }
                      sortDocumentEvaluations={sortDocumentEvaluations}
                      updateSortDocumentEvaluations={
                        updateSortDocumentEvaluations
                      }
                      {...documentInfoProps}
                      isCompanySupplier
                      createDocumentWithProperties={
                        createSupplierResourceDocument
                      }
                      updateDocument={updateSupplierResourceDocument}
                      autosize
                      tags={tags}
                      permissions={permissions}
                      fromSupplierResource={true}
                      supplierResourceSites={supplierResourceSites}
                      hasSites={hasSites}
                      setSortResourceSites={setSortSupplierResourceSites}
                      sortResourceSites={sortSupplierResourceSites}
                      updateFilterResourceSites={updateSupplierResourceSites}
                      filterResourceSites={filterResourceSites}
                      setFilterResourceSites={setFilterResourceSites}
                      supplierResourceSitesHasNextPage={supplierResourceSitesHasNextPage}
                      supplierResourceSitesFetchNextPage={supplierResourceSitesFetchNextPage}
                      supplierResourceSitesIsFetching={supplierResourceSitesIsFetching}
                    />
                  </TabPanel>
                  <TabPanel key={"badges"} paddingLeft={0} paddingRight={0}>
                    <SelectBadgesTable
                      badges={resourceBadges}
                      badgeIds={badgeIds}
                      setBadgeIds={setBadgeIds}
                      setBadgeId={setBadgeId}
                      badgesFetchNextPage={fetchBadgeNextPage}
                      badgesFilters={filterResourceBadge}
                      badgesHasNextPage={hasBadgeNextPage}
                      updateBadgesFilter={updateFilterBadge}
                      badgesSort={sortResourceBadge}
                      setBadgesSort={setSortResourceBadge}
                      badgeSitesIsLoading={isFetchingBadge}
                      showExtraData={true}
                      showOnlySitesExtraData={true}
                      assocciatedSites={badgeSites}
                      selectSingleRow={false}
                      showDelete={true}
                      showTags={false}
                      unlinkBadgeResource={(badgeId) => {
                        setBadgeId(badgeId), setShowDeleteAlert(true);
                      }}
                    ></SelectBadgesTable>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            ) : (
              <>
                <Box textStyle="h1" color="black" mt={5}>
                  {t("resourceDocuments", { ns: "supplier" })}
                </Box>
                <SupplierDocumentsTable
                  tableColumns={columnsTable}
                  downloader={downloader}
                  documents={supplierResourceDocuments}
                  refetchDocument={refetchSupplierResourceDocuments}
                  documentsHasNextPage={supplierResourcesHasNextPage}
                  documentsFetchNextPage={supplierResourcesFetchNextPage}
                  evaluateDocument={evaluateDocument}
                  sortDocuments={sortDocuments}
                  setSortDocuments={updateSortDocuments}
                  filterDocuments={filterDocuments}
                  updateFilterDocuments={updateFilterDocuments}
                  updateSortDocuments={updateSortDocuments}
                  getDocumentEvaluations={getDocumentEvaluations}
                  documentEvaluations={documentEvaluations}
                  documentEvaluationsFetching={documentEvaluationsFetching}
                  filterDocumentEvaluations={filterDocumentEvaluations}
                  updateFilterDocumentEvaluations={
                    updateFilterDocumentEvaluations
                  }
                  sortDocumentEvaluations={sortDocumentEvaluations}
                  updateSortDocumentEvaluations={updateSortDocumentEvaluations}
                  {...documentInfoProps}
                  //isFetching={isFetching}
                  isCompanySupplier
                  createDocumentWithProperties={createSupplierResourceDocument}
                  updateDocument={updateSupplierResourceDocument}
                  autosize
                  tags={tags}
                  permissions={permissions}
                  fromSupplierResource={true}
                  supplierResourceSites={supplierResourceSites}
                  hasSites={hasSites}
                  setSortResourceSites={setSortSupplierResourceSites}
                  sortResourceSites={sortSupplierResourceSites}
                  updateFilterResourceSites={updateSupplierResourceSites}
                  filterResourceSites={filterResourceSites}
                  setFilterResourceSites={setFilterResourceSites}
                  supplierResourceSiteCount={supplierResourceSiteCount}
                />
              </>
            )}
          </Box>
        </Box>
      )}

      {showConfirmEvaluateResourceModal && (
        <BaseModal
          title={t("confirmEvaluationForced", { ns: "siteResources" })}
          onClose={() => setShowConfirmEvaluateResourceModal(false)}
          onConfirm={() => {
            evaluateSupplierResource(
              showConfirmEvaluateResourceModal as ResourceEvaluationState,
            );
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: t("showConfirmEvaluateResourceModal", {
                ns: "siteResources",
              }),
            }}
          />
        </BaseModal>
      )}

      {showResourceEvaluationHistoryModal && (
        <ResourceEvaluationHistoryModal
          onClose={() => {
            setEnableGetEvaluationsHistory(false);
            setShowResourceEvaluationHistoryModal(false);
          }}
          evaluations={supplierResourceEvaluations}
          getEvaluations={getSupplierResourceEvaluations}
          filterResourceEvaluations={filterResourceEvaluations}
          updateFilterResourceEvaluations={updateFilterResourceEvaluations}
          sortResourceEvaluations={sortResourceEvaluations}
          updateSortResourceEvaluations={updateSortResourceEvaluations}
        />
      )}
      {linkBadgeModal && (
        <BaseModal
          closeOnOverlayClick={false}
          onClose={() => setLinkBadgeModal(false)}
          title={
            <Text
              fontWeight={"normal"}
              fontSize={20}
              dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}
            />
          }
          footer={[
            <Button
              key="confirm"
              isDisabled={!badgeIds || badgeIds?.length === 0}
              colorScheme="blue"
              onClick={async () => {
                await linkBadgesToResource();
              }}
              isLoading={linkBadgesToResourceIsLoading}
            >
              {t("confirm", { ns: "common" })}
            </Button>,
            <Button
              key="cancel"
              colorScheme="red"
              onClick={() => setLinkBadgeModal(false)}
            >
              {t("cancel", { ns: "common" })}
            </Button>,
          ]}
        >
          <SelectBadgesTable
            autosize
            key="step3"
            badges={availableBadgesResource}
            badgesFilters={availableBadgesFilters}
            badgesSort={availableBadgesSort}
            setBadgesSort={setAvailableBadgesSort}
            updateBadgesFilter={updateFilterAvailableBadge}
            badgeSitesIsLoading={availableBadgesIsLoading}
            setBadgeIds={setBadgeIds}
            badgeIds={badgeIds}
            selectAllAvailable={false}
          />
        </BaseModal>
      )}
      {showInfoSuccess && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t("badgeAssociatedToResouce", { ns: "badges" })}
          variant="info"
          onClose={() => {
            setShowInfoSuccess(false);
          }}
        />
      )}
      <DeleteActionAlert
        mainTitle={t("warning", { ns: "common" })}
        title={
          <Text
            fontWeight={"normal"}
            fontSize={20}
            dangerouslySetInnerHTML={{
              __html: sanitizedHtmlContentUnlinkBadges,
            }}
          />
        }
        onConfirm={() => {
          unlinkBadgeResource(), setShowDeleteAlert(false);
        }}
        isOpen={showDeleteAlert}
        isLoading={unlinkBadgeResourceIsLoading}
        onCancel={() => setShowDeleteAlert(false)}
      />
    </ContentLayout>
  );
};

export default SupplierResourceView;
