import { useState } from "react";
import Document from "../../../domain/entities/document";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { GetDocumentsFilter } from "../../../domain/repositories/documentRepository";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import DocumentTypeWithPublic from "../../../domain/entities/documentTypeWithPublic";
import { ResourceDocumentEvaluationState } from "../../../domain/entities/resourceDocumentEvaluationState.enum";
import { updateFilterWithDelete } from "../../../utils";
import AiTaskDocument from "../../../domain/entities/aiTaskDocument";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";

export interface DocumentsViewModel {
  getDocuments(
    companyId: string,
    filter?: GetDocumentsFilter,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<Document[]>;

  createDocument(companyId: string, documents: string[]): Promise<void>;

  createPropagableDocuments(
    companyId: string,
    documents: DocumentTypeWithPublic[],
  );

  evaluateDocument(
    companyId: string,
    documentId: string,
    evaluationState?: ResourceDocumentEvaluationState,
    expirationDate?: Date,
    noEvaluationExpiration?: boolean,
    sitesIds?: string[],
    selectAll?: boolean,
    filters?: GetSitesFilter
  );

  updateDocument(
    companyId: string,
    document: Document,
    siteIds?: string[],
    selectAll?: boolean,
  ): Promise<void>;

  deleteDocument(
    companyId: string,
    documentId: string,
    siteId?: string,
  ): Promise<Document[]>;

  reorderDocuments?: (
    companyId: string,
    documents: Document[],
  ) => Promise<void>;
}

export interface ConfirmAiTaskParams {
  taskId: string;
  aiTaskDocuments: AiTaskDocument[];
  siteIds: string[];
}

export type UseDocuments = {
  documents: Document[];
  documentsLoading: boolean;
  documentsFetching: boolean;
  deleteDocument: (documentId: string) => void;
  onReorder: (documents: Document[]) => void;
  deleteDocumentIsLoading: boolean;
  createDocument: (document: string[]) => void;
  createPropagableDocuments: (document: DocumentTypeWithPublic[]) => void;
  evaluateDocument: (params: {
    documentId: string;
    evaluationState?: ResourceDocumentEvaluationState;
    expirationDate?: Date;
    noEvaluationExpiration?: boolean;
    siteIds?: string[];
    selectAll?: boolean;
    filters?: GetSitesFilter;
  }) => void;
  filterDocuments: GetDocumentsFilter;
  updateFilterDocuments: (
    field: string,
    value: string | string[] | [Date, Date],
  ) => void;
  sortDocuments: SortMeta;
  setSortDocuments: (sort: SortMeta) => void;
  createDocuments: (documents: string[]) => void;
  updateDocument?: (
    document: Document,
    siteIds?: string[],
    selectAll?: boolean,
  ) => void;
  createPropagableDocumentsIsLoading?: boolean;
  createDocumentIsLoading?: boolean;
  documentsHasNextPage: boolean;
  documentsFetchNextPage: () => void;
  refetchDocuments: () => void;
};

const useDocumentsListViewModel = (
  viewModel: DocumentsViewModel,
  identifier: string,
  siteId?: string,
  resourceId?: string,
  resourceType?: DocumentTypeCategory
): UseDocuments => {
  // Internal params.
  const { companyId } = useAuth();

  const [filterDocuments, setFilterDocuments] = useState<GetDocumentsFilter>();
  const [sortDocuments, setSortDocuments] = useState<SortMeta>();

  // Queries.
  const getDocumentsQuery = useInfiniteQuery<Document[], Error>(
    ["get-documents", identifier, companyId, filterDocuments, sortDocuments],
    async ({ pageParam = 1 }) => {
      const result = await viewModel.getDocuments(
        companyId,
        filterDocuments,
        sortDocuments,
        pageParam,
      );
      return result;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      retry: false,
    },
  );

  // Mutations (private).
  const createMutation = useMutation(
    (documents: string[]) => {
      return viewModel.createDocument(companyId, documents);
    },
    {
      onSuccess: () => getDocumentsQuery.refetch(),
      onError: (err) => console.error(err),
    },
  );

  const createPropagableMutation = useMutation(
    (documents: DocumentTypeWithPublic[]) => {
      return viewModel.createPropagableDocuments(companyId, documents);
    },
    {
      onSuccess: () => getDocumentsQuery.refetch(),
      onError: (err) => console.error(err),
    },
  );

  const evaluateDocumentMutation = useMutation(
    ["evaluate-document"],
    async (params: {
      documentId: string;
      evaluationState?: ResourceDocumentEvaluationState;
      expirationDate?: Date;
      noEvaluationExpiration?: boolean;
      siteIds?: string[];
      selectAll?: boolean;
      filters?: GetSitesFilter;
    }) => {
      {
        return viewModel.evaluateDocument(
          companyId,
          params.documentId,
          params.evaluationState,
          params.expirationDate,
          params?.noEvaluationExpiration,
          params?.siteIds,
          params?.selectAll,
          params?.filters,
        );
      }
    },
    {
      onSuccess: () => {
        return getDocumentsQuery.refetch()
      },
      onError: (err) => console.error(err),
    },
  );

  const deleteMutation = useMutation(
    (documentId: string) => {
      return viewModel.deleteDocument(companyId, documentId, siteId);
    },
    {
      onSuccess: () => getDocumentsQuery.refetch(),
      onError: (err) => console.error(err),
    },
  );

  const updateDocumentMutation = useMutation(
    (params: { document: Document; siteIds: string[]; selectAll: boolean }) =>
      viewModel.updateDocument(
        companyId,
        params.document,
        params.siteIds,
        params.selectAll,
      ),
    {
      onSuccess: () => getDocumentsQuery.refetch(),
      onError: (err) => console.error(err),
    },
  );

  const updateDocument = async (
    document: Document,
    siteIds: string[],
    selectAll: boolean,
  ) => {
    await updateDocumentMutation.mutateAsync({ document, siteIds, selectAll });
  };

  const onReorderMutation = useMutation(
    (params: { documents: Document[] }) =>
      viewModel.reorderDocuments(companyId, params.documents),
    {
      onSuccess: () => getDocumentsQuery.refetch(),
      onError: (err) => console.error(err),
    },
  );

  const onReorder = async (documents: Document[]) => {
    await onReorderMutation.mutateAsync({ documents });
  };

  const updateFilterDocuments = (
    field: string,
    value: string | string[] | [Date, Date],
  ) => {
    updateFilterWithDelete(setFilterDocuments, field, value);
  };

  const createDocuments = async (documents: string[]) => {
    await createMutation.mutateAsync(documents);
  };

  const documents = getDocumentsQuery.data?.pages?.flat() ?? [];
  return {
    documents,
    documentsHasNextPage: getDocumentsQuery.hasNextPage,
    documentsFetchNextPage: getDocumentsQuery.fetchNextPage,
    documentsLoading: getDocumentsQuery.isLoading,
    refetchDocuments: getDocumentsQuery.refetch,
    deleteDocument: deleteMutation.mutateAsync,
    deleteDocumentIsLoading: deleteMutation.isLoading,
    createDocument: createMutation.mutateAsync,
    createDocumentIsLoading: createMutation.isLoading,
    createPropagableDocuments: createPropagableMutation.mutateAsync,
    createPropagableDocumentsIsLoading: createPropagableMutation.isLoading,
    documentsFetching: getDocumentsQuery.isFetching,
    evaluateDocument: evaluateDocumentMutation.mutateAsync,
    filterDocuments,
    updateFilterDocuments,
    sortDocuments,
    setSortDocuments,
    createDocuments,
    updateDocument,
    onReorder,
  };
};

export default useDocumentsListViewModel;
