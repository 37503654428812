import {
  Box,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { FaRegSave } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useState } from "react";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import LoadingView from "../../Common/LoadingView";
import { COLORS } from "../../../assets/theme/colors";
import DataBox from "../../../components/Common/DataBox";
import Machine from "../../../../domain/entities/machine";
import ContentLayout from "../../../layout/ContentLayout";
import { useNavigate, useParams } from "react-router-dom";
import { MdCancel, MdClose, MdEdit } from "react-icons/md";
import ActionBar from "../../../components/Common/ActionBar";
import ResourceDocumentsView, {
  ResourceDocumentProps,
} from "../ResourceDocumentsView";
import RenderIf from "../../../components/Permissions/RenderIf";
import ActionBarItem from "../../../components/Common/ActionBarItem";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Permission } from "../../../components/Permissions/Permissions";
import FormTextField from "../../../components/Common/forms/FormTextField";
import useMachineViewModel from "../../../hooks/Resources/Machines/useMachineViewModel";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { useDocumentFilesDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import useMachineDocumentsViewModel from "../../../hooks/Resources/Machines/useMachineDocumentsViewModel";
import DocumentCommunicationModal from "../../../components/Views/common/DocumentCommunicationModal";
import { FaUserPlus, FaWandMagicSparkles } from "react-icons/fa6";
import DOMPurify from "dompurify";
import BaseModal from "../../../components/Common/alerts/BaseModal";
import SelectSitesView from "../../../components/Views/common/SelectSitesView";
import SelectBadgesTable from "../../../components/Views/Badge/SelectBadgesTable";
import { BiLink } from "react-icons/bi";
import { Alert } from "../../Common/Alert";
import { DeleteActionAlert } from "../../Common/DeleteActionAlert";
import MachineVehicleMigrationButton from "../MachineVehicleMigrationButton";
import { FiPlus } from "react-icons/fi";
import { AiTaskStatus } from "../../../../domain/entities/aiTask";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";
import { LuPackagePlus } from "react-icons/lu";

interface Props {
  permissions: ResourceDocumentProps["permissions"] & {
    addDocument: Permission;
    editRecord: Permission;
    linkBadge: Permission;
    migrateToVehicle: Permission;
  };
}

const MachineDetailView: FC<Props> = ({ permissions }) => {
  const { t } = useTranslation("machines");
  const { id } = useParams();
  const {
    machine,
    isFetching,
    updateImage,
    updateMachine,
    resourceMigrationSites,
    resourceSites,
    filterResourceSites,
    updateFilterResourceSites,
    setSortResourceSites,
    sortResourceSites,
    isFetchingSites,
    hasNextPage,
    fetchNextPage,
    setEnableGetResourceSites,
    hasSites,
    siteCollection,
    sortSiteCollection,
    setSortSiteCollection,
    filterSiteCollection,
    setFilterSiteCollection,
    selectAll,
    setSelectAll,
    totalSiteCount,
    updateFilterSiteCollection,
    siteCollectionHasNextPage,
    siteCollectionFetchNextPage,
    refetchSiteCollection,
    linkMachineToSites,
    isLinking,
    updateFilterMachine,
    badgeIds,
    badgeSites,
    fetchBadgeNextPage,
    filterMachineBadge,
    hasBadgeNextPage,
    isFetchingBadge,
    resourceBadges,
    setBadgeId,
    setBadgeIds,
    setFilterMachineBadge,
    setSortMachineBadge,
    sortMachineBadge,
    availableBadgesRefetch,
    availableBadgesFilters,
    availableBadgesIsLoading,
    availableBadgesResource,
    availableBadgesSort,
    badgeId,
    linkBadgesToResource,
    linkBadgesToResourceIsLoading,
    setAvailableBadgesSort,
    setShowInfoSuccess,
    showInfoSuccess,
    unlinkBadgeResource,
    unlinkBadgeResourceIsLoading,
    updateFilterAvailableBadge,
    machineVehicleMigrationProps,
    setFilterResourceSites,

  } = useMachineViewModel(id as string);
  const [showSiteCollection, setShowSiteCollection] = useState(false);
  const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>();
  const documentsHook = useMachineDocumentsViewModel(id);
  const [showAddDocumentType, setShowAddDocumentType] =
    useState<boolean>(false);
  const [showManageTaskWithAI, setShowManageTaskWithAI] =
    useState<boolean>(false);
  const downloader = useDocumentFilesDownloader();
  const [init, setInit] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showCommunicationModal, setShowCommunicationModal] = useState(false);
  const [uploadedImageFile, setUploadedImageFile] = useState<File>();
  const [linkBadgeModal, setLinkBadgeModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const sanitizedHtmlContent = DOMPurify.sanitize(
    t("addResourceToSites", { ns: "sites", resource: `${machine?.name}` })
  );
  const sanitizedHtmlContentLinkBadges = DOMPurify.sanitize(
    t("linkToBadges", { ns: "badges", resource: `${machine?.name}` })
  );
  const sanitizedHtmlContentUnlinkBadges = DOMPurify.sanitize(
    t("unlinkResourceFromBadge", {
      ns: "badges",
      resource: `${machine?.name}`,
    })
  );

  const navigate = useNavigate();
  const goToMachinesList = () => {
    navigate(`/resources/machines`);
  };

  const onSubmit: SubmitHandler<Machine> = (data) => {
    if (uploadedImageFile) {
      updateImage(
        { ...machine, name: data.name, plate: data.plate, model: data.model },
        uploadedImageFile
      );
    } else {
      updateMachine({
        ...machine,
        name: data.name,
        plate: data.plate,
        model: data.model,
      });
    }
    setIsEditing(false);
  };

  const methods = useForm<Machine>({ mode: "all", defaultValues: machine });
  const requiredRule = { required: t("requiredField", { ns: "common" }) };
  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (!machine || init) {
      return;
    }

    reset({ name: machine.name, plate: machine.plate, model: machine.model });
    setInit(true);
  }, [machine, init, isEditing, reset]);

  useEffect(() => {
    if (!linkBadgesToResourceIsLoading) {
      setLinkBadgeModal(false);
    }
  }, [linkBadgesToResourceIsLoading]);

  const refetchCommentsAndNotes = () => {
    documentsHook.communicationProps.refetchResourceDocumentComments(),
      documentsHook.communicationProps.refetchResourceDocumentNotes();
  };

  if (!machine) {
    return <LoadingView />;
  }
  const handleLinkToSites = async () => {
    await linkMachineToSites(selectAll? [] : selectedSiteIds, selectAll);
    setShowSiteCollection(false);
    setSelectedSiteIds([]);
  };

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            bgColor={COLORS.sikuroBlue}
            color="white"
            icon={MdClose}
            description={t("close", { ns: "common" })}
            onClick={goToMachinesList}
          />
          <RenderIf permissions={permissions.editRecord}>
            <ActionBarItem
              icon={isEditing ? MdCancel : MdEdit}
              onClick={() => {
                setIsEditing(!isEditing);
                if (isEditing) {
                  setUploadedImageFile(undefined);
                }
              }}
              description={t(isEditing ? "cancel" : "edit", { ns: "common" })}
            />
          </RenderIf>
          {isEditing && isValid && (
            <ActionBarItem
              description={t("confirm", { ns: "common" })}
              icon={FaRegSave}
              onClick={handleSubmit(onSubmit)}
            />
          )}
          <RenderIf
            check={PermissionCheck.All}
            permissions={[
              permissions.documents.addFile,
              permissions.manageDocumentWithAi,
            ]}
          >
            <ActionBarItem
              icon={FaWandMagicSparkles}
              description={t("manageDocumentWithAi", { ns: "documents" })}
              onClick={() => {
                documentsHook.documentAIProps.aiTasksRefetch();
                setShowManageTaskWithAI(true);
              }}
              isAnimated={documentsHook.documentAIProps.aiTasks.some(
                (task) => task.status === AiTaskStatus.RUNNING
              )}
            />
          </RenderIf>
          <RenderIf permissions={permissions.documents.chat}>
            <ActionBarItem
              bgColor={COLORS.yellow}
              color="black"
              icon={HiOutlineChatAlt2}
              description={t("chat", { ns: "communication" })}
              onClick={() => {
                refetchCommentsAndNotes(), setShowCommunicationModal(true);
              }}
            />
          </RenderIf>
          <ActionBarItem
            bgColor={COLORS.yellow}
            color="black"
            icon={LuPackagePlus }
            description={t("addResourceToSitesTooltip", {
              ns: "sites",
              resource: `${machine?.name}`,
            })}
            onClick={() => {
              refetchSiteCollection(), setShowSiteCollection(true);
            }}
          />
          <RenderIf permissions={permissions.linkBadge}>
            <ActionBarItem
              icon={BiLink}
              description={t("linkToBadgesTooltip", {
                ns: "badges",
                resource: `${machine?.name}`,
              })}
              onClick={async () => {
                await availableBadgesRefetch();
                setLinkBadgeModal(true);
              }}
            />
          </RenderIf>
          <RenderIf permissions={permissions.migrateToVehicle}>
            <MachineVehicleMigrationButton
              namespace="machines"
              hasSites={resourceMigrationSites.length > 0}
              {...machineVehicleMigrationProps}
            />
          </RenderIf>
        </ActionBar>
      }
    >
      {machine && (
        <Box px={3} py={5} marginLeft={7}>
          <Box textStyle="h1" mb={2}>
            {machine?.name} {machine?.model}
          </Box>
          <Box
            pb={8}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
          >
            <FormProvider {...methods}>
              <DataBox
                title={t("details", { ns: "common" })}
                isEditing={isEditing}
                isLoading={isFetching}
                image={{
                  url: uploadedImageFile
                    ? URL.createObjectURL(uploadedImageFile)
                    : machine.photo,
                  onUpdate: (f) => setUploadedImageFile(f),
                }}
                fields={[
                  <FormTextField
                    key="name"
                    name="name"
                    label={t("name")}
                    rules={requiredRule}
                  />,
                  <FormTextField
                    key="plate"
                    name="plate"
                    label={t("plate")}
                    rules={requiredRule}
                  />,
                  <FormTextField key="model" name="model" label={t("model")} />,
                ]}
              />
            </FormProvider>
          </Box>
          <Box
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
            padding={5}
            mt={5}
          >
            <Tabs height="100%" defaultIndex={0} marginTop={4}>
              <TabList>
                <Tab width="50%">{t("documents", { ns: "common" })}</Tab>
                <Tab width="50%">{t("badges", { ns: "badges" })}</Tab>
              </TabList>
              <TabPanels>
                <TabPanel key={"documents"} paddingLeft={0} paddingRight={0}>
                  <ResourceDocumentsView
                    category={DocumentTypeCategory.MACHINE}
                    documentsHook={documentsHook}
                    resourcePath="/resources/workers"
                    showAddDocumentType={showAddDocumentType}
                    setShowAddDocumentType={setShowAddDocumentType}
                    downloader={downloader}
                    resourceSites={resourceSites}
                    hasSites={hasSites}
                    hasNextPage={hasNextPage}
                    fetchNextPage={() => fetchNextPage()}
                    siteCount={totalSiteCount}
                    selectAllSites={selectAll}
                    setSelectAllSites={setSelectAll}
                    setEnableGetResourceSites={setEnableGetResourceSites}
                    isFetchingSites={isFetchingSites}
                    filterResourceSites={filterResourceSites}
                    updateFilterResourceSites={updateFilterResourceSites}
                    setSortResourceSites={setSortResourceSites}
                    sortResourceSites={sortResourceSites}
                    showManageTaskWithAI={showManageTaskWithAI}
                    setShowManageTaskWithAI={setShowManageTaskWithAI}
                    permissions={permissions}
                    resourceId={machine.id}
                    setFilterResourceSites={setFilterResourceSites}
                  />
                </TabPanel>
                <TabPanel key={"badges"} paddingLeft={0} paddingRight={0}>
                  <SelectBadgesTable
                    badges={resourceBadges}
                    badgeIds={badgeIds}
                    setBadgeIds={setBadgeIds}
                    setBadgeId={setBadgeId}
                    badgesFetchNextPage={fetchBadgeNextPage}
                    badgesFilters={filterMachineBadge}
                    badgesHasNextPage={hasBadgeNextPage}
                    updateBadgesFilter={updateFilterMachine}
                    badgesSort={sortMachineBadge}
                    setBadgesSort={setSortMachineBadge}
                    badgeSitesIsLoading={isFetchingBadge}
                    showExtraData={true}
                    showOnlySitesExtraData={true}
                    assocciatedSites={badgeSites}
                    selectSingleRow={false}
                    showTags={false}
                    showDelete={true}
                    unlinkBadgeResource={(badgeId) => {
                      setBadgeId(badgeId), setShowDeleteAlert(true);
                    }}
                  ></SelectBadgesTable>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      )}
      {showCommunicationModal && (
        <DocumentCommunicationModal
          onClose={() => setShowCommunicationModal(false)}
          hook={documentsHook.communicationProps}
          permissions={permissions.documents}
        />
      )}
      {showSiteCollection && (
        <BaseModal
          closeOnOverlayClick={false}
          onClose={() => setShowSiteCollection(false)}
          footer={[
            <Button
              key="confirm"
              isDisabled={!selectedSiteIds || selectedSiteIds?.length === 0}
              colorScheme="blue"
              onClick={() => handleLinkToSites()}
              isLoading={isLinking}
            >
              {t("confirm", { ns: "common" })}
            </Button>,
            <Button
              key="cancel"
              colorScheme="red"
              onClick={() => setShowSiteCollection(false)}
            >
              {t("cancel", { ns: "common" })}
            </Button>,
          ]}
        >
          <Text
            fontSize={16}
            marginTop={3}
            marginBottom={5}
            dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}
          />
          <SelectSitesView
            autosize
            title={""}
            siteSelectedAction={setSelectedSiteIds}
            siteList={siteCollection}
            setSortResourceSites={setSortSiteCollection}
            sortResourceSites={sortSiteCollection}
            filterResourceSites={filterSiteCollection}
            updateFilterResourceSites={updateFilterSiteCollection}
            hasNextPage={siteCollectionHasNextPage}
            fetchNextPage={() => siteCollectionFetchNextPage()}
            includeFooterButton={false}
            setSelectAllSites={setSelectAll}
            selectAllSites={selectAll}
            showSelectAll={true}
            hideSubtitles={true}
          />
        </BaseModal>
      )}
      {linkBadgeModal && (
        <BaseModal
          closeOnOverlayClick={false}
          onClose={() => setLinkBadgeModal(false)}
          title={
            <Text
              fontWeight={"normal"}
              fontSize={20}
              dangerouslySetInnerHTML={{
                __html: sanitizedHtmlContentLinkBadges,
              }}
            />
          }
          footer={[
            <Button
              key="confirm"
              isDisabled={!badgeIds || badgeIds?.length === 0}
              colorScheme="blue"
              onClick={async () => {
                await linkBadgesToResource();
              }}
              isLoading={linkBadgesToResourceIsLoading}
            >
              {t("confirm", { ns: "common" })}
            </Button>,
            <Button
              key="cancel"
              colorScheme="red"
              onClick={() => setLinkBadgeModal(false)}
            >
              {t("cancel", { ns: "common" })}
            </Button>,
          ]}
        >
          <SelectBadgesTable
            autosize
            key="step3"
            badges={availableBadgesResource}
            badgesFilters={availableBadgesFilters}
            badgesSort={availableBadgesSort}
            setBadgesSort={setAvailableBadgesSort}
            updateBadgesFilter={updateFilterAvailableBadge}
            badgeSitesIsLoading={availableBadgesIsLoading}
            setBadgeIds={setBadgeIds}
            badgeIds={badgeIds}
            selectAllAvailable={false}
          />
        </BaseModal>
      )}
      {showInfoSuccess && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t(showInfoSuccess, { ns: showInfoSuccess === 'resourceAssociatedToSite' ? "siteResources" : "badges"})}
          variant="info"
          onClose={() => {
            setShowInfoSuccess(null);
          }}
        />
      )}
      <DeleteActionAlert
        mainTitle={t("warning", { ns: "common" })}
        title={
          <Text
            fontWeight={"normal"}
            fontSize={20}
            dangerouslySetInnerHTML={{
              __html: sanitizedHtmlContentUnlinkBadges,
            }}
          />
        }
        onConfirm={() => {
          unlinkBadgeResource(), setShowDeleteAlert(false);
        }}
        isOpen={showDeleteAlert}
        isLoading={unlinkBadgeResourceIsLoading}
        onCancel={() => setShowDeleteAlert(false)}
      />
    </ContentLayout>
  );
};

export default MachineDetailView;
