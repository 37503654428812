import { Tr as ChakraTr, Thead, Th, Tbody, Td } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import DocumentPreset from "../../../../domain/entities/documentPreset";
import Requirement from "../../../../domain/entities/requirement";
import Variant from "../../../../domain/entities/variant";
import BaseModal from "../../Common/alerts/BaseModal";
import DnDTable from "../../Common/table/DnDTable";
import TagSelect from "../../Common/TagSelect";

interface OrderDocuementsByVariantModalProps {
  onClose: () => void;
  documents: Requirement[] | DocumentPreset[];
  isButtonDisabled: boolean;
  autosize?: boolean;
  variants: Variant[];
  setVariantId: Dispatch<SetStateAction<string>>;
  moveDocumentsByVariant: (documents: Requirement[] | DocumentPreset[]) => void;
  isLoading: boolean;
  labelTagSelect: string
  title: string;
  emptyText: string
}

export const OrderDocumentsByVariantModal = ({
  onClose,
  documents,
  variants,
  setVariantId,
  moveDocumentsByVariant,
  isLoading,
  labelTagSelect,
  title,
  emptyText
}: OrderDocuementsByVariantModalProps) => {
  const { t } = useTranslation();
  const [selectedVariant, setSelectedVariant] = useState<string>();
  const [orderArray, setOrderArray] = useState([])

  useEffect(() => {
    if (variants && variants.length > 0) {
      const defaultVariant = variants.find((v) => v.name === "default") || variants[0];
      setSelectedVariant(defaultVariant.id);
      setVariantId(defaultVariant.id);
    }
  }, [variants]);

  useEffect(() => {
    setOrderArray(documents)
  }, [ documents]);




  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newArray = [...documents];
    const index = documents.findIndex(
      (obj) => obj.id === result.draggableId
    );
    const movedObject = newArray.splice(index, 1)[0];
    newArray.splice(result.destination.index, 0, movedObject);
    setOrderArray(newArray)
    moveDocumentsByVariant(newArray as any);
  };

  return (
    <BaseModal
      title={title}
      onClose={() => {
        setSelectedVariant(undefined), onClose();
      }}
      size="6xl"
      onCancel={false}
      bodyOverflow={"scroll"}
    >
      <TagSelect
        tags={variants}
        isMulti={false}
        style={{ zIndex: "2" }}
        selectedTags={selectedVariant ? [selectedVariant] : []}
        setSelectedTags={(variant) => {
          setVariantId(variant[0]);
          setSelectedVariant(variant[0]);
        }}
        label={labelTagSelect}
        defaultMenuIsOpen={false}
      />
      <DnDTable
        dnd={{ onDragEnd, droppableId: "requirements-table" }}
        isLoading={isLoading}
        emptyText={emptyText}
        showEmptyText={
          selectedVariant === undefined || documents?.length === 0
        }
      >
        <Thead>
          <ChakraTr>
            <Th>{t("name")}</Th>
          </ChakraTr>
        </Thead>
        <Tbody>
          {selectedVariant &&
            orderArray?.map((document) => (
              <ChakraTr key={document.id} id={document.id}>
                <Td>{document.documentType.name}</Td>
              </ChakraTr>
            ))}
        </Tbody>
      </DnDTable>
    </BaseModal>
  );
};
